import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Text,
  Link,
} from 'components/typo';
import { setModal } from 'redux/actions/SystemActions';

const ModalFooterHelp = () => {
  const dispatch = useDispatch();

  return (
    <Text
      align="center"
      color="grey500"
    >
      Возникли проблемы?
      {' '}
      <Link
        to="/#help"
        onClick={(e) => {
          e.preventDefault();
          dispatch(setModal({ name: 'problems' }));
        }}
      >
        Напишите в поддержку
      </Link>
    </Text>
  );
};

export default ModalFooterHelp;
