import React from 'react';
import s from 'styled-components';
import cn from 'classnames';

const CardContainer = s.div`
  padding: ${({ $p }) => $p || '20px'};
  background-color: #fff;
  border-radius: 6px;

  &.--shadow {
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.02);
  }

  &.--action {
    cursor: pointer;
    transition: box-shadow 0.3s, transform 0.3s;

    &:hover {
      transform: translateY(-1px);
    }

    &.--shadow:hover {
      box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &.--border {
    border: 1px solid #e3e3e3;
  }

  &.--link {
    cursor: pointer;

    &:hover {
      .icon-link svg {
        fill: #0f737e;
      }
    }
  }

  &.--bg {
    &-link {
      background-color: #e7f1f2;
    }

    &-primary-light {
      background-color: #f1faff;
    }

    &-transparent {
      background-color: rgba(255, 255, 255, 0);
    }

    &-grey-blur {
      background: rgba(237, 242, 247, 0.9);
      backdrop-filter: blur(50px);
    }
  }

  &.--ai {
    &-center {
      align-items: center;
    }

    &-start {
      align-items: flex-start;
    }

    &-end {
      align-items: flex-end;
    }
  }

  &.--jc {
    &-center {
      justify-content: center;
    }

    &-start {
      justify-content: flex-start;
    }

    &-end {
      justify-content: flex-end;
    }

    &-between {
      justify-content: space-between;
    }

    &-around {
      justify-content: space-around;
    }
  }

  &.--center {
    text-align: center;
  }

  &.--flex {
    display: flex;
    width: 100%;
  }
`;

const Card = ({
  children,
  shadow,
  border,
  className,
  onClick = () => {},
  action,
  link,
  bg,
  center,
  flex,
  ai,
  jc,
  p,
  ...props
}) => (
  <CardContainer
    $p={p}
    className={cn(
      'ps-card',
      {
        '--shadow': shadow,
        '--border': border,
        '--action': action,
        '--link': link,
        '--center': center,
        '--flex': flex,
        [`--bg-${bg}`]: bg,
        [`--ai-${ai}`]: ai,
        [`--jc-${jc}`]: jc,
      },
      className,
    )}
    onClick={onClick}
    {...props}
  >
    {children}
  </CardContainer>
);

export default Card;
