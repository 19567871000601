export const MailingsActionTypes = {
  SET_FETCHING_MAILINGS: 'SET_FETCHING_MAILINGS',
  FETCH_MAILINGS_ERROR: 'FETCH_MAILINGS_ERROR',

  CLEAR_MAILINGS_HOME: 'CLEAR_MAILINGS_HOME',
  SET_MAILINGS_HOME_IS_EMPTY: 'SET_MAILINGS_HOME_IS_EMPTY',
  FETCH_MAILINGS_HOME_SUCCESS: 'FETCH_MAILINGS_HOME_SUCCESS',

  SET_FETCHING_GET_MAILING_BY_ID: 'SET_FETCHING_GET_MAILING_BY_ID',
  FETCH_GET_MAILING_BY_ID_SUCCESS: 'FETCH_GET_MAILING_BY_ID_SUCCESS',

  SET_FETCHING_MAILING_SUBMITTING: 'SET_FETCHING_MAILING_SUBMITTING',
  SET_FETCHING_MAILING_TOGGLE_ACTIVE: 'SET_FETCHING_MAILING_TOGGLE_ACTIVE',

  FETCH_MAILING_CREATE_SUCCESS: 'FETCH_MAILING_CREATE_SUCCESS',
  FETCH_MAILING_UPDATE_SUCCESS: 'FETCH_MAILING_UPDATE_SUCCESS',
  FETCH_MAILING_DELETE_SUCCESS: 'FETCH_MAILING_DELETE_SUCCESS',
  FETCH_MAILING_TOGGLE_ACTIVE_SUCCESS: 'FETCH_MAILING_TOGGLE_ACTIVE_SUCCESS',
};
