import React from 'react';
import { Bage } from 'components/base';

const CardStopList = ({
  title = 'Стоп-лист',
  variant = 'grey',
}) => (
  <Bage
    title={title}
    tt="up"
    variant={variant}
  />
);

export default CardStopList;
