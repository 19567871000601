import React, { useState } from 'react';
import { clientsStatusesModel } from 'constants/ClientsTypes';
import { ReactComponent as IconDocument } from 'assets/icons/document.svg';

// components
import { Box } from 'layout';
import {
  Modal,
  ModalContent,
} from 'components/modal';
import { Button } from 'components/base';
import { Text } from 'components/typo';
import { FormSelect } from 'components/form';

const ModalConfirm = (props) => {
  const [
    selected,
    setSelected,
  ] = useState(null);
  const {
    // closeModal,
    modal: {
      // onYes,
      isSubmitting,
    },
  } = props;

  // const handleYes = () => {
  //   onYes(closeModal)
  // }

  const handleDownload = () => {
    // TODO DOWNLOAD PDF
  };

  return (
    <Modal
      center
      noOverflow
      width="confirm"
    >
      <ModalContent
        column
        display="flex"
        overflowX="unset"
        overflowY="unset"
        p="39px 36px 36px 36px"
      >
        <div className="mb-3">
          <IconDocument fill="currentColor" />
        </div>
        <div className="mb-3">
          <Text fw="sb">Сохранить базу клиентовв Excel файл</Text>
        </div>
        <Box
          className="mb-4"
          width="100%"
        >
          <FormSelect
            isMulti
            errors={{}}
            name="status"
            options={clientsStatusesModel}
            placeholder="Все клиенты"
            selectedOption={selected}
            touched={{}}
            value={selected}
            onChange={(value) => setSelected(value)}
            {...props}
          />
        </Box>
        <Button
          full
          defaultStyle="green"
          isDisabled={isSubmitting}
          isFetching={isSubmitting}
          title="Выбрать"
          onClick={handleDownload}
        />
      </ModalContent>
    </Modal>
  );
};

export default ModalConfirm;
