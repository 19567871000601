import React from 'react';
import styled from 'styled-components';

const FormHeadContainer = styled.div`
  padding: 20px 0;
  font-size: 22px;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: 480px) {
    padding: 10px 0;
    font-size: 18px;
  }
`;

const FormHead = ({
  children = null,
  title,
  ...otherProps
}) => (
  <FormHeadContainer {...otherProps}>
    {children || <h3>{title}</h3>}
  </FormHeadContainer>
);

export default FormHead;
