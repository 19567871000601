import React, {
  useCallback,
  useEffect,
} from 'react';
import { Link } from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import * as PathsTypes from 'constants/PathsTypes';

// components
import { Button } from 'components/base';
import { ButtonArrow } from 'components/buttons';
import Logo from 'components/Logo';
import PageHeader from 'layout/Page/PageHeader';
import {
  Grid,
  Box,
} from 'layout';
import PageHeaderLink from 'layout/Page/PageHeader/PageHeaderLink';

// redux
import { setModal } from 'redux/actions/SystemActions';

// utils
import useResponsive from 'components/hooks/useResponsive';
import {
  SmoothScroolToTarget,
  handleElClass,
} from 'utils';

const PageHeaderHome = ({ isSecond }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const {
    isLaptopOrMobile,
    isMobileSmall,
  } = useResponsive();

  const headerScrollHandler = useCallback(() => {
    const header = document.querySelector('header');
    const content = document.querySelector('main');
    const headerHeight = header.getBoundingClientRect().height;

    content.style.paddingTop = `${headerHeight}px`;
    handleElClass(header, '--fixed', 'add');
  }, []);

  useEffect(() => {
    headerScrollHandler();
    // eslint-disable-next-line
  }, [headerScrollHandler, isLaptopOrMobile]);

  const handleModal = useCallback(
    (name, data = {}) => dispatch(
      setModal({
        name,
        data,
      }),
    ),
    [ dispatch ],
  );

  const handleLogin = useCallback(() => {
    handleModal('login');
  }, [ handleModal ]);

  const handleRegistration = useCallback(() => handleModal('signup'), [ handleModal ]);

  // const handleRequest = (e) => {
  //   e.preventDefault();
  //   handleModal("request", { isEmpty: true });
  // };

  const renderRegistrationBtn = useCallback(
    () => (isMobileSmall ? (
      <Button
        defaultStyle="transparent"
        size="small"
        title="Начать"
        onClick={handleRegistration}
      />
    ) : (
      <ButtonArrow
        size="small"
        title="Начать"
        onClick={handleRegistration}
      />
    )),
    [
      handleRegistration,
      isMobileSmall,
    ],
  );

  const renderLoginBtn = useCallback(() => {
    const classes = isMobileSmall ? 'ml-10' : 'ml-3';
    const btnProps = {
      className: classes,
      defaultStyle: 'black',
      size: 'small',
      title: 'Войти',
      onClick: !profile ? handleLogin : null,
    };

    return profile ? (
      <Link to={PathsTypes.INTERFACE}>
        <Button {...btnProps} />
      </Link>
    ) : (
      <Button {...btnProps} />
    );
  }, [
    handleLogin,
    isMobileSmall,
    profile,
  ]);

  const handleSubscriptions = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }

      handleModal('subscriptions');
    },
    [ handleModal ],
  );

  return (
    <PageHeader>
      <Logo
        className="mr-3"
        defaultStyle="acsent"
        size="small"
      />

      {!isLaptopOrMobile && (
        <Grid
          className="header-nav"
          gutter={36}
          width="auto"
        >
          <PageHeaderLink
            data-target="cases"
            title="Кейсы"
            to="/#cases"
            onClick={isSecond ? null : SmoothScroolToTarget}
          />
          <PageHeaderLink
            data-target="tarifs"
            title="Тарифы"
            to="/#tarifs"
            onClick={handleSubscriptions}
          />
          <PageHeaderLink
            href={PathsTypes.TELEGRAM}
            target="_blank"
            title="Telegram"
          />
          {/* <PageHeaderLink title="WhatsApp" href={PathsTypes.WHATSUP} target="_blank" /> */}
        </Grid>
      )}

      <Box ai="center">
        {!profile ? renderRegistrationBtn() : ''}
        {renderLoginBtn()}
      </Box>
    </PageHeader>
  );
};

export default PageHeaderHome;
