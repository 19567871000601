import React, { useState } from 'react';
import s from 'styled-components';
import { checkCorrectUrl } from 'helpers/System';

const defaultStyles = ({
  src,
  theme,
  variant = 'normal',
  fit,
  isDisabled = false,
  background,
  width,
  height,
}) => {
  const sizes = theme.components.cardImage[variant] || theme.components.cardImage.normal;

  return `
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: ${background ? 0 : sizes.padding}px;
    height: ${height || sizes.height}px;
    width: ${width || sizes.width}px;
    border-radius: ${sizes.br || 0}px;
    opacity: ${isDisabled ? 0.5 : 1};
    overflow: ${!background ? 'hidden' : 'unset'};
    transition: opacity 0.3s ease-in-out;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      max-height: 100%;
      width: auto;
    }

    div {
      width: 100%;
      height: 100%;
      background-image: url(${checkCorrectUrl(src)});
      background-color: ${theme.colors.transparent};
      background-repeat: no-repeat;
      background-size: ${fit};
      background-position: center;
      border-radius: ${sizes.br || 0}px;
    }
  `;
};

const CardImageContainer = s.div`
  ${(props) => defaultStyles(props)}
`;

const CardImage = ({
  alt = '',
  background = false,
  children = null,
  fit = 'contain',
  src = '',
  placeholder = null,
  ...otherProps
}) => {
  const [
    img,
    setImg,
  ] = useState(src);
  const props = {
    ...otherProps,
    fit,
    src,
    background,
  };

  const onImgUrlError = () => setImg(null);

  return (
    <CardImageContainer
      className="card-image"
      {...props}
    >
      {children}
      {background && <div />}
      {!background && img && (
        <img
          alt={alt}
          src={checkCorrectUrl(img)}
          onError={onImgUrlError}
        />
      )}
      {!background && !img && (
        <img
          alt={alt}
          src={placeholder}
        />
      )}
    </CardImageContainer>
  );
};

export default CardImage;
