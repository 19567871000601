export const IMAGE_MIME = {
  'image/*': [
    '.jpeg',
    '.jpg',
    '.png',
    '.webp',
  ],
};

export const VIDEO_MIME = {
  'video/*': [
    '.mp4',
    '.mov',
    '.avi',
  ],
};

export const VIDEO_AND_IMAGE_MIME = {
  ...IMAGE_MIME,
  ...VIDEO_MIME,
};
