import React from 'react';

// components
import { Box } from 'layout';
import {
  Modal,
  ModalContent,
} from 'components/modal';
import { Title } from 'components/typo';
import EmailConfirm from 'components/EmailConfirm';

// images
import { ReactComponent as Icon } from 'assets/icons/email.svg';

const ModalPhoneConfirm = () => (
  <Modal
    flex
    size="full"
  >
    <ModalContent
      center
      column
      display="flex"
      p="withSidebar"
    >
      <Box
        flex
        ai="center"
        direction="column"
      >
        <Box className="mb-6">
          <Icon
            height={52.5}
            width={55}
          />
        </Box>
        <Box className="mb-5">
          <Title tag="h2">Код подтверждения почты</Title>
        </Box>
        <Box self="stretch">
          <EmailConfirm />
        </Box>
      </Box>
    </ModalContent>
  </Modal>
);

export default ModalPhoneConfirm;
