import s from 'styled-components';
import cn from 'classnames';

const Styles = ({
  theme,
  $p,
  $size,
  $br,
  $bgColor,
  $fill,
}) => `
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${theme.sizes[$size]};
  width: ${theme.sizes[$size]};
  padding: ${theme.paddings[$p]};
  border-radius: ${theme.radius[$br]};
  background-color: ${theme.colors[$bgColor]};
  color: ${theme.colors[$fill]};
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  svg {
    fill: currentColor;
  }
`;

const IconWrapperContainer = s.div`
  ${(props) => Styles(props)}
`;
const IconWrapper = ({
  children,
  className,
  br = 'normal',
  size = 'icon-normal',
  p = 'icon-normal',
  bgColor = 'grey300',
  fill = 'grey500',
  ...otherProps
}) => {
  const classes = cn('w-icon-wrapper', className);
  const props = {
    $br: br,
    $size: size,
    $p: p,
    $fill: fill,
    $bgColor: bgColor,
    className: classes,
    ...otherProps,
  };

  return (
    <IconWrapperContainer {...props}>
      {children}
    </IconWrapperContainer>
  );
};

export default IconWrapper;
