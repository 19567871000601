import React from 'react';
import s from 'styled-components';
import cn from 'classnames';
import { Link } from 'components/typo';

const getStyles = ({ theme }) => `
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: ${theme.colors.black};
  font-size: 16px;
  transition: color 0.3s;
  line-height: 24px;

  &.--active,
  &:hover {
    color: ${theme.colors.black700};
  }
`;

const HeaderLinkContainer = s(Link)`
  ${getStyles}
`;

const HeaderLinkBrowserContainer = s.a`
  ${getStyles}
`;

const PageHeaderLink = ({
  title,
  active,
  onClick = null,
  href = null,
  ...otherProps
}) => (href ? (
  <HeaderLinkBrowserContainer
    href={href}
    {...otherProps}
    className={cn('page-header-link', { '--active': active })}
    onClick={onClick}
  >
    {title}
  </HeaderLinkBrowserContainer>
) : (
  <HeaderLinkContainer
    {...otherProps}
    className={cn('page-header-link', { '--active': active })}
    onClick={onClick}
  >
    {title}
  </HeaderLinkContainer>
));

export default PageHeaderLink;
