import React from 'react';
import styled from 'styled-components';

const sizes = { modal: '510px' };

const FormContainer = styled.form`
  width: ${({ $width }) => ($width ? sizes[$width] : '100%')};
  color: #000;
`;

const Form = ({
  children,
  width,
  ...otherProps
}) => (
  <FormContainer
    $width={width}
    {...otherProps}
  >
    {children}
  </FormContainer>
);

export default Form;
