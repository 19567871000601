export const SystemActionTypes = {
  SET_SHOW_PRELOADER: 'SET_SHOW_PRELOADER',
  INIT_STATE_SUCCESS: 'INIT_STATE_SUCCESS',
  INIT_STATE_ERROR: 'INIT_STATE_ERROR',
  SET_SEARCH_VALUE: 'SET_SEARCH_VALUE',
  SET_MODAL: 'SET_MODAL',
  SET_BROWSER_WIDTH: 'SET_BROWSER_WIDTH',
  SET_PANNEL_IS_COLLAPSED: 'SET_PANNEL_IS_COLLAPSED',
  SET_FORM_IS_DIRTY: 'SET_FORM_IS_DIRTY',
  SET_NEED_TO_SAVE_FORM: 'SET_NEED_TO_SAVE_FORM',

  SET_NOTIFICATION: 'SET_NOTIFICATION',
  SET_NOTIFICATION_ERROR: 'SET_NOTIFICATION_ERROR',

  SET_FETCHING_FORM_REQUEST: 'SET_FETCHING_FORM_REQUEST',
  FETCH_FORM_REQUEST_SUCCESS: 'FETCH_FORM_REQUEST_SUCCESS',
};
