import React, { forwardRef } from 'react';
import cn from 'classnames';
import s from 'styled-components';
import Spinner from 'components/base/Spinner';
import { theme } from 'theme';

const styles = ({
  $size = 'normal',
  theme,
  $nopadding,
  $defaultStyle,
  $reversed,
  $add,
  $minWidth,
  $fw,
}) => {
  const btnTheme = theme.components.button;
  const sizes = btnTheme.sizes[$size] || btnTheme.sizes.normal;
  const sizesMobType = $size === 'big' ? 'normal' : $size;
  const sizesMob = btnTheme.sizes[sizesMobType] || btnTheme.sizes.normal;
  const styles = btnTheme.styles[$defaultStyle] || btnTheme.styles.acsent;

  return `
    position: relative;
    display: inline-flex;
    height: ${sizes.height};
    padding: ${$nopadding ? 'unset' : sizes.padding};
    ${$add ? 'padding-right:  14px' : ''};
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border: ${styles.border || 'unset'};
    border-radius: 12px;
    cursor: pointer;
    text-decoration: unset;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    background-color: ${styles.bg};
    color: ${styles.color};
    font-size: ${sizes.fs};
    font-weight: ${$fw ? theme.fw[$fw] : styles.fw || sizes.fw};
    border-radius: ${sizes.br};
    backdrop-filter: ${styles.blur || 'none'};
    ${$minWidth ? `min-width: ${btnTheme.minWidths[$minWidth]};` : ''}

    &.--blur {
      backdrop-filter: blur(50px);
    }

    &.--border-r {
      border-radius: 100px;
    }

    span {
      text-decoration: unset;
    }

    @media (max-width: 768px) {
      height: ${sizesMob.height};
      padding: ${$nopadding ? 'unset' : sizes.padding};
      font-size: ${sizesMob.fs};
      font-weight: ${styles.fw || sizesMob.fw};
      border-radius: ${sizesMob.br};
    }

    & > * {
      white-space: nowrap !important;
    }

    &.--hovered,
    &:hover {
      @media (min-width: 769px) {
        background-color: ${styles.bgHover || styles.bg};
        color: ${styles.colorHover || styles.color};
      }
    }

    svg {
      fill: currentColor;
      order: ${$reversed ? -1 : 1};
      margin-right: ${$reversed ? sizes.offset : 0};
      margin-left: ${$reversed ? 0 : sizes.offset};
    }

    &.--c {
      &-grey {
        color: $CGrey-500;
      }
    }

    &.--fw {
      &-sb > * {
        font-weight: $FWSB;
      }

      &-m > * {
        font-weight: $FWM;
      }
    }

    &.--jc {
      &-sb {
        justify-content: space-between;
      }
    }

    &.--tt {
      &-up {
        text-transform: uppercase;
      }

      &-cap {
        text-transform: capitalize;
      }
    }

    &.--full {
      width: 100%;
    }

    &.--w {
      &-medium {
        min-width: 160px;
      }

      &-big {
        min-width: 280px;
      }
    }

    &.--fs {
      &-xs {
        font-size: 12px;
      }

      &-small {
        font-size: 14px;
      }
    }

    &.--fetching {
      opacity: 1 !important;
    }

    &:disabled {
      opacity: 0.4;
      pointer-events: none;
      background-color: ${styles.bgDisabled || ''};

      &.--opacity-f {
        opacity: 1;
      }
    }

    &.--no-pointers {
      pointer-events: none;
    }

    &.--arrow {
      svg {
        position: relative;
        left: 0;
        transition: left 0.3s ease-in-out;
      }

      &.--hovered,
      &:hover {
        @media (min-width: 769px) {
          svg {
            left: 5px;
          }
        }
      }
    }
  `;
};

const ButtonContainer = s.button`
  ${(props) => styles(props)}
`;

const Button = forwardRef(
  (
    {
      title = 'button',
      type = 'button',
      full = false,
      isDisabled = null,
      width = false,
      onClick = () => {},
      className = '',
      children,
      isFetching,
      noPointers,
      tt = null,
      at = 'c',
      blur = null,
      color = 'black',
      jc = 'center',
      minWidth,
      size,
      arrow = false,
      isHovered = false,
      defaultStyle = 'acsent',
      opacityFull = false,
      isRoundedBorders = false,
      nopadding,
      reversed,
      add,
      fw,
      icon,
      ...otherProps
    },
    ref,
  ) => {
    const btnTheme = theme.components.button;
    const { spinnerColor } = btnTheme.styles[defaultStyle];

    const props = {
      $size: size,
      $nopadding: nopadding,
      $minWidth: minWidth,
      $defaultStyle: defaultStyle,
      $reversed: reversed,
      $add: add,
      $fw: fw,
      $icon: icon,
    };

    return (
      <ButtonContainer
        ref={ref}
        className={cn(
          'button',
          {
            '--full': full,
            '--blur': blur,
            '--border-r': isRoundedBorders,
            [`--c-${color}`]: color,
            [`--w-${width}`]: width,
            '--no-pointers': noPointers,
            [`--tt-${tt}`]: tt,
            [`--at-${at}`]: at,
            [`--jc-${jc}`]: jc,
            '--fetching': isFetching,
            '--arrow': arrow,
            '--hovered': isHovered,
            '--opacity-f': opacityFull,
          },
          className,
        )}
        disabled={isDisabled}
        type={type}
        onClick={onClick}
        {...props}
        {...otherProps}
      >
        {isFetching && (
          <Spinner
            defaultStyle="small"
            fillColor={spinnerColor}
            radius={20}
          />
        )}
        {title && (
          <span style={{ visibility: isFetching ? 'hidden' : null }}>
            {title}
          </span>
        )}
        {children}
      </ButtonContainer>
    );
  },
);

Button.displayName = 'Button';

export default Button;
