export const StoriesActionTypes = {
  SET_FETCHING_STORIES: 'SET_FETCHING_STORIES',
  FETCH_STORIES_ERROR: 'FETCH_STORIES_ERROR',

  CLEAR_STORIES_HOME: 'CLEAR_STORIES_HOME',
  SET_STORIES_HOME_IS_EMPTY: 'SET_STORIES_HOME_IS_EMPTY',
  FETCH_STORIES_HOME_SUCCESS: 'FETCH_STORIES_HOME_SUCCESS',

  SET_FETCHING_GET_GROUP_STORY: 'SET_FETCHING_GET_GROUP_STORY',
  FETCH_GET_GROUP_STORY_SUCCESS: 'FETCH_GET_GROUP_STORY_SUCCESS',
  FETCH_GET_GROUP_STORY_ERROR: 'FETCH_GET_GROUP_STORY_ERROR',
  CLEAR_GROUP_STORY: 'CLEAR_GROUP_STORY',

  SET_FETCHING_GROUP_STORY_SUBMITTING: 'SET_FETCHING_GROUP_STORY_SUBMITTING',

  SET_FETCHING_GROUP_STORY_TOGGLE_ACTIVE: 'SET_FETCHING_GROUP_STORY_TOGGLE_ACTIVE',
  FETCH_GROUP_STORY_TOGGLE_ACTIVE_SUCCESS: 'FETCH_GROUP_STORY_TOGGLE_ACTIVE_SUCCESS',
  FETCH_GROUP_STORY_TOGGLE_ACTIVE_ERROR: 'FETCH_GROUP_STORY_TOGGLE_ACTIVE_ERROR',

  FETCH_GROUP_STORY_CREATE_SUCCESS: 'FETCH_GROUP_STORY_CREATE_SUCCESS',
  FETCH_GROUP_STORY_CREATE_ERROR: 'FETCH_GROUP_STORY_CREATE_ERROR',

  FETCH_GROUP_STORY_UPDATE_SUCCESS: 'FETCH_GROUP_STORY_UPDATE_SUCCESS',
  FETCH_GROUP_STORY_UPDATE_ERROR: 'FETCH_GROUP_STORY_UPDATE_ERROR',

  FETCH_GROUP_STORY_DELETE_SUCCESS: 'FETCH_GROUP_STORY_DELETE_SUCCESS',
  FETCH_GROUP_STORY_DELETE_ERROR: 'FETCH_GROUP_STORY_DELETE_ERROR',

  SET_FETCHING_GROUP_STORY_STORIES_BY_ID: 'SET_FETCHING_GROUP_STORY_STORIES_BY_ID',
  FETCH_GROUP_STORY_STORIES_BY_ID_SUCCESS: 'FETCH_GROUP_STORY_STORIES_BY_ID_SUCCESS',
  FETCH_GROUP_STORY_STORIES_BY_ID_ERROR: 'FETCH_GROUP_STORY_STORIES_BY_ID_ERROR',

  SET_FETCHING_GROUP_STORY_STORIES_SUBMITTING: 'SET_FETCHING_GROUP_STORY_STORIES_SUBMITTING',

  FETCH_GROUP_STORY_STORIES_BY_ID_CREATE_SUCCESS: 'FETCH_GROUP_STORY_STORIES_BY_ID_CREATE_SUCCESS',
  FETCH_GROUP_STORY_STORIES_BY_ID_CREATE_ERROR: 'FETCH_GROUP_STORY_STORIES_BY_ID_CREATE_ERROR',

  FETCH_GROUP_STORY_STORIES_BY_ID_UPDATE_SUCCESS: 'FETCH_GROUP_STORY_STORIES_BY_ID_UPDATE_SUCCESS',
  FETCH_GROUP_STORY_STORIES_BY_ID_UPDATE_ERROR: 'FETCH_GROUP_STORY_STORIES_BY_ID_UPDATE_ERROR',

  FETCH_GROUP_STORY_STORIES_BY_ID_DELETE_SUCCESS: 'FETCH_GROUP_STORY_STORIES_BY_ID_DELETE_SUCCESS',
  FETCH_GROUP_STORY_STORIES_BY_ID_DELETE_ERROR: 'FETCH_GROUP_STORY_STORIES_BY_ID_DELETE_ERROR',

  CLEAR_GROUP_STORY_STORIES: 'CLEAR_GROUP_STORY_STORIES',
  SET_STORY: 'SET_STORY',
  CLEAR_STORY: 'CLEAR_STORY',
};
