import React from 'react';
import styled from 'styled-components';
import { Text } from 'components/typo';

const FormLabelContainer = styled.div`
  margin-bottom: 10px;
`;

const FormLabel = ({
  children = null,
  ...otherProps
}) => (
  <FormLabelContainer {...otherProps}>
    <Text {...otherProps}>{children}</Text>
  </FormLabelContainer>
);

export default FormLabel;
