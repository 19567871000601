import React from 'react';
import s from 'styled-components';
import cn from 'classnames';
import useResponsive from 'components/hooks/useResponsive';
// import DemoItem from './DemoItem'
import ThemeCard from 'pages/interface/Settings/Design/cards/ThemeCard';

const DemosContainer = s.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -16px -10px;

  & > * {
    padding: 16px 10px;
  }
`;
const Demos = ({
  children,
  demos = [],
  prefix = '',
  ...props
}) => {
  const {
    isLaptopBigOrMobile,
    isLaptopOrMobile,
  } = useResponsive();

  return (
    <DemosContainer className={cn('demos', { '--mob': isLaptopBigOrMobile })}>
      {demos.map((item) => (
        <div key={item.id}>
          <a
            alt={item.title}
            href={item[`${prefix}_url`]}
            rel="noopener noreferrer"
            target="_blank"
          >
            <ThemeCard
              isLaptopOrMobile={isLaptopOrMobile}
              item={{
                image: item[`${prefix}_img`],
                ...item,
              }}
              {...props}
            />
          </a>
        </div>
      ))}
    </DemosContainer>
  );
};

export default Demos;
