import { AdditionsActionTypes } from 'redux/types/AdditionsTypes';
import { UserActionTypes } from 'redux/types/UserTypes';

const INITIAL_STATE = {
  name: 'additions',
  homeList: [],
  homeListIsEmpty: true,
  modalList: [],
  modalListIsEmpty: true,
  isFetching: false,
  isFetchingToggleActive: false,
  isSubmitting: false,
};

const additionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE };

  case AdditionsActionTypes.SET_FETCHING_ADDITIONS:
    return {
      ...state,
      isFetching: action.payload,
    };

  case AdditionsActionTypes.FETCH_ADDITIONS_HOME_SUCCESS:
    return {
      ...state,
      homeList: action.payload,
    };

  case AdditionsActionTypes.SET_ADDITIONS_HOME_IS_EMPTY:
    return {
      ...state,
      homeListIsEmpty: action.payload,
    };

  case AdditionsActionTypes.CLEAR_ADDITIONS_HOME:
    return {
      ...state,
      homeList: [],
      homeListIsEmpty: false,
    };

  case AdditionsActionTypes.FETCH_ADDITIONS_MODAL_SUCCESS:
    return {
      ...state,
      modalList: action.payload,
    };

  case AdditionsActionTypes.SET_ADDITIONS_MODAL_IS_EMPTY:
    return {
      ...state,
      modalListIsEmpty: action.payload,
    };

  case AdditionsActionTypes.CLEAR_ADDITIONS_MODAL:
    return {
      ...state,
      modalList: [],
      modalListIsEmpty: false,
    };

  case AdditionsActionTypes.SET_FETCHING_ADDITION_SUBMITTING:
    return {
      ...state,
      isSubmitting: action.payload,
    };

  case AdditionsActionTypes.FETCH_ADDITION_CREATE_SUCCESS:
    return {
      ...state,
      homeList: [
        ...state.homeList,
        action.payload,
      ].sort((a, b) => {
        if (a.position - b.position > 0) return 1;
        if (a.position - b.position < 0) return -1;

        return 0;
      }),
    };

  case AdditionsActionTypes.FETCH_ADDITION_UPDATE_SUCCESS: {
    const isExistIdx = state.homeList.findIndex((c) => c.id === action.payload.id);

    if (isExistIdx >= 0) {
      return {
        ...state,
        homeList: [
          ...state.homeList.slice(0, isExistIdx),
          action.payload,
          ...state.homeList.slice(isExistIdx + 1),
        ],
      };
    }

    return state;
  }

  case AdditionsActionTypes.FETCH_ADDITION_DELETE_SUCCESS:
    return {
      ...state,
      homeList: state.homeList.filter((c) => c.id !== action.payload),
    };

  case AdditionsActionTypes.SET_FETCHING_ADDITION_TOGGLE_ACTIVE:
    return {
      ...state,
      isFetchingToggleActive: action.payload,
    };

  case AdditionsActionTypes.FETCH_ADDITION_TOGGLE_ACTIVE_SUCCESS:
    return {
      ...state,
      homeList: [
        ...state.homeList.map((p) => (p.id === action.payload.id
          ? action.payload
          : p)),
      ],
    };

  default:
    return state;
  }
};

export default additionsReducer;
