import React from 'react';

// components
import {
  Container,
  Box,
} from 'layout';
import { Title } from 'components/typo';
import {
  Modal,
  ModalContent,
  ModalActions,
} from 'components/modal';
import Logo from 'components/Logo';
import SubscriptionsBlock from 'components/SubscriptionsBlock';
import ModalSubscriptionsBanner from 'components/modals/ModalSubscriptionsBanner';
import Positioner from 'components/base/Positioner';

const ModalSubscriptions = ({
  closeModal,
  isLaptopOrMobile,
}) => (
  <Modal
    flex
    size="full"
  >
    <Positioner
      i="10"
      left="0"
      right="0"
      top="0"
    >
      <ModalActions
        btnStyle="white"
        closeModal={closeModal}
      />
      <ModalSubscriptionsBanner isLaptopOrMobile={isLaptopOrMobile} />
    </Positioner>
    <ModalContent
      column
      display="flex"
      p={`${isLaptopOrMobile ? '160px' : '130px'} 46px 46px 46px`}
    >
      <Box
        flex
        ai="center"
        direction="column"
        jc="center"
      >
        <Box self="stretch">
          <div>
            <Box
              className={isLaptopOrMobile ? 'mb-3' : 'mb-5'}
              jc="center"
            >
              <Logo
                nopointers
                notitle
                defaultStyle="black"
              />
            </Box>
            <div className={isLaptopOrMobile ? 'mb-4' : 'mb-7'}>
              <Title
                center
                tag="h2"
              >
                Выберите тариф
              </Title>
            </div>
            <Container>
              <SubscriptionsBlock />
            </Container>
          </div>
        </Box>
      </Box>
    </ModalContent>
  </Modal>
);

export default ModalSubscriptions;
