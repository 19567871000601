import React from 'react';
import s from 'styled-components';
import { Text } from 'components/typo';

const DropdownMenuItemContainer = s.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: ${({
    theme,
    $light,
  }) => ($light ? theme.colors.grey500 : theme.colors.black)};
  cursor: pointer;

  &:hover {
    color: ${({
    theme,
    $dark,
  }) => ($dark ? theme.colors.grey700 : theme.colors.black)};

    .w-dropdown-icon {
      color: currentColor;
    }
  }

  .w-dropdown {
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      margin-right: 20px;
      padding: 3px;
      color: ${({ theme }) => theme.colors.grey500};
      transition: color 0.3s ease-in-out;

      svg {
        fill: currentColor;
      }
    }

    &-title {
      white-space: nowrap;
    }
  }
`;

const DropdownMenuItem = ({
  children,
  icon: Icon = null,
  iconWidth = 24,
  iconHeight = 24,
  light,
  dark,
  title = null,
  ...otherProps
}) => (
  <DropdownMenuItemContainer
    {...otherProps}
    className="w-dropdown"
    $light={light}
    $dark={dark}
  >
    {Icon && (
      <div className="w-dropdown-icon">
        <Icon
          height={iconHeight}
          width={iconWidth}
        />
      </div>
    )}
    {children}
    {title && (
      <Text
        transitionOn
        className="w-dropdown-title"
        color="inherit"
      >
        {title}
      </Text>
    )}
  </DropdownMenuItemContainer>
);

export default DropdownMenuItem;
