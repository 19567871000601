import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import s from 'styled-components';
import placeholder from 'assets/icons/round-placeholder.svg';
import { checkCorrectUrl } from 'helpers/System';

const defaultStyles = ({
  src,
  theme,
  $variant = 'normal',
  $isDisabled = false,
  $background,
  $width,
  $height,
  $objectFit,
  $defaultBg,
  $br,
}) => {
  const sizes = theme.components.cardImage[$variant] || theme.components.cardImage.normal;

  return `
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: ${$height || (sizes.height ? `${sizes.height}px` : '')};
    width: ${$width || (sizes.width ? `${sizes.width}px` : '')};
    border-radius: ${$br || (sizes.br ? `${sizes.br}px` : 0)};
    opacity: ${$isDisabled ? 0.5 : 1};
    overflow: ${!$background ? 'hidden' : 'unset'};
    transition: opacity 0.3s ease-in-out;
    background-color: ${!src ? theme.colors.grey200 : $defaultBg ? theme.colors[$defaultBg] : theme.colors.grey200};
    object-fit: ${$objectFit};
    `;
};

const bgStyles = ({ src }) => `
  background-image: url(${checkCorrectUrl(src)});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Image = s.img`
  ${defaultStyles}
`;

const ImageBackground = s.div`
  ${defaultStyles}
  ${bgStyles}
`;

export const LazyImage = ({
  src,
  alt,
  isActive = undefined,
  handleLoad = () => {},
  srcSet = null,
  isBackground = false,
  objectFit = '',
  defaultBg = '',
  variant,
  isDisabled,
  width,
  height,
  background,
  br = '',
  isFocused,
  isHovered,
  ...props
}) => {
  const [
    imageSrc,
    setImageSrc,
  ] = useState(null);
  const [
    imageSrcSet,
    setImageSrcSet,
  ] = useState(null);
  const [
    imageRef,
    setImageRef,
  ] = useState();

  const onLoad = (event) => {
    event.target.classList.add('loaded');
    handleLoad();
  };

  const onError = (event) => {
    event.target.classList.add('has-error');
    setImageSrc(null);
  };

  const imageProps = useMemo(() => ({
    $variant: variant,
    $isDisabled: isDisabled,
    $background: background,
    $width: width,
    $height: height,
    $objectFit: objectFit,
    $defaultBg: defaultBg,
    $br: br,
    $isFocused: isFocused,
    $isHovered: isHovered,
    ...props,
  }), [
    variant,
    isDisabled,
    background,
    width,
    height,
    objectFit,
    defaultBg,
    br,
    isFocused,
    isHovered,
    props,
  ]);

  useEffect(() => {
    let observer;
    let didCancel = false;

    if (imageRef && imageSrc !== src) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                if (isActive || isActive === undefined) {
                  if (src) {
                    setImageSrc(checkCorrectUrl(src));
                  }
                  if (srcSet) {
                    setImageSrcSet(checkCorrectUrl(srcSet));
                  }

                  observer.unobserve(imageRef);
                }
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: '75%',
          },
        );
        observer.observe(imageRef);
      }
      else {
        // Old browsers fallback
        setImageSrc(src || placeholder);

        if (srcSet) {
          setImageSrcSet(srcSet || placeholder);
        }
      }
    }

    return () => {
      didCancel = true;
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef);
      }
    };
  }, [
    src,
    imageSrc,
    imageRef,
    isActive,
    srcSet,
  ]);

  return isBackground && imageSrc ? (
    <ImageBackground
      src={imageSrc}
      {...imageProps}
    />
  ) : (
    <Image
      ref={setImageRef}
      alt={alt}
      src={imageSrc}
      srcSet={imageSrcSet}
      onError={onError}
      onLoad={onLoad}
      {...imageProps}
    />
  );
};

export default LazyImage;
