import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import s from 'styled-components';
import { theme } from 'theme';

const Styles = ({
  theme,
  $color,
  $cursor,
  $tag,
  $noPointers,
  $pointer,
  $nowrap,
  $hoverColor,
  $textOverflow,
  $transitionOn,
  $td,
}) => `
  color: ${theme.colors[$color] || $color || theme.colors.black};
  cursor: ${$tag === 'a' || $pointer ? 'pointer' : $cursor};
  pointer-events: ${$noPointers ? 'none' : 'initial'};
  white-space: ${$nowrap ? 'nowrap' : 'initial'};
  text-decoration: ${$td || 'none'};
  transition: ${$tag === 'a' || $hoverColor || $transitionOn ? 'color 0.3s ease-in-out' : 'none'};

  &.--a {
    &-center {
      text-align: center;
    }
    &-right {
      text-align: right;
    }
  }

  ${$hoverColor
    ? `
      &:hover {
        color: ${theme.colors[$hoverColor]};
      }`
    : ''}

  ${$textOverflow
    ? `
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      `
    : ''}

`;

const TextContainerDiv = s.div`
  ${(props) => Styles(props)}
`;

const TextContainerP = s.p`
  ${(props) => Styles(props)}
`;

const TextContainerSpan = s.span`
  ${(props) => Styles(props)}
`;

const TextContainerLink = s.a`
  ${(props) => Styles(props)}
`;

const Text = ({
  children,
  align,
  tag,
  size = 'normal',
  color,
  fw,
  className,
  tt,
  title,
  cursor = 'inherit',
  hoverColor = null,
  textOverflow = false,
  transitionOn = false,
  nowrap = false,
  pointer,
  td,
  fs,
  noPointers,
  isElipsed,
  ...otherProps
}) => {
  const classes = cn(
    'w-text',
    {
      [`--a-${align}`]: align,
      [`--fs-${fs}`]: fs,
      [`text-fw${fw}`]: fw,
      [`text-t${tt}`]: tt,
      [`text-${size}`]: size,
      'text-elipsed': isElipsed,
    },
    className,
  );

  const props = {
    className: classes,
    $color: color,
    $cursor: cursor,
    $tag: tag,
    $td: td,
    $nowrap: nowrap,
    $hoverColor: hoverColor,
    $textOverflow: textOverflow,
    $transitionOn: transitionOn,
    $pointer: pointer,
    $noPointers: noPointers,
    ...otherProps,
  };

  if (tag === 'p') {
    return <TextContainerP {...props}>{children || title}</TextContainerP>;
  } if (tag === 'span') {
    return <TextContainerSpan {...props}>{children || title}</TextContainerSpan>;
  } if (tag === 'a') {
    return <TextContainerLink {...props}>{children || title}</TextContainerLink>;
  }

  return <TextContainerDiv {...props}>{children || title}</TextContainerDiv>;
};

Text.propTypes = { color: PropTypes.oneOf(Object.keys(theme.colors)) };

export default Text;
