import React from 'react';
import s from 'styled-components';

const defaultStyles = ({
  theme,
  $square = false,
  $banner = false,
  $variant = 'normal',
  $maxWidth = null,
  $minWidth = null,
  $round,
  $bg = 'grey200',
}) => {
  const type = $square ? 'square' : $banner ? 'banner' : 'line';
  const sizes = theme.components.cardPlaceholder[type][$variant] || theme.components.cardPlaceholder[type].normal;

  return `
    display: block;
    background-color: ${theme.colors[$bg]};
    height: ${sizes.height}px;
    width: ${sizes.width ? `${sizes.width}px` : '100%'};
    border-radius: ${$round ? '50%' : sizes.radius ? `${sizes.radius}px` : theme.radius.normal};
    max-width: ${$maxWidth ? `${$maxWidth}px` : 'unset'};
    min-width: ${$minWidth ? `${$minWidth}px` : 'unset'};
  `;
};

const CardPlaceholderContainer = s.span`
  ${(props) => defaultStyles(props)}
`;

const CardPlaceholderSquare = ({
  square,
  banner,
  variant,
  maxWidth,
  minWidth,
  round,
  bg,
  ...props
}) => (
  <CardPlaceholderContainer
    className="card-placeholder"
    $square={square}
    $banner={banner}
    $variant={variant}
    $maxWidth={maxWidth}
    $minWidth={minWidth}
    $round={round}
    $bg={bg}
    {...props}
  />
);

export default CardPlaceholderSquare;
