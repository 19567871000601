import s from 'styled-components';
import { OutsideAlerter } from 'utils';

const FormFieldPopupContainer = s(OutsideAlerter)`
  position: absolute;
  top: ${({ top }) => top || '100%'};
  right: 0;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.whitet90};
  border: ${({ theme }) => `0.5px solid ${theme.colors.grey300}`};
  border-radius: 10px;
  box-shadow: 4px 10px 25px rgba(113, 128, 150, 0.12);
  backdrop-filter: blur(50px);
`;

const FormFieldPopup = ({
  onOutside,
  children,
}) => (
  <FormFieldPopupContainer
    onClick={(e) => e.stopPropagation()}
    onClickOutside={onOutside}
  >
    {children}
  </FormFieldPopupContainer>
);

export default FormFieldPopup;
