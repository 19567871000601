import {
  format,
  parse,
  isToday,
  isSameDay,
} from 'date-fns';
import ruLocale from 'date-fns/locale/ru';

export const formatDateDayAndMonth = (date) => format(new Date(date), 'd MMMM', { locale: ruLocale });

export const formatDateDayMonthYear = (date) => format(new Date(date), 'd MMMM yyyy', { locale: ruLocale });

export const formatDate = (date, dateFormat = 'MM.dd.yyyy') => (date ? format(new Date(date), dateFormat, { locale: ruLocale }) : null);

export const formatDateOrToday = (date, dateFormat = 'dd.MM.yy') => {
  const dateFormated = new Date(date);
  const today = isToday(dateFormated);

  if (today) return 'cегодня';

  return format(dateFormated, dateFormat, { locale: ruLocale });
};

export const formatDateToQuery = (date, dateFormat = 'yyyy-MM-dd') => formatDate(date, dateFormat);

export const formatTime = (time, formater = 'HH:mm') => format(parse(time, 'HH:mm:ss', new Date()), formater);

export const dateIsExpired = (date) => (new Date()
  .getTime() > new Date(date)
  .getTime());

export const isInOneDay = (first, second) => isSameDay(new Date(first), new Date(second));

export const renderDateOrToday = (date, formatter = 'd MMMM yyyy') => {
  const formatedDate = new Date(date);

  if (isToday(formatedDate)) {
    return 'Сегодня';
  }

  return formatDate(formatedDate, formatter);
};

export const parseDate = (str) => {
  const mdy = str.split('/');

  return new Date(mdy[2], mdy[0] - 1, mdy[1]);
};

export const getDaysDiff = (first, second) => Math.round((second - first) / (1000 * 60 * 60 * 24));

export const getEventSentDate = (date = '', time = '') => `${formatDate(date, 'yyyy-MM-dd')}T${time}`;
