import React from 'react';
import { Bage } from 'components/base';

const CardDiscount = ({
  discount,
  variant = 'orange',
}) => (
  <Bage
    title={`-${discount}%`}
    variant={variant}
  />
);

export default CardDiscount;
