/* eslint-disable indent */
import React, { useState } from 'react';
import s from 'styled-components';
import { Box } from 'layout';
import {
Card,
CardPlaceholder,
} from 'components/cards';
import { firstLetterUp } from 'utils/string';
import { Text } from 'components/typo';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import WithHover from 'components/hooks/WithHover';

const ThemeCardContainer = s(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 250px;
  height: 426px;
  color: ${(
    {
      theme,
      active,
      custom,
    },
  ) => (custom ? theme.colors.white : active
  ? theme.colors.black
  : theme.colors.grey500)};
  background-color: ${(
    {
      theme,
      active,
      custom,
    },
  ) => (active
  ? theme.colors.grey200
  : custom && !active
  ? theme.colors.purple
  : theme.colors.grey100)};
  border-radius: ${({ theme }) => theme.paddings.normal};
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;

  &:focus {
    outline: unset;
  }

  .inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${({ theme }) => theme.paddings.normal};
    padding-bottom: 0;

    button {
      height: 24px;
      padding: 0;
      color: inherit;
    }

    img {
      width: auto;
      max-width: 226px;
      max-height: 346px;
      margin: 0 auto;
      margin-bottom: -1px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }

  .head {
    margin-bottom: 27px;
  }

  .body {
    margin-top: auto;
  }
`;

const ThemeCard = ({
  item,
  active,
  isHovered,
  ...otherProps
}) => {
  const [
 imgLoaded,
setImgLoaded,
] = useState(false);

  // useEffect(() => {
  //   if (item && item.image && imgLoaded) {
  //     setImgLoaded(false)
  //   }
  //   // eslint-disable-next-line
  // }, [ item.image ])

  return (
    <ThemeCardContainer
      isAnimated
      pointer
      active={active}
      custom={item.id === 'custom'}
      {...otherProps}
    >
      <div className="inner">
        {imgLoaded
          ? (

            <div className="head">
              <Box
                ai="center"
                className="head-title"
                jc="space-between"
              >
                <Text
                  color="current"
                  fw="sb"
                  size="medium"
                >
                  {item.title
                    ? (
                      <>
                        {item.title}
                        <br />
                        {item.title2}
                      </>
                    )
                    : firstLetterUp(item.id)}
                </Text>

                {active && (
                <IconCheck
                  fill="currentColor"
                  height={14}
                  width={20}
                />
)}
              </Box>
              {item.description && (
              <Text
                className="mb-4 mt-3"
                color="whitet70"
              >
                {item.description}
              </Text>
)}
              {item.action && item.action({ isHovered })}
            </div>
          ) : <CardThemePlaceholder />}
        <div className="body">
          <img
            alt={item.title}
            src={item.image}
            style={imgLoaded ? null : { display: 'none' }}
            onLoad={() => setImgLoaded(true)}
          />
        </div>
      </div>
    </ThemeCardContainer>
  );
};

const CardThemePlaceholderContainer = s.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.grey200};
  border-radius: 16px;

  .theme-inner {
    height: 100%;
    padding: 24px 32px 0 24px;
  }

  .theme-img-p {
    width: 90%;
    height: 346px;
    margin-top: auto;
    margin-right: auto;
    margin-left: auto;
    background-color: ${({ theme }) => theme.colors.white};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

const CardThemePlaceholder = () => (
  <CardThemePlaceholderContainer>
    <Box
      className="theme-inner"
      direction="column"
    >
      <Box className="mb-5">
        <CardPlaceholder
          bg="white"
          maxWidth="164"
          minWidth="164"
        />
      </Box>
      <div className="theme-img-p" />
    </Box>
  </CardThemePlaceholderContainer>
);

export default WithHover(ThemeCard);
