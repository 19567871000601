import React from 'react';
import { formOrderTypes } from 'constants/FormOrderTypes';

// components
import { Box } from 'layout';
import {
  Modal,
  ModalBanner,
  ModalContent,
  ModalActions,
  ModalContentWrapper,
} from 'components/modal';
// import FormRequest from 'components/form/FormRequest'
import FormQuestion from 'components/form/FormQuestion';

// images
import { ReactComponent as Icon } from 'assets/icons/multy/mail-green.svg';
import bgImage from 'assets/images/modals/help-bg.jpg';

const ModalHelp = ({
  closeModal,
  isLaptopBigOrMobile,
  isLaptopOrMobile,
}) => (
  <Modal
    flex
    size="full"
  >
    <ModalActions closeModal={closeModal} />
    {!isLaptopBigOrMobile && (
      <ModalBanner
        center
        description="Наш менеджер свяжется с вами в ближайшее время и ответит на все вопросы"
        img={bgImage}
        title="Мы всегда готовы помочь разобраться в деталях"
      />
    )}
    <ModalContentWrapper>
      <ModalContent p="withSidebar">
        <Box
          flex
          ai="center"
          direction="column"
          height="100%"
          jc="center"
        >
          <Box className={isLaptopOrMobile ? 'mb-3' : 'mb-30'}>
            <Icon
              height={49}
              width={55}
            />
          </Box>
          <Box self="stretch">
            <FormQuestion
              title="Написать в поддержку"
              type={formOrderTypes.PROBLEM}
            />
          </Box>
        </Box>
      </ModalContent>
    </ModalContentWrapper>
  </Modal>
);

export default ModalHelp;
