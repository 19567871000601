import React, { useMemo } from 'react';
import cn from 'classnames';
import { Text } from 'components/typo';

const CardContentText = ({
  isActive,
  isHovered,
  title,
  description,
  descriptionSize = 'small',
  initColor = 'black',
  ...props
}) => {
  const titleColor = useMemo(() => {
    let color = initColor;

    if (!isActive && !isHovered) {
      color = 'grey500';
    }
    else if (isHovered) {
      color = 'grey700';
    }

    return color;
  }, [
    isActive,
    isHovered,
    initColor,
  ]);

  const descriptionColor = useMemo(() => {
    let color = 'grey500';

    if (isHovered) {
      color = 'grey700';
    }

    return color;
  }, [ isHovered ]);

  return (
    <>
      {title && (
        <Text
          noPointers
          transitionOn
          className={cn({ 'mb-1': description })}
          color={titleColor}
          {...props}
        >
          {title}
        </Text>
      )}
      {description && (
        <Text
          noPointers
          transitionOn
          color={descriptionColor}
          size={descriptionSize}
          {...props}
        >
          {`${description}`}
        </Text>
      )}
    </>
  );
};

export default CardContentText;
