import React from 'react';
import s from 'styled-components';
import cn from 'classnames';

const getStyles = ({
  $position,
  $top,
  $bottom,
  $left,
  $right,
  $width,
  $height,
  $i,
  $opacity,
}) => `
  position: ${$position};
  top: ${$top};
  bottom: ${$bottom};
  left: ${$left};
  right: ${$right};
  width: ${$width};
  height: ${$height};
  z-index: ${$i};
  opacity: ${$opacity};
  transition: opacity 0.3s ease-in-out;

  &.--t {
    &-center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-center-x {
      left: 50%;
      transform: translateX(-50%);
    }

    &-center-y {
      left: 50%;
      transform: translateY(-50%);
    }

    &-hidden {
      opacity: 0;
      pointer-eventd: none;
      height: 0px;
      width: 0px;
      overflow: hidden;
    }
  }
`;

const PositionerContainer = s.div`
  ${getStyles}
`;

const Positioner = ({
  children = null,
  className = '',
  top = '',
  bottom = '',
  left = '',
  width = '',
  height = '',
  right = '',
  position = 'absolute',
  type = '',
  opacity = '',
  i = '',
}) => (
  <PositionerContainer
    $bottom={bottom}
    $height={height}
    $i={i}
    $left={left}
    $opacity={opacity}
    $position={position}
    $right={right}
    $top={top}
    $width={width}
    className={cn(
      'positioner',
      { [`--t-${type}`]: type },
      className,
    )}
  >
    {children}
  </PositionerContainer>
);

export default Positioner;
