import React from 'react';
import s from 'styled-components';
import PropTypes from 'prop-types';
import { theme } from 'theme';

export { Text } from 'components/typo';

const defaultStyles = ({
  theme,
  $br = 6,
  $tt = 'uppercase',
  $variant = 'grey',
  $height = '',
  $width = '',
}) => {
  const styles = theme.components.bage[$variant] || theme.components.bage.grey;

  return `
    display: inline-flex;
    padding: 5px 8px;
    background-color: ${styles.bg};
    color: ${styles.color};
    border-radius: ${$br}px;
    height: ${$height || ''};
    min-width: ${$width || ''};
    font-size: 14px;
    line-height: ${$height ? $height - '5px' : '21px'};
    font-weight: ${theme.fw.sb};
    white-space: nowrap;
    text-transform: ${$tt};
    letter-spacing: 1px;
  `;
};

const BageContainer = s.div`
  ${(props) => defaultStyles(props)}
`;

const Bage = ({
  title,
  children,
  br,
  tt,
  width,
  height,
  variant = 'grey',
  ...otherProps
}) => (
  <BageContainer
    className="w-bage"
    $variant={variant}
    $br={br}
    $tt={tt}
    $width={width}
    $height={height}
    {...otherProps}
  >
    <span>{title || children}</span>
  </BageContainer>
);

const bageVariants = Object.keys(theme.components.bage);

Bage.propTypes = { variant: PropTypes.oneOf(bageVariants) };

export default Bage;
