import React, { Suspense } from 'react';
import Spinner from 'components/base/Spinner';

const SuspenseContainer = ({ children }) => (
  <Suspense fallback={<Spinner size="medium" />}>
    {children}
  </Suspense>
);

export default SuspenseContainer;
