import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Formik,
  Field,
} from 'formik';
import { SchemaLogin } from 'constants/ValidationSchemas';
import {
  Link,
  Title,
} from 'components/typo';

// components
import { Button } from 'components/base';
import {
  Form,
  FormField,
  FormGroup,
} from 'components/form';
import useResponsive from 'components/hooks/useResponsive';

// actions
import { fetchGetAuthToken } from 'redux/actions/AuthActions';
import { setModal } from 'redux/actions/SystemActions';

// constants

const Login = () => {
  const dispatch = useDispatch();
  const { isLaptopOrMobile } = useResponsive();

  const { auth: isFetchingAuth } = useSelector((state) => state.user.isFetching);
  const { modal } = useSelector((state) => state.system);

  const handleForgetModal = (e) => {
    e.preventDefault();
    dispatch(setModal({
      name: 'forget-password',
      back: true,
    }));
  };

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={SchemaLogin}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(fetchGetAuthToken(values))
          .then(() => {
            setSubmitting(false);
            if (modal && modal.name) dispatch(setModal({ close: true }));
          })
          .catch(() => setSubmitting(false));
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        } = props;

        const attrs = {
          onChange: (e) => {
            e.target.value = e.target.value.trim();
            handleChange(e);
          },
          onBlur: handleBlur,
          errors,
          touched,
          size: 'big',
        };

        const formIsValid = dirty && !errors.username && values.username.length && !errors.password && values.password.length && !isSubmitting;

        return (
          <Form onSubmit={handleSubmit}>
            <div className={isLaptopOrMobile ? 'mb-20' : 'mb-5'}>
              <Title
                center
                tag="h2"
              >
                Войти в аккаунт
              </Title>
            </div>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    autoFocus
                    name="username"
                    placeholder="Email"
                    type="text"
                    autoComplete="email"
                    value={values.username.trim()}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    name="password"
                    placeholder="Пароль"
                    type="password"
                    value={values.password.trim()}
                    autoComplete="current-password"
                  />
                )}
              </Field>
            </FormGroup>
            <div className={isLaptopOrMobile ? 'my-20' : 'my-4'}>
              <Link
                to="/#forget-password"
                onClick={handleForgetModal}
              >
                Забыли пароль?
              </Link>
            </div>
            <FormGroup>
              <Button
                full
                defaultStyle="acsent"
                id="sign-in-button"
                isDisabled={!formIsValid || isFetchingAuth}
                isFetching={isFetchingAuth || isSubmitting}
                size="big"
                title="Войти в аккаунт"
                type="submit"
              />
            </FormGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Login;
