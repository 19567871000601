import React from 'react';
import { useDispatch } from 'react-redux';

// components
import { Box } from 'layout';
import { ButtonArrow } from 'components/buttons';
import {
  Modal,
  ModalBanner,
  ModalContent,
  ModalActions,
  ModalContentWrapper,
} from 'components/modal';
import Login from 'components/Login';

// images
import { ReactComponent as Icon } from 'assets/icons/multy/profile-green.svg';
import bgImage from 'assets/images/modals/login-bg.jpg';

// redux
import { setModal } from 'redux/actions/SystemActions';

const ModalLogin = ({
  closeModal,
  isLaptopOrMobile,
  isLaptopBigOrMobile,
}) => {
  const dispatch = useDispatch();

  const actions = (
    <ButtonArrow
      defaultStyle="blured"
      size="big"
      title="Создать аккаунт"
      onClick={() => dispatch(setModal({ name: 'signup' }))}
    />
  );

  return (
    <Modal
      flex
      size="full"
    >
      <ModalActions closeModal={closeModal} />
      {!isLaptopBigOrMobile && (
        <ModalBanner
          center
          actions={actions}
          author={null}
          description="Зарегистрируйтесь в нашем сервисе, чтобы создать мобильное приложение."
          img={bgImage}
          title="Нет аккаунта в Приложении?"
        />
      )}
      <ModalContentWrapper>
        <ModalContent p="withSidebar">
          <Box
            flex
            ai="center"
            direction="column"
            height="100%"
            jc="center"
          >
            <Box className={isLaptopOrMobile ? 'mb-3' : 'mb-30'}>
              <Icon
                height={55}
                width={44}
              />
            </Box>
            <Box self="stretch">
              <Login />
            </Box>
          </Box>
        </ModalContent>
      </ModalContentWrapper>
    </Modal>
  );
};

export default ModalLogin;
