import React from 'react';

// components
import {
  Container,
  Box,
} from 'layout';
import Logo from 'components/Logo';
import { Modal } from 'components/modal';
import { Title } from 'components/typo';
import { Button } from 'components/base';
import useResponsive from 'components/hooks/useResponsive';

const ErrorPage = ({
  title = '',
  btnTitle = 'Перейти на главную',
  htmlIcon = null,
  noBtn = false,
}) => {
  const { isMobile } = useResponsive();

  return (
    <Modal fixedTop>
      <Container
        fullHeight
        className="mb-5"
        size="xs"
      >
        <Box
          ai="center"
          className={isMobile ? 'pt-5 pb-4' : 'pt-60 pb-6'}
          direction="column"
          height="100%"
          jc="space-between"
        >
          <Logo size="normal" />
          <Box
            ai="center"
            className="my-a"
            direction="column"
          >
            <Title
              center
              tag={isMobile ? 'h4' : 'h2'}
            >
              {!!htmlIcon && (
                <span
                  className="mr-3"
                  role="img"
                >
                  {htmlIcon}
                </span>
              )}
              {title}
            </Title>
            {!noBtn && (
              <a
                href="/"
                title="На главную"
              >
                <Button
                  size="big"
                  title={btnTitle}
                />
              </a>
            )}
          </Box>
        </Box>
      </Container>
    </Modal>
  );
};

export default ErrorPage;
