import React, { useMemo } from 'react';
import s from 'styled-components';
import { Text } from 'components/typo';
import { Box } from 'layout';

const CardMenuContainer = s(Box)`
  position: relative;
  flex: ${({ $flex }) => ($flex ? 1 : 'unset')};
  width: ${({
    theme,
    $small,
  }) => ($small ? theme.cards.width.small : 'initial')};
  height: ${({
    $collapsed,
    $height,
  }) => ($collapsed ? 'auto' : $height || '230px')};
  padding: ${({ $collapsed }) => ($collapsed ? '11px 22px' : '22px')};
  color: ${({ theme }) => theme.colors.white};
  text-align: ${({ $ta }) => $ta};
  background-color: ${({
    theme,
    $bg,
  }) => theme.colors[$bg]};
  border-radius: ${({ theme }) => theme.radius.normal};
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-6px);
  }

  .card-icon {
    width: 66px;
    height: 66px;
  }

  .card-inner {
    width: 100%;
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 260px;
    height: 100%;
    background: no-repeat ${({ $imgbg }) => `url(${$imgbg}) `} center bottom;
    background-size: contain;
    content: ${({ $imgbg }) => ($imgbg ? '""' : 'unset')};
  }

  .card-title {
    align-self: ${({ $center }) => ($center ? 'center' : 'flex-start')};
  }
`;

const CardMenu = ({
  children,
  title,
  icon: Icon,
  renderContent,
  ta = 'left',
  imgBg = null,
  bg = 'purple',
  collapsed = false,
  center = false,
  small,
  flex,
  height,
  ...otherProps
}) => {
  const renderTitle = useMemo(() => {
    if (typeof title === 'string') {
      return title;
    }
    if (Array.isArray(title)) {
      const { length } = title;

      if (length === 1) {
        return title[0];
      }

      const mappedTitles = [];

      for (let i = 0; i < length; i++) {
        mappedTitles.push(title[i]);

        if (i < length - 1) {
          mappedTitles.push(<br key={i} />);
        }
      }

      return mappedTitles;
    }

    return '';
  }, [ title ]);

  return (
    <CardMenuContainer
      className="card-menu"
      direction="column"
      ai="center"
      br={16}
      $bg={bg}
      $flex={flex}
      $center={center}
      $collapsed={collapsed}
      $imgbg={imgBg}
      $ta={ta}
      $small={small}
      $height={height}
      {...otherProps}
    >
      {children
      || (
        <>
          {Icon && !collapsed ? (
            <Box
              ai="center"
              className="card-icon my-a"
              jc="center"
            >
              <Icon />
            </Box>
          ) : ''}
          <div className="mt-a card-title">
            <Text
              color="inherit"
              fw="sb"
            >
              {renderTitle}
            </Text>
          </div>
        </>
      )}
    </CardMenuContainer>
  );
};

export default CardMenu;
