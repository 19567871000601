import React from 'react';
import s from 'styled-components';
import cn from 'classnames';
import ButtonRound from 'components/base/ButtonRound';
import { ReactComponent as IconClose } from 'assets/icons/icon-cross.svg';
import { ReactComponent as IconBack } from 'assets/icons/back.svg';

const ModalActionsContainer = s.div`
  position: absolute;
  top: 0;
  bottom: unset;
  z-index: 9999;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  pointer-events: none;

  &.--bottom {
    top: unset;
    bottom: 0;
  }

  & > * {
    pointer-events: all;
  }

  &.--end {
    justify-content: flex-end;
  }

  &.--start {
    justify-content: flex-start;
  }

  &.--between {
    justify-content: space-between;
  }
`;

const ModalActions = ({
  bottom = false,
  closeModal = null,
  prevModal = null,
  btnStyle = '',
}) => (
  <ModalActionsContainer
    className={cn('modal-actions', {
      '--between': closeModal && prevModal,
      '--start': !closeModal && prevModal,
      '--end': closeModal && !prevModal,
      '--bottom': bottom,
    })}
  >
    {prevModal && (
      <ButtonRound
        defaultStyle={btnStyle}
        renderIcon={() => <IconBack />}
        onClick={prevModal}
      />
    )}
    {closeModal && (
      <ButtonRound
        defaultStyle={btnStyle}
        renderIcon={() => <IconClose />}
        onClick={closeModal}
      />
    )}
  </ModalActionsContainer>
);

export default ModalActions;
