import React from 'react';
import { ButtonRound } from 'components/base';
import { ReactComponent as IconAdd } from 'assets/icons/add.svg';

const ButtonRoundAdd = ({
  onClick,
  ...otherProps
}) => (
  <ButtonRound
    {...otherProps}
    bgColor="light"
    padding="normal"
    renderIcon={() => <IconAdd />}
    onClick={onClick}
  />
);

export default ButtonRoundAdd;
