export const messageTypes = {
  STAR: 'star',
  STOP: 'stop',
  CHECK: 'check',
  MESSAGE: 'message',
  ERROR: 'error',
  TRASH: 'trash',
  ORDER: 'order',
};

export const messageTypesColors = {
  ERROR: 'red',
  DEFAULT: 'black',
};
