import React, { Component } from 'react';
import ErrorPage from 'pages/ErrorPage';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(error, 'error derived');

    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error, ' :catch error');
    console.log(info, ' :catch error info');
    // You can also log the error to an error reporting service
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorPage
          htmlIcon="⚙️"
          title="️Что-то пошло не так..."
        />
      );
    }

    return children;
  }
}
