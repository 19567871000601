export const FiltersActionTypes = {
  SET_FILTER: 'SET_FILTER',
  SET_FILTERS: 'SET_FILTERS',
  SET_OFFSET: 'SET_OFFSET',
  SET_LIMIT: 'SET_LIMIT',
  RESET_ALL_FILTERS: 'RESET_ALL_FILTERS',
};

export const FiltersNames = {
  filters: 'filters',
  filtersProducts: 'filtersProducts',
  filtersProductsFormats: 'filtersProductsFormats',
  filtersOrders: 'filtersOrders',
  filtersAnalytics: 'filtersAnalytics',
  filtersAdditions: 'filtersAdditions',
};

export const FiltersNamesTypes = {
  STATUSES: 'statuses',
  CATEGORIES: 'categories',
  SEARCH: 'search',
  LIMIT: 'limit',
  OFFSET: 'offset',
  ORDER_TYPE: 'order_type',
};

export const CATEGORIES_INIT = null;
export const SEARCH_INIT = '';
export const OFFSET_INIT = 0;
export const LIMIT_INIT = 40;
