import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import DropdownMenu from 'components/dropdown/DropdownMenu';
import DropdownMenuItem from 'components/dropdown/DropdownMenuItem';
import { ReactComponent as IconSettings } from 'assets/icons/settings.svg';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';
import { ReactComponent as IconStop } from 'assets/icons/stop.svg';
import { ReactComponent as IconReturn } from 'assets/icons/check-list.svg';
import { ReactComponent as IconAccept } from 'assets/icons/check-circle.svg';
import { ReactComponent as IconReject } from 'assets/icons/reject-circle.svg';
import { ReactComponent as IconDelivery } from 'assets/icons/geo-menu.svg';
import { ReactComponent as IconComplete } from 'assets/icons/bag.svg';
import { ReactComponent as IconInfo } from 'assets/icons/info.svg';
import { ReactComponent as IconStart } from 'assets/icons/start.svg';
import { setModal } from 'redux/actions/SystemActions';

const CardMenuSettings = (props) => {
  const dispatch = useDispatch();
  const {
    onClose = null,
    onStopListToggle = null,
    onEdit = null,
    onDelete = null,
    deleteDirect = null,
    onAccept = null,
    onReject = null,
    onDelivery = null,
    onComplete = null,
    onStart = null,
    onInfo = null,
    isBlocked = false,
    deleteTitle = null,
    onActiveToggle = null,
    additionalMenu = [],
    editTitle,
  } = props;

  const menuItems = useMemo(() => {
    const menu = [];

    if (onStart) {
      menu.push({
        icon: IconStart,
        iconWidth: '28',
        iconHeight: '28',
        title: 'Запустить',
        onClick: (e) => {
          e.stopPropagation();
          onClose();

          return onStart();
        },
      });
    }

    if (onEdit) {
      menu.push({
        icon: IconSettings,
        iconWidth: '28',
        iconHeight: '28',
        title: editTitle || 'Редактировать',
        onClick: (e) => {
          e.stopPropagation();
          onClose();

          return onEdit();
        },
      });
    }

    if (onActiveToggle) {
      menu.push({
        icon: isBlocked ? IconReturn : IconStop,
        iconWidth: isBlocked ? '23.5' : '24',
        iconHeight: isBlocked ? '18' : '24',
        title: isBlocked ? 'Сделать активным' : 'Скрыть',
        onClick: (e) => {
          e.stopPropagation();
          onClose();

          return onActiveToggle();
        },
      });
    }

    if (onStopListToggle) {
      menu.push({
        icon: isBlocked ? IconReturn : IconStop,
        iconWidth: isBlocked ? '23.5' : '24',
        iconHeight: isBlocked ? '18' : '24',
        title: isBlocked ? 'Вернуть в продажу' : 'Стоп-лист',
        onClick: (e) => {
          e.stopPropagation();
          onClose();

          return onStopListToggle();
        },
      });
    }

    if (onDelete) {
      menu.push({
        icon: IconTrash,
        iconWidth: '23',
        iconHeight: '23.5',
        title: 'Удалить',
        onClick: (e) => {
          e.stopPropagation();

          if (deleteDirect) {
            onDelete();
          }
          else {
            dispatch(setModal({
              name: 'confirm',
              type: 'delete',
              center: true,
              title: deleteTitle,
              onYes: (closeModal) => onDelete()
                .then(() => closeModal()),
            }));
          }

          return onClose();
        },
      });
    }

    if (onAccept) {
      menu.push({
        icon: IconAccept,
        iconWidth: '24',
        iconHeight: '24',
        title: 'Подтвердить',
        onClick: (e) => {
          e.stopPropagation();
          onClose();

          return onAccept();
        },
      });
    }

    if (onDelivery) {
      menu.push({
        icon: IconDelivery,
        iconWidth: '18',
        iconHeight: '23.1',
        title: 'Доставить заказ',
        onClick: (e) => {
          e.stopPropagation();
          onClose();

          return onDelivery();
        },
      });
    }

    if (onComplete) {
      menu.push({
        icon: IconComplete,
        iconWidth: '22',
        iconHeight: '24',
        title: 'Выполенен',
        onClick: (e) => {
          e.stopPropagation();

          onClose();

          return onComplete();
        },
      });
    }

    if (onInfo) {
      menu.push({
        icon: IconInfo,
        iconWidth: '22',
        iconHeight: '22',
        title: 'Подробнее',
        onClick: (e) => {
          e.stopPropagation();
          onClose();

          return onInfo();
        },
      });
    }

    if (onReject) {
      menu.push({
        icon: IconReject,
        iconWidth: '24',
        iconHeight: '24',
        title: 'Отменить',
        onClick: (e) => {
          e.stopPropagation();
          onClose();

          return onReject();
        },
      });
    }

    return [
      ...menu,
      ...additionalMenu,
    ];
  }, [
    onEdit,
    onDelivery,
    onActiveToggle,
    onInfo,
    onAccept,
    onReject,
    onComplete,
    onClose,
    onDelete,
    onStart,
    onStopListToggle,
    isBlocked,
    dispatch,
    deleteTitle,
    deleteDirect,
    additionalMenu,
    editTitle,
  ]);

  return (
    <DropdownMenu>
      {menuItems.map((props, idx) => (
        <DropdownMenuItem
          key={idx}
          {...props}
        />
      ))}
    </DropdownMenu>

  );
};

export default CardMenuSettings;
