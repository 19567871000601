import s from 'styled-components';
import { Text } from 'components/typo';
import { Box } from 'layout';
import { Button } from 'components/base';

const CardEmptyContainer = s(Box)`
width: 100%;
padding: 86px 80px 80px 80px !important;
text-align: center;
background-color: ${({ theme }) => theme.colors.grey100};
border-radius: ${({ theme }) => theme.radius.normal};
cursor: pointer;
pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : '')};
opacity: ${({ $isDisabled }) => ($isDisabled ? 0.4 : '')};

.card-icon {
  width: 55px;
  height: 55px;
  background-color: ${({
    theme,
    $bg,
  }) => theme.colors[$bg] || theme.colors.transparent};
  border-radius: 16px;
}

.card-inner {
  width: 100%;
  max-width: 510px;
}

&:hover {
  button {
    color: ${({ theme }) => theme.colors.black} !important;
  }
}
`;

const CardEmpty = ({
  title,
  description,
  icon: Icon,
  btnTitle = '',
  onClick = null,
  isDisabled = false,
  bg,
}) => (
  <CardEmptyContainer
    $isDisabled={isDisabled}
    $bg={bg}
    ai="center"
    br={16}
    className="card-empty"
    direction="column"
    onClick={isDisabled ? null : onClick}
  >
    <Box
      ai="center"
      className="card-icon mb-4"
      jc="center"
    >
      {Icon && <Icon />}
    </Box>
    <div className="mb-4">
      <Text
        fs="medium"
        fw="sb"
      >
        {title}
      </Text>
    </div>
    <div className="card-inner mb-4">
      <Text color="grey500">{description}</Text>
    </div>
    {btnTitle && (
      <Button
        defaultStyle="bordered"
        size="small"
        title={btnTitle}
      />
    )}
  </CardEmptyContainer>
);

export default CardEmpty;
