import { useMediaQuery } from 'react-responsive';

const useResponsive = () => {
  const isXlScreen = useMediaQuery({ query: '(min-width: 1500px)' });
  const isBigScreen = useMediaQuery({ query: '(min-width: 1440px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1282px)' });
  const isDesktopSmall = useMediaQuery({ query: '(min-width: 992px) and (max-width: 1281px)' });
  const isLaptop = useMediaQuery({ query: '(min-width: 769px) and (max-width: 991px)' });
  const isIpadPro = useMediaQuery({ query: '(max-width: 1112px)' });
  const isLaptopBigOrMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isMobileSmall = useMediaQuery({ query: '(max-width: 480px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  return {
    isXlScreen,
    isBigScreen,
    isDesktop,
    isDesktopSmall,
    isLaptopBigOrMobile,
    isLaptop,
    isLaptopOrMobile,
    isMobile,
    isMobileSmall,
    isPortrait,
    isRetina,
    isIpadPro,
  };
};

export default useResponsive;
