import React from 'react';
import s from 'styled-components';
import cn from 'classnames';

const Styles = ({
  $display,
  $direction,
  $m,
  $p,
  $ai,
  $self,
  $gap,
  $jc,
  $height,
  $flex,
  theme,
  $br,
  $width,
  $pointer,
  $textAlign,
  $jcs,
  $maxWidth,
}) => `
  height: ${$height || 'auto'};
  display: ${$display || 'flex'};
  max-width: ${$maxWidth};
  width: ${$width || 'auto'};
  flex-direction: ${$direction || 'row'};
  align-items: ${$ai || 'flex-start'};
  align-self: ${$self || ''};
  justify-content: ${$jc || 'flex-start'};
  justify-self: ${$jcs || 'unset'};
  margin: ${$m || 'unset'};
  padding: ${$p || 'unset'};
  gap: ${$gap || ''};
  flex: ${$flex || 'unset'} !important;
  border-radius: ${theme.radius[$br] || '0'};
  cursor: ${$pointer ? 'pointer' : ''};
  ${$textAlign ? `text-align: ${$textAlign};` : ''}
`;

const BoxContainerDiv = s.div`
  ${(props) => Styles(props)}
`;

const BoxContainerSpan = s.span`
  ${(props) => Styles(props)}
`;

const BoxContainerLabel = s.label`
  ${(props) => Styles(props)}
`;

const Box = ({
  children = null,
  as = null,
  className = '',
  br = 'unset',
  maxWidth = '',
  display,
  direction,
  m,
  p,
  ai,
  self,
  jc,
  jcs,
  height,
  flex,
  width,
  pointer,
  textAlign,
  gap,
  ...otherProps
}) => {
  const classes = cn('w-box', className);
  const props = {
    $br: br,
    className: classes,
    $maxWidth: maxWidth,
    $display: display,
    $direction: direction,
    $m: m,
    $p: p,
    $ai: ai,
    $self: self,
    $jc: jc,
    $gap: gap,
    $height: height,
    $flex: flex,
    $width: width,
    $pointer: pointer,
    $textAlign: textAlign,
    $jcs: jcs,
    ...otherProps,
  };

  if (as === 'span') {
    return <BoxContainerSpan {...props}>{children}</BoxContainerSpan>;
  } if (as === 'label') {
    return <BoxContainerLabel>{children}</BoxContainerLabel>;
  }

  return <BoxContainerDiv {...props}>{children}</BoxContainerDiv>;
};

export default Box;
