import React from 'react';
import { Box } from 'layout';
import { Text } from 'components/typo';
import { PRODUCT_QUANTITYS_SHORT } from 'constants/ProductsTypes';

const CardPrice = ({
  rawPrice,
  price,
  amount = null,
  measure,
  isActive,
  isHovered,
}) => (
  <Box jc="flex-end">
    {amount && amount > 0 ? (
      <Text
        color="grey500"
        fw="m"
        tag="span"
      >
        {String(amount)
          .replace('.00', '')}
        {' '}
        {measure ? PRODUCT_QUANTITYS_SHORT[measure] || 'x' : 'x'}
        {' '}
      </Text>
    ) : ''}
      &nbsp;
    {rawPrice && (
      <Text
        noPointers
        nowrap
        className="mr-1"
        color="grey500"
        td="line-through"
      >
        {rawPrice}
      </Text>
    )}
    <Text
      noPointers
      nowrap
      transitionOn
      color={isHovered ? 'grey700' : isActive ? 'black' : 'grey500'}
      fw="m"
    >
      {price}
      {' '}
      ₽
    </Text>
  </Box>
);

export default CardPrice;
