import React, {
  useMemo,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as IconSave } from 'assets/icons/confirmModal/save.svg';
import { ReactComponent as IconReset } from 'assets/icons/confirmModal/reset.svg';
import { ReactComponent as IconDelete } from 'assets/icons/confirmModal/delete.svg';
import { ReactComponent as IconExit } from 'assets/icons/confirmModal/exit.svg';
import { ReactComponent as IconSubscription } from 'assets/icons/confirmModal/ghost.svg';
import { ReactComponent as IconWarning } from 'assets/icons/confirmModal/warning.svg';

// components
import {
  Modal,
  ModalContent,
} from 'components/modal';
import { Button } from 'components/base';
import { Text } from 'components/typo';

const ModalConfirm = (props) => {
  const { isSubmitting } = useSelector((state) => state.system);
  const {
    closeModal = () => {},
    modal: {
      onYes = () => {},
      onNo = () => {},
      type = '',
      title = '',
      description = '',
      btnYesTitle = '',
    },
  } = props;

  const renderIcon = useCallback(() => {
    let Icon = null;

    switch (type) {
    case 'reset':
      Icon = IconReset;
      break;
    case 'delete':
      Icon = IconDelete;
      break;
    case 'save':
      Icon = IconSave;
      break;
    case 'exit':
      Icon = IconExit;
      break;
    case 'subscription':
      Icon = IconSubscription;
      break;
    case 'cancle-subscription':
      Icon = IconWarning;
      break;

    default:
      break;
    }

    return Icon ? (
      <Icon
        fill="#000"
        height={46}
        width={46}
      />
    ) : null;
  }, [ type ]);

  const modalTitle = useMemo(() => {
    if (title) return title;

    switch (type) {
    case 'reset':
      return 'Сбросить все последние дейстивия?';

    case 'delete':
      return 'Вы действительно хотите удалить?';

    case 'save':
      return 'Сохранить последние действия?';

    case 'exit':
      return 'Вы действительно хотите выйти?';

    case 'subscription':
      return 'Доступно на платном тарифе';

    case 'cancle-subscription':
      return 'Точно хотите изменить тариф?';

    default:
      return 'Заголовок не указан';
    }
  }, [
    type,
    title,
  ]);

  const modalDescription = useMemo(() => {
    if (description) return description;

    switch (type) {
    case 'subscription':
      return 'Выберите свой тариф и расширьте возможности своего бизнеса';

    case 'cancle-subscription':
      return 'Ваше приложение будет скрыто из Apple Store и Google Play. Это действие нельзя отменить.';

    default:
      return 'Это действие нельзя отменить.';
    }
  }, [
    type,
    description,
  ]);

  const btnAttributes = useMemo(() => {
    const attrs = {
      defaultStyle: 'acsent',
      title: 'Сохранить',
    };

    switch (type) {
    case 'reset':
      attrs.title = 'Сбросить';
      attrs.defaultStyle = 'purple';
      break;

    case 'delete':
      attrs.title = btnYesTitle || 'Удалить';
      attrs.defaultStyle = 'orange';
      break;

    case 'exit':
      attrs.title = btnYesTitle || 'Выйти';
      attrs.defaultStyle = 'purple';
      break;

    case 'subscription':
      attrs.title = 'Выбрать тариф';
      attrs.defaultStyle = 'purple';
      break;

    case 'cancle-subscription':
      attrs.title = 'Перейти на Старт';
      attrs.defaultStyle = 'orange';
      break;

    case 'save':
    default:
      break;
    }

    return attrs;
  }, [
    type,
    btnYesTitle,
  ]);

  const handleYes = () => {
    onYes(closeModal);
  };

  const handleNo = () => {
    onNo(closeModal);
    closeModal();
  };

  return (
    <Modal
      center
      width="confirm"
    >
      <ModalContent
        column
        display="flex"
        p="39px 36px 36px 36px"
      >
        <div className="mb-4">
          {renderIcon()}
        </div>
        <div className="mb-3">
          <Text
            align="center"
            fw="sb"
          >
            {modalTitle}
          </Text>
        </div>
        {type !== 'save' && type !== 'exit' && (
          <div className="mb-4">
            <Text
              align="center"
              color="grey500"
            >
              {modalDescription}
            </Text>
          </div>
        )}
        <Button
          {...btnAttributes}
          full
          className="mb-20"
          isDisabled={isSubmitting}
          isFetching={isSubmitting}
          onClick={handleYes}
        />

        {type !== 'subscription' && (
          <Button
            full
            defaultStyle="whitegrey"
            isDisabled={isSubmitting}
            title="Отмена"
            onClick={handleNo}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export default ModalConfirm;
