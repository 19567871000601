import React from 'react';
import s from 'styled-components';
import cn from 'classnames';

export const ptDefaultD = '80px';
export const pbDefaultD = '100px';
export const ptDefaultL = '50px';
export const pbDefaultL = '60px';
export const pDefaultM = '32px';

const getStyles = ({
  theme,
  m,
}) => `
  padding-top: ${m || ptDefaultD};
  padding-bottom: ${m || pbDefaultD};

  @media (max-width: 768px) {
    padding-top: ${m || ptDefaultL};
    padding-bottom: ${m || pbDefaultL};
  }

  @media (max-width: 600px) {
    padding-top: ${m || pDefaultM};
    padding-bottom: ${m || pDefaultM};
  }

  &.--rel {
    position: relative;
  }

  &.--o-off {
    overflow: hidden;
  }

  &.--b-top-off {
    padding-top: calc(${(m || ptDefaultD)} + ${theme.radius.xxl}) !important;
    margin-top: -${theme.radius.xxl};

    @media (max-width: 768px) {
      padding-top: calc(${(m || ptDefaultL)} + ${theme.radius.xl}) !important;
      margin-top: -${theme.radius.xl};
    }

    @media (max-width: 600px) {
      padding-top: calc(${(m || pDefaultM)} + ${theme.radius.xl}) !important;
    }
  }

  &.--b-bottom-off {
    padding-bottom: calc(${(m || ptDefaultD)} + ${theme.radius.xxl}) !important;
    margin-bottom: -${theme.radius.xxl};

    @media (max-width: 768px) {
      padding-bottom: calc(${(m || ptDefaultL)} + ${theme.radius.xl}) !important;
      margin-bottom: -${theme.radius.xl};
    }

    @media (max-width: 600px) {
      padding-bottom: calc(${(m || pDefaultM)} + ${theme.radius.xl}) !important;
    }
  }

  .s-anchor {
    position: relative;
    top: -120px;
  }

  &.--bg {
    &-white {
      background-color: ${theme.colors.white};
    }

    &-light {
      background-color: ${theme.colors.grey100};
    }

    &-purple {
      color: ${theme.colors.white};
      background-color: ${theme.colors.purple};
    }
  }

  &.--br {
    &-top {
      border-top-left-radius: ${theme.radius.xxl};
      border-top-right-radius: ${theme.radius.xxl};

      @media (max-width: 768px) {
        border-top-left-radius: ${theme.radius.xl};
        border-top-right-radius: ${theme.radius.xl};
      }
    }

    &-bottom {
      border-bottom-left-radius: ${theme.radius.xxl};
      border-bottom-right-radius: ${theme.radius.xxl};

      @media (max-width: 768px) {
        border-bottom-left-radius: ${theme.radius.xl};
        border-bottom-right-radius: ${theme.radius.xl};
      }
    }

    &-all {
      border-radius: ${theme.radius.xxl};

      @media (max-width: 768px) {
        border-radius: ${theme.radius.xl};
      }
    }
  }
`;

const SectionContainer = s.section`
  ${getStyles}
`;

const Section = ({
  children,
  className = '',
  linkId = null,
  isRelative = false,
  bg = 'white',
  br = '',
  isBrTopOff = false,
  isBrBottomOff = false,
  isOverflowOff = false,
  ...otherProps
}) => (
  <SectionContainer
    {...otherProps}
    className={cn('w-section', {
      '--rel': linkId || isRelative,
      '--b-top-off': isBrTopOff,
      '--b-bottom-off': isBrBottomOff,
      '--o-off': isOverflowOff,
      [`--bg-${bg}`]: bg,
      [`--br-${br}`]: br,
    }, className)}
  >
    {linkId && (
      <span
        className="s-anchor"
        id={linkId}
      />
    )}
    {children}
  </SectionContainer>
);

export default Section;
