/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import isValidProp from '@emotion/is-prop-valid';
import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import s, { StyleSheetManager } from 'styled-components';
import cn from 'classnames';
import {
  Input,
  FormError,
} from 'components/form';
import { ButtonRound } from 'components/base';
import { ReactComponent as IconShow } from 'assets/icons/icon-eye.svg';
import { ReactComponent as IconHide } from 'assets/icons/icon-eye-hide.svg';
import { ReactComponent as IconSearch } from 'assets/icons/search.svg';
import { ReactComponent as IconClear } from 'assets/icons/clear.svg';
import {
  isNumber,
  isFloatNumber,
  isLetter,
  isLetterOrNumbers,
  isHex,
} from 'helpers/RegExp';
import useResponsive from 'components/hooks/useResponsive';
import { colors } from 'theme';

const Styles = ({
  theme,
  $full,
  $size,
  $minHeight,
}) => `
  position: relative;
  display: flex;
  align-itens: stretch;
  border: 1px solid ${theme.colors.grey100};
  min-height ${$minHeight ? `${$minHeight} !important` : ''};
  background-color: ${theme.colors.grey100};
  border-radius: 10px;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  color: ${theme.colors.grey500};
  ${$full ? 'width: 100%;' : ''}

  &.--value {
    color: ${theme.colors.black};
  }

  &.--center input {
    text-align: center;
  }

  &.--readonly {
    user-select: none;
    pointer-events: none;
    color: ${theme.colors.grey400} !important;
  }

  &.--value {
    color: ${theme.colors.black};

    &.--readonly {
      color: ${theme.colors.grey500} !important;
    }
  }

  input {
    background-color: unset;
    height: calc(${$size ? theme.sizes[$size] : theme.sizes.normal} - 2px) !important;
    color: inherit;

    &.w-filter-search {
      padding-left: 47px !important;
    }

    &:disabled {
      color: ${theme.colors.grey400};
    }

    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: currentColor !important;
      transition: color 0.3s ease-in-out;
    }

    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: currentColor !important;
      opacity: 1;
      transition: color 0.3s ease-in-out;
    }

    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: currentColor !important;
      opacity: 1;
      transition: color 0.3s ease-in-out;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: currentColor !important;
      transition: color 0.3s ease-in-out;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: currentColor !important;
      transition: color 0.3s ease-in-out;
    }

    ::placeholder {
      /* Most modern browsers support this now. */
      color: currentColor;
    }
  }

  &.--mob input {
    height: calc(${theme.sizes.normal} - 2px) !important;
    font-size: 16px;
  }

  &:hover:not(.--error) {
    border-color: ${theme.colors.grey300};
  }

  &.--focused:not(.--error) {
    color: ${theme.colors.black};
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.black};

    .form-field-icon > svg {
      fill: ${theme.colors.black};
    }
  }

  &.--error.--focused {
    color: ${theme.colors.black} !important;
    background-color: ${theme.colors.white} !important;
    border-color: ${theme.colors.black} !important;

    .form-field-icon > * {
      color: ${theme.colors.black} !important;

      svg {
        fill: ${theme.colors.black} !important;
      }
    }

    .form-field-icon > svg {
      fill: ${theme.colors.black} !important;
    }
  }

  &.--error {
    color: ${theme.colors.red} !important;
    background-color: ${theme.colors.white} !important;
    border-color: ${theme.colors.red} !important;

    .form-field-icon > * {
      color: ${theme.colors.red} !important;
    }

    .form-field-icon > svg {
      fill: ${theme.colors.red} !important;
    }
  }

  label {
    position: relative;
    display: flex;
    align-items: stretch;
    flex: 1;
  }

  &.--icon {
    position: relative;
  }

  &.--icon-left input {
    padding-left: 44px;
  }

  .form-field-icon {
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 0 16px;
    color: ${theme.colors.grey500};

    &.--pointer {
      cursor: pointer;
    }

    &.--pointer:hover svg {
      fill: ${theme.colors.black};
    }

    svg {
      transition fill 0.3s ease-in-out;
      user-event: none;
    }

    &.--left {
      left: 0;
      right: unset;
    }

    &.--search {
      padding-right: 13px;
    }

    &.--clear {
      padding-right: 7px;

      &:hover svg {
        fill: ${theme.colors.black} !important;
      }

      svg {
        fill: ${theme.colors.grey500} !important;
      }
    }

    svg {
      &.action {
        cursor: pointer;
        transition fill 0.3s ease-in-out;

        &:hover {
          fill: ${theme.colors.black} ;
        }
      }
    }
  }

  &.--focused .form-field-icon {
    color: ${theme.colors.black}
  }
`;

const FormFieldContainer = s.div`
  ${(props) => Styles(props)}
`;

const FormField = ({
  title,
  type = 'text',
  placeholder = '',
  icon: Icon,
  iconWidth = 12,
  iconHeight = 24,
  defaultStyle = 'simple',
  errors,
  touched,
  name,
  value = '',
  size = '',
  content = null,
  onBlur = null,
  onFocus = null,
  customComponent = null,
  fieldError = null,
  fieldTouched = null,
  onChange = () => {},
  onClick = null,
  numbers = false,
  floatNumbers = false,
  letters = false,
  readOnly = false,
  max = null,
  min = null,
  minLength = null,
  hex = null,
  maxLength = null,
  before = null,
  full,
  onSubmit = null,
  onDebounceChange = null,
  onIconLeftClick = null,
  onIconRightClick = null,
  iconLeft: IconLeft,
  isCenter,
  // autoFocus = false, //
  tag,
  onClear = null,
  errorPosAbsolute = false,
  noMessage,
  preventSubmit = false,
  color,
  minHeight = '',
  ...attrs
}) => {
  const { isLaptopOrMobile } = useResponsive();
  const fieldRef = useRef(null);
  const rightElement = useRef(null);
  const [
    fieldOffset,
    setOffset,
  ] = useState(null);
  const [
    typeState,
    setTypeState,
  ] = useState(type);
  const [
    focused,
    setFocused,
  ] = useState(false);

  const error = fieldError || (errors && errors[name]);
  const touch = fieldTouched || (touched && touched[name]);
  const isError = !!(error && touch);

  const FieldComponent = customComponent || Input;

  function getCharCode(e) {
    return e.which ? e.which : e.keyCode;
  }

  function isEnterCharCode(charCode) {
    return charCode === 13;
  }

  const handleKeyPress = (e) => {
    const charCode = getCharCode(e);

    if (preventSubmit && isEnterCharCode(charCode) && onSubmit) {
      e.preventDefault();
      onSubmit(e);
    }

    onChange(e);
  };

  useEffect(() => {
    if (rightElement && rightElement.current) {
      const { width } = rightElement.current.getBoundingClientRect();

      setOffset(width);
    }
  }, [ rightElement ]);

  // useEffect(() => {
  //   if (autoFocus && window.innerWidth > 992 && fieldRef && fieldRef.current) {
  //     fieldRef.current.focus()
  //   }
  // }, [ autoFocus, fieldRef ])

  const handleChange = (e) => {
    const { value } = e.target;

    if (value.length) {
      if ((!numbers && !floatNumbers && letters && !isLetter(value))
      || (numbers && !letters && !floatNumbers && !isNumber(value))
      || (floatNumbers && !letters && !numbers && !isFloatNumber(value))
      || (numbers && letters && !isLetterOrNumbers(value))
      || (hex && !isHex(value))
      || (maxLength && value.length > maxLength)
      || (minLength && value.length < minLength)
      ) {
        e.preventDefault();

        return false;
      }

      if (numbers || floatNumbers) {
        if (max && value > max) {
          e.target.value = max;
        }
        if (min && value < min) {
          e.target.value = min;
        }
      }
    }

    onChange(e);

    return e;
  };

  const handleClearField = () => {
    if (fieldRef && fieldRef.current) {
      fieldRef.current.value = '';

      if (onClear) {
        onClear();
      }
    }
  };

  return (
    <div style={{
      position: 'relative',
      width: full ? '100%' : null,
    }}
    >
      <FormFieldContainer
        className={cn('form-field-wrap', {
          '--icon': Icon,
          '--icon-left': IconLeft,
          '--error': isError,
          '--center': isCenter,
          '--focused': focused,
          '--readonly': readOnly,
          '--textarea': tag === 'textarea',
          '--value': value,
          '--mob': isLaptopOrMobile,
        })}
        $full={full}
        $size={size}
      >
        <label>
          <StyleSheetManager shouldForwardProp={isValidProp}>
            <FieldComponent
              {...attrs}
              ref={fieldRef}
              required
              defaultStyle={defaultStyle}
              minHeight={minHeight}
              name={name}
              placeholder={placeholder}
              readOnly={readOnly}
              size={size}
              style={{
                paddingRight: fieldOffset && !before ? `${fieldOffset}px` : null,
                paddingLeft: fieldOffset && (before || type === 'search') ? `${fieldOffset}px` : null,
              }}
              tag={tag}
              type={typeState}
              value={value}
              onBlur={(e) => {
                setFocused(false);

                if (onBlur) {
                  onBlur(e);
                }
              }}
              onChange={onDebounceChange || handleChange}
              onClick={onClick}
              onFocus={(e) => {
                setFocused(true);

                if (onFocus) {
                  onFocus(e);
                }
              }}
              onKeyPress={handleKeyPress}
            />
          </StyleSheetManager>
          {IconLeft && (
            <div
              className={cn('form-field-icon --left --iconLeft', { '--pointer': Boolean(onIconLeftClick) })}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (onIconLeftClick) {
                  onIconLeftClick(value);
                }
              }}
            >
              <IconLeft
                fill={isError ? colors.red : colors.grey500}
                height={iconHeight}
                width={iconWidth}
              />
            </div>
          )}
          {(content || Icon || type === 'password' || type === 'search') ? (
            <div
              ref={rightElement}
              className={cn('form-field-icon', {
                '--right': !before,
                '--search --left': before || type === 'search',
                '--pointer': Boolean(onIconRightClick),
              })}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (type === 'password') {
                  setTypeState(typeState === 'password' ? 'text' : 'password');
                }

                if (onIconRightClick) {
                  onIconRightClick(value);
                }
              }}
            >
              {content && content(fieldRef)}
              {Icon && (
                <Icon
                  fill={isError ? colors.red : colors.grey500}
                  height={iconHeight}
                  width={iconWidth}
                />
              )}

              {type === 'search' && (
                <IconSearch
                  className="action"
                  fill={isError ? colors.red : colors.grey500}
                  height={18}
                  width={18}
                />
              )}
              {type === 'password' && typeState === 'password' ? (
                <IconShow
                  className="action"
                  fill={isError ? colors.red : colors.grey500}
                  height={16}
                  width={23}
                />
              ) : ''}
              {type === 'password' && typeState === 'text' ? (
                <IconHide
                  className="action"
                  fill={isError ? colors.red : colors.grey500}
                  height={16}
                  width={23}
                />
              ) : ''}
            </div>
          ) : ''}
          {onClear && value ? (
            <div
              className="form-field-icon --right --clear"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (handleClearField) {
                  handleClearField();
                }
              }}
            >
              <ButtonRound
                defaultStyle="settings"
                renderIcon={() => (
                  <IconClear
                    height={16}
                    width={16}
                  />
                )}
                size="reset"
              />
            </div>
          ) : ''}

        </label>
      </FormFieldContainer>
      {isError && !noMessage && (
        <FormError
          absolute={errorPosAbsolute}
          isFocused={focused}
        >
          {error}
        </FormError>
      )}
    </div>
  );
};

FormField.propTypes = ({
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
});

export default FormField;
