import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Formik,
  Field,
} from 'formik';
import { SchemaPhone } from 'constants/ValidationSchemas';

// components
import { Button } from 'components/base';
import { Box } from 'layout';
import { Title } from 'components/typo';
import {
  Form,
  FormField,
  FormGroup,
  FormFieldMasked,
} from 'components/form';
import useResponsive from 'components/hooks/useResponsive';

// actions
import {
  getCode,
  setPhone,
} from 'redux/actions/AuthActions';
import { setModal } from 'redux/actions/SystemActions';

// constants

// helpers
import { getNumbers } from 'helpers/RegExp';

// images
import { ReactComponent as IconDefault } from 'assets/icons/multy/key-default.svg';
import { ReactComponent as IconSuccess } from 'assets/icons/multy/key-success.svg';

const ForgetPassword = () => {
  const { isLaptopOrMobile } = useResponsive();
  const dispatch = useDispatch();

  const handleSignIn = (phone, setSubmitting) => {
    dispatch(getCode(phone))
      .then(() => {
        setSubmitting(false);
        dispatch(setModal({
          name: 'phone-confirm',
          back: true,
          data: { password: true },
        }));
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{ phone: '' }}
      validationSchema={SchemaPhone}
      onSubmit={(values, { setSubmitting }) => {
        const phone = getNumbers(values.phone);

        dispatch(setPhone(phone));
        handleSignIn(phone, setSubmitting);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          isSubmitting,
        } = props;

        const attrs = {
          onChange: handleChange,
          onBlur: handleBlur,
          errors,
          touched,
          size: 'big',
        };

        const formIsValid = isValid && dirty;

        const Icon = formIsValid ? IconSuccess : IconDefault;

        return (
          <Form
            noValidate
            onSubmit={handleSubmit}
          >
            <Box
              flex
              className={isLaptopOrMobile ? 'mb-3' : 'mb-30'}
              jc="center"
            >
              <Icon
                height={52.5}
                width={55}
              />
            </Box>
            <Box
              flex
              className={isLaptopOrMobile ? 'mb-20' : 'mb-5'}
              jc="center"
            >
              <Title tag="h2">Забыли пароль?</Title>
            </Box>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    alwaysShowMask={false}
                    customComponent={FormFieldMasked}
                    mask="+9 999 999 99 99"
                    name="phone"
                    placeholder="Телефон"
                    tag="mask"
                    type="tel"
                    value={getNumbers(values.phone)}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Button
                full
                defaultStyle="acsent"
                isDisabled={isSubmitting || !formIsValid}
                isFetching={isSubmitting}
                size="big"
                title="Получить код по SMS"
                type="submit"
              />
            </FormGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ForgetPassword;
