import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// components
import smoothscroll from 'smoothscroll-polyfill';
import * as serviceWorker from './serviceWorker';
import App from 'layout/App';
import ErrorBoundary from 'layout/ErrorBoundary';
import store from 'redux/store/configureStore';
import history from 'history/configureHistory';
import AppRouter from 'components/AppRouter';
import { theme } from 'theme';

// actions
import { getUserSession } from 'redux/actions/UserActions';

// new styles
import 'assets/scss/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

// kick off the polyfill!
smoothscroll.polyfill();

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [ new Integrations.BrowserTracing() ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
  });
}

getUserSession((user) => {
  root.render(
    <Provider store={store}>
      <AppRouter history={history}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <App user={user} />
          </ErrorBoundary>
        </ThemeProvider>
      </AppRouter>
    </Provider>,
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
