import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Formik,
  Field,
} from 'formik';
import * as PathsTypes from 'constants/PathsTypes';
import { SchemaChangePassword } from 'constants/ValidationSchemas';

// components
import { Button } from 'components/base';
import { Box } from 'layout';
import { Title } from 'components/typo';
import {
  Form,
  FormField,
  FormGroup,
} from 'components/form';

// redux
import { fetchChangePassword } from 'redux/actions/AuthActions';
import { setModal } from 'redux/actions/SystemActions';

// constants

// images
import { ReactComponent as Icon } from 'assets/icons/multy/key-new.svg';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (values, { setSubmitting }) => {
    const data = {
      password: values.password,
      password_2: values.passwordConfirm,
    };

    dispatch(fetchChangePassword(data))
      .then(() => {
        setSubmitting(false);
        dispatch(setModal({ close: true }));
        navigate(PathsTypes.INTERFACE);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirm: '',
      }}
      validationSchema={SchemaChangePassword}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          isSubmitting,
        } = props;

        const attrs = {
          onChange: handleChange,
          onBlur: handleBlur,
          errors,
          touched,
          size: 'big',
        };

        const formIsValid = isValid && dirty;

        return (
          <Form
            noValidate
            onSubmit={handleSubmit}
          >
            <Box
              flex
              className="mb-6"
              jc="center"
            >
              <Icon
                height={52.5}
                width={55}
              />
            </Box>
            <Box
              flex
              className="mb-5"
              jc="center"
            >
              <Title tag="h2">Новый пароль</Title>
            </Box>
            <FormGroup>
              <Field name="password">
                {() => (
                  <FormField
                    {...attrs}
                    autoFocus
                    name="password"
                    placeholder="Новый пароль"
                    autoComplete="new-password"
                    type="password"
                    value={values.password}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    name="passwordConfirm"
                    autoComplete="off"
                    placeholder="Подтвердите пароль"
                    type="password"
                    value={values.passwordConfirm}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Button
                full
                defaultStyle="acsent"
                isDisabled={isSubmitting || !formIsValid}
                isFetching={isSubmitting}
                size="big"
                title="Сохранить"
                type="submit"
              />
            </FormGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePassword;
