import React from 'react';
import s from 'styled-components';
import cn from 'classnames';
import { Link } from 'components/typo';
import * as PathsTypes from 'constants/PathsTypes';

// images
import imgLogo from 'assets/images/logo.png';
import imgLogoIcon from 'assets/images/logo-icon.png';

// constants

const styles = ({
  theme,
  $size,
  $isNoMobile,
  $defaultStyle = 'light',
  $animated = false,
}) => {
  const componentTheme = theme.components.logo;
  const sizes = componentTheme.sizes[$size] || componentTheme.sizes.normal;
  const mobSizes = componentTheme.sizes.normal;
  const styles = componentTheme.styles[$defaultStyle] || componentTheme.styles.light;

  return `
    display: inline-flex;
    align-items: center;

    svg {
      fill: ${styles.fill};
      transition: 0.3s fill ease-in-out;
    }

    img, svg {
      width: ${sizes.width};
      height: ${sizes.height};

      @media (max-width: 768px) {
        width: ${$isNoMobile ? sizes.width : mobSizes.width};
        height: ${$isNoMobile ? sizes.height : mobSizes.height};
      }
    }

    span {
      color: ${styles.color};
      transition: 0.3s color ease-in-out;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    svg + span {
      margin-left: ${$size === 'small' ? '12px' : '15px'};
    }

    &:hover {
      span {
        color: ${styles.colorHover || styles.color};
      }

      svg {
        fill: ${styles.fillHover || styles.fill};
      }
    }

    ${$animated
    ? `
        position: relative;
        animation: scale 1.5s linear infinite;
      `
    : ''}
    `;
};

const LogoContainer = s.div`
  ${(props) => styles(props)}
`;

const Logo = ({
  nopointers = false,
  className = '',
  size = 'normal',
  isNoMobile = false,
  defaultStyle,
  animated,
}) => {
  const logo = (
    <LogoContainer
      $isNoMobile={isNoMobile}
      $size={size}
      $animated={animated}
      $defaultStyle={defaultStyle}
      className={cn('w-logo', className)}
    >
      <img
        alt="Matur"
        src={size === 'normal' ? imgLogo : imgLogoIcon}
      />
    </LogoContainer>
  );

  const handleClick = (e) => {
    if (window.location.pathname === '/') {
      e.preventDefault();
      window.location.href = '/';
    }
  };

  return nopointers
    ? logo
    : (
      <Link
        noTransition
        display="inline-flex"
        to={PathsTypes.HOME}
        onClick={handleClick}
      >
        {logo}
      </Link>
    );
};

export default Logo;
