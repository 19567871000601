import React from 'react';
import { ButtonRound } from 'components/base';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';

const CardBtnTrash = ({
  isHovered,
  ...props
}) => (
  <ButtonRound
    {...props}
    defaultStyle="settings"
    fill={isHovered ? 'grey500' : null}
    fillHover="black"
    size="settings"
  >
    <IconTrash
      height={23.5}
      width={23}
    />
  </ButtonRound>
);

export default CardBtnTrash;
