import React from 'react';
import s from 'styled-components';

const SpinnerBaseContainer = s.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const VideoPreview = ({ file }) => (
  <SpinnerBaseContainer className="video-preview">
    <track />
    <source
      src={file.preview}
      type={file.type}
    />
  </SpinnerBaseContainer>
);

export default VideoPreview;
