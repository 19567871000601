import React from 'react';
import cn from 'classnames';
import s from 'styled-components';
import { Text } from 'components/typo';

const CardTextPopupContainer = s.div`
  position: relative;
  display: inline-block;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;

  &.--hovered,
  &:hover {
    color: ${({ theme }) => theme.colors.black700};
  }

  &.--hovered .popup,
  &:hover .popup {
    opacity: 1;
    pointer-events: all;
  }

  .popup {
    position: absolute;
    top: 50%;
    left: 100%;
    display: flex;
    margin-left: 10px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 10px 10px 30px rgba(113, 128, 150, 0.16);
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
  }
`;

const CardContentText = ({
  title = '',
  description = '',
  isHovered = false,
  children,
}) => (
  <CardTextPopupContainer className={isHovered ? '--hovered' : ''}>
    <Text
      noPointers
      transitionOn
      className={cn({ 'mb-1': description })}
      color="current"
    >
      {title}
    </Text>

    <div className="popup">
      {children}
    </div>
  </CardTextPopupContainer>
);

export default CardContentText;
