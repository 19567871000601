export const phoneReg = /^((8|\+7|\d)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d- ]{7,10}$/;
export const phoneRegExp = /^\+\d{1,3}\s?\(\d{3}\)\s?\d{3}(-\d{2}){2}$/;
export const phoneOrEmailReg = /^([_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$/;
export const emailRegExp = /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/gi;
export const birthdayRegExp = /^([012]\d|30|31)\.(0\d|10|11|12)\.\d{2}$/;
export const passportCodeRegExp = /^\d{3}-\d{3}$/;
export const urlRegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
export const urlRegExpNoProtocol = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
export const timeRegExpDinamic = /^\d{0,2}?:?\d{0,2}$/u;
export const timeRegExpStatic = /^\d{2}:\d{2}$/;
export const colorHEXRegExp = /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

export const getNumbers = (maskedPhone) => maskedPhone.replace(/\D+/g, '');

export const getPhone = (maskedPhone) => maskedPhone.replace(/ /g, '');

export const isNumber = (value) => /^[0-9]+$/.test(value);

export const isFloatNumber = (value) => /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(value);

export const isLetter = (value) => /\w+/.test(value);

export const isLetterOrNumbers = (value) => /[0-9A-Za-z]+/.test(value);

export const isHex = (value) => value.match(/^[A-Fa-f0-9]+$/);
