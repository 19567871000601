import React from 'react';
import cn from 'classnames';
import s from 'styled-components';
import { ReactComponent as IconQuestion } from 'assets/icons/question-circle.svg';
import DropdownMenu from 'components/dropdown/DropdownMenu';
import { Text } from 'components/typo';

const TooltipContainer = s.span`
position: relative;
cursor: pointer;

svg {
  transition: fill 0.3s ease-in-out;
  fill: ${({
    theme,
    $color,
  }) => theme.colors[$color]};
}

&:hover svg {
  fill: ${({ theme }) => theme.colors.black};
}

&:hover .w-tooltip-content {
  visibility: visible;
  pointer-events: initial;
}

.w-tooltip-content {
  position: absolute;
  z-index: 100000;
  min-width: 258px;
  max-width: 258px;
  padding: 16px;
  border-radius: 10px;
  visibility: hidden;
  pointer-events: none;
}
`;

const TooltipBase = ({
  className,
  position = 'top-right',
  color = 'grey500',
  text = 'Подсказка',
}) => (
  <TooltipContainer
    className={cn('w-tooltip', className)}
    $color={color}
  >
    <DropdownMenu
      className={cn('w-tooltip-content', { [`--${position}`]: position })}
      position={position}
    >
      <Text size="small">
        {text}
      </Text>
    </DropdownMenu>
    <div className="w-tooltip-icon">
      <IconQuestion
        height={22}
        width={22}
      />
    </div>
  </TooltipContainer>
);

export default TooltipBase;
