import {
  FiltersActionTypes,
  FiltersNames,
  FiltersNamesTypes,
  LIMIT_INIT,
} from 'redux/types/FiltersTypes';
import { UserActionTypes } from 'redux/types/UserTypes';

export const FILTERS_INIT_STATE = {
  [FiltersNamesTypes.CATEGORIES]: null,
  [FiltersNamesTypes.SEARCH]: null,
  [FiltersNamesTypes.LIMIT]: LIMIT_INIT,
  [FiltersNamesTypes.OFFSET]: null,
};

export const FILTERS_ORDERS_INIT_STATE = {
  [FiltersNamesTypes.STATUSES]: null,
  [FiltersNamesTypes.SEARCH]: null,
  [FiltersNamesTypes.LIMIT]: null,
  [FiltersNamesTypes.OFFSET]: null,
};

const filtersInitState = {
  [FiltersNames.filters]: { ...FILTERS_INIT_STATE },
  [FiltersNames.filtersProducts]: { ...FILTERS_INIT_STATE },
  [FiltersNames.filtersAdditions]: { ...FILTERS_INIT_STATE },
  [FiltersNames.filtersProductsFormats]: { ...FILTERS_INIT_STATE },
  [FiltersNames.filtersOrders]: { ...FILTERS_ORDERS_INIT_STATE },
};

const filtersReducer = (state = filtersInitState, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...filtersInitState };

  case FiltersActionTypes.SET_FILTER: {
    const {
      name,
      value,
      filtersName,
    } = action.payload;

    return {
      ...state,
      [filtersName]: {
        ...state[filtersName],
        [name]: value,
      },
    };
  }
  case FiltersActionTypes.SET_FILTERS: {
    const {
      filtersName,
      filters,
    } = action.payload;

    return {
      ...state,
      [filtersName]: {
        ...state[filtersName],
        ...filters,
      },
    };
  }

  case FiltersActionTypes.RESET_ALL_FILTERS: {
    let initState;

    switch (action.payload) {
    case FiltersNames.filtersOrders:
      initState = { ...FILTERS_ORDERS_INIT_STATE };
      break;

    case FiltersNames.filters:
    default:
      initState = { ...FILTERS_INIT_STATE };
      break;
    }

    return {
      ...state,
      [action.payload]: initState,
    };
  }

  default:
    return state;
  }
};

export default filtersReducer;
