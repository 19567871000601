export const LocationsActionTypes = {
  SET_FETCHING_LOCATIONS: 'SET_FETCHING_LOCATIONS',
  FETCH_LOCATIONS_ERROR: 'FETCH_LOCATIONS_ERROR',

  CLEAR_LOCATIONS_HOME: 'CLEAR_LOCATIONS_HOME',
  SET_LOCATIONS_HOME_IS_EMPTY: 'SET_LOCATIONS_HOME_IS_EMPTY',
  FETCH_LOCATIONS_HOME_SUCCESS: 'FETCH_LOCATIONS_HOME_SUCCESS',

  SET_FETCHING_GET_LOCATION_BY_ID: 'SET_FETCHING_GET_LOCATION_BY_ID',
  FETCH_GET_LOCATION_BY_ID_SUCCESS: 'FETCH_GET_LOCATION_BY_ID_SUCCESS',

  SET_FETCHING_LOCATION_SUBMITTING: 'SET_FETCHING_LOCATION_SUBMITTING',
  SET_FETCHING_LOCATION_TOGGLE_ACTIVE: 'SET_FETCHING_LOCATION_TOGGLE_ACTIVE',

  FETCH_LOCATION_CREATE_SUCCESS: 'FETCH_LOCATION_CREATE_SUCCESS',
  FETCH_LOCATION_UPDATE_SUCCESS: 'FETCH_LOCATION_UPDATE_SUCCESS',
  FETCH_LOCATION_DELETE_SUCCESS: 'FETCH_LOCATION_DELETE_SUCCESS',
  FETCH_LOCATION_TOGGLE_ACTIVE_SUCCESS: 'FETCH_LOCATION_TOGGLE_ACTIVE_SUCCESS',
};
