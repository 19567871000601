/* eslint-disable indent */
/* eslint-disable import/no-cycle */
import React from 'react';
import s from 'styled-components';
import cn from 'classnames';
import { Text } from 'components/typo';
import Switcher from 'components/Switcher';
import WithHover from 'components/hooks/WithHover';

const FormSwitchCardContainer = s.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 18px;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  border-radius: ${({ theme }) => theme.radius.normal};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'initial' : 'pointer')};

  &.--hor {
    flex-direction: row;
    justify-content: space-between;
    padding: 28px 21px;

    .w-icon {
      margin-right: 14px;
      margin-bottom: 0;
    }

    .w-title {
      margin-right: auto;
      margin-bottom: 0;
    }

    .w-description {
      margin-right: auto;
      margin-bottom: 0;
    }
  }

  .w-icon {
    margin-bottom: 8px;
    transition: fill 0.3s ease-in-out;
    fill:
      ${(
        {
          theme,
          $value,
          $isDisabled,
        },
      ) => theme.colors[$value && !$isDisabled ? 'grey700' : 'grey500']};
  }

  &:hover {
    .w-icon {
      fill: ${({
theme,
$isDisabled,
}) => ($isDisabled ? '' : theme.colors.grey700)};
    }

    .w-title {
      color: ${({
theme,
$isDisabled,
}) => ($isDisabled ? '' : theme.colors.grey700)};
    }
  }

  .w-title {
    margin-bottom: 6px;
    color:
      ${(
        {
          theme,
          $value,
          $isDisabled,
        },
      ) => theme.colors[$value && !$isDisabled ? 'grey700' : 'grey500']};
  }

  .w-description {
    margin-bottom: 18px;
  }
`;

const FormSwitchCard = ({
  title,
  description,
  icon: Icon = null,
  name,
  value,
  handleChange = null,
  isDisabled,
  isHovered,
  isFocused,
  isHorizontal = false,
  ...cardProps
}) => {
  const toggleEventHandler = () => {
    if (handleChange) {
      handleChange();
    }
  };

  return (
    <FormSwitchCardContainer
      className={cn('w-switch-card', { '--hor': isHorizontal })}
      $isDisabled={isDisabled}
      $value={value}
      $isFocused={isFocused}
      onClick={toggleEventHandler}
      {...cardProps}
    >
      <Icon
        className="w-icon"
        height={24}
        width={24}
      />
      <Text
        transitionOn
        className="w-title"
        color="current"
        fw="sb"
      >
        {title}
      </Text>
      <Text
        className="w-description"
        color="grey500"
        size="small"
      >
        {description}
      </Text>
      <Switcher
        isDisabled={isDisabled}
        isHovered={isHovered}
        name={name}
        value={value}
        onChange={handleChange}
      />
    </FormSwitchCardContainer>
  );
};

export default WithHover(FormSwitchCard);
