import React from 'react';
import { Link } from 'components/typo';

const EmailLink = ({
  email = '',
  color = 'white',
  hover = 'whitet70',
}) => (
  <Link
    browser
    color={color}
    hover={hover}
    href={`mailto:${email}`}
    target="_blank"
  >
    {email}
  </Link>
);

export default EmailLink;
