/* eslint-disable react/no-danger */
import React, { useCallback } from 'react';
import s from 'styled-components';
import cn from 'classnames';
import {
  Box,
  Grid,
} from 'layout';
import Logo from 'components/Logo';
import {
  Title,
  Text,
} from 'components/typo';
import {
  EmailLink,
  TelegramLink,
  // WhatsupLink,
} from 'components/socials';

const styles = ({
  theme,
  $img,
}) => `
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 604px;
  height: 100%;
  background-color: ${theme.colors.black};
  background-image: ${$img ? `url(${$img})` : null};
  background-size: cover;
  background-position: center;
  padding: 116px 120px 40px 94px;
  color: ${theme.colors.white};
  border-radius: 0 60px 60px 0;

  @media (max-width: 1140px) {
    width: 400px;
    padding: 100px 50px
  }
`;

const ModalBannerContainer = s.div`
  ${(props) => styles(props)}
`;

const ModalBanner = ({
  className,
  children,
  title = '',
  description = '',
  descriptionHtml = null,
  author = 'Команда Приложения',
  actions = null,
  center,
  img,
  ...otherProps
}) => {
  const classes = cn('w-modal-banner', className);

  const renderDefault = useCallback(
    () => (
      <>
        <div className={center ? 'my-a' : ''}>
          <Box className="mb-5">
            <Logo
              nopointers
              notitle
              defaultStyle="white"
            />
          </Box>
          {title && (
            <Box className="mb-4">
              <Title tag="h4">{title}</Title>
            </Box>
          )}
          {description || descriptionHtml ? (
            <Box className="mb-4">
              <Text
                color="whitet70"
                tag="div"
              >
                {description}
                {descriptionHtml}
              </Text>
            </Box>
          ) : (
            ''
          )}
          {actions && <Box className="mb-4">{actions}</Box>}
          {author && (
            <Box>
              <Text color="whitet70">{author}</Text>
            </Box>
          )}
        </div>
        <Grid
          className="mt-a"
          gutter={30}
        >
          <div>
            <EmailLink />
          </div>
          <div>
            <TelegramLink />
          </div>
          {/* <div>
            <WhatsupLink />
          </div> */}
        </Grid>
      </>
    ),
    [
      title,
      description,
      actions,
      center,
      author,
      descriptionHtml,
    ],
  );

  return (
    <ModalBannerContainer
      className={classes}
      $img={img}
      {...otherProps}
    >
      {children || renderDefault()}
    </ModalBannerContainer>
  );
};

export default ModalBanner;
