export const AdditionsActionTypes = {
  SET_FETCHING_ADDITIONS: 'SET_FETCHING_ADDITIONS',
  FETCH_ADDITIONS_ERROR: 'FETCH_ADDITIONS_ERROR',

  CLEAR_ADDITIONS_HOME: 'CLEAR_ADDITIONS_HOME',
  SET_ADDITIONS_HOME_IS_EMPTY: 'SET_ADDITIONS_HOME_IS_EMPTY',
  FETCH_ADDITIONS_HOME_SUCCESS: 'FETCH_ADDITIONS_HOME_SUCCESS',

  CLEAR_ADDITIONS_MODAL: 'CLEAR_ADDITIONS_MODAL',
  SET_ADDITIONS_MODAL_IS_EMPTY: 'SET_ADDITIONS_MODAL_IS_EMPTY',
  FETCH_ADDITIONS_MODAL_SUCCESS: 'FETCH_ADDITIONS_MODAL_SUCCESS',

  SET_FETCHING_GET_ADDITION_BY_ID: 'SET_FETCHING_GET_ADDITION_BY_ID',
  FETCH_GET_ADDITION_BY_ID_SUCCESS: 'FETCH_GET_ADDITION_BY_ID_SUCCESS',

  SET_FETCHING_ADDITION_SUBMITTING: 'SET_FETCHING_ADDITION_SUBMITTING',
  SET_FETCHING_ADDITION_TOGGLE_ACTIVE: 'SET_FETCHING_ADDITION_TOGGLE_ACTIVE',

  FETCH_ADDITION_CREATE_SUCCESS: 'FETCH_ADDITION_CREATE_SUCCESS',
  FETCH_ADDITION_UPDATE_SUCCESS: 'FETCH_ADDITION_UPDATE_SUCCESS',
  FETCH_ADDITION_DELETE_SUCCESS: 'FETCH_ADDITION_DELETE_SUCCESS',
  FETCH_ADDITION_TOGGLE_ACTIVE_SUCCESS: 'FETCH_ADDITION_TOGGLE_ACTIVE_SUCCESS',
};
