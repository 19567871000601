// import { toast } from 'react-toastify'

// types
// import { UserActionTypes } from 'redux/types/UserTypes'

// services
// import ApiService from 'services/ApiService'
import { fetchLogin } from './AuthActions';
import StorageService from 'services/StorageService';
import store from 'redux/store/configureStore';

// constants
// import * as PathsTypes from 'constants/PathsTypes'
// import { messageTypes } from 'constants/NotifierTypes'
// import { setNotification } from './SystemActions'

export const getUserSession = (callback) => {
  const token = StorageService.getToken();

  if (token) {
    store.dispatch(fetchLogin(token, false))
      .then((user) => callback(user));
  }
  else {
    callback(null);
  }
};
