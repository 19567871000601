import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import s from 'styled-components';
import { theme } from 'theme';

const Styles = ({
  theme,
  $color,
  $cursor,
  $noPointers,
  $hover,
  $display,
}) => `
  display: ${$display};
  text-decoration: unset;
  color: ${theme.colors[$color] || $color || theme.colors.black};
  cursor: ${$cursor || 'pointer'};
  pointer-events: ${$noPointers ? 'none' : ''};
  transition: color 0.3s ease-in-out;

  &.--no-transition {
    transition: unset;
  }

  svg {
    fill: currentColor;
  }

  &:hover,
  &.--active {
    color: ${theme.colors[$hover]};
  }
`;

const LinkContainerBrowser = s.a`
  ${(props) => Styles(props)}
`;

const LinkContainerRouter = s(Link)`
  ${(props) => Styles(props)}
`;

const LinkRoute = ({
  display = 'inline-block',
  children,
  align,
  size = 'normal',
  hover = 'grey700',
  active = false,
  browser,
  color = 'black',
  fw,
  className,
  tt,
  title,
  cursor = 'pointer',
  noTransition = false,
  noPointers,
  ...otherProps
}) => {
  const classes = cn(
    'w-link',
    {
      '--active': active,
      '--no-transition': noTransition,
      [`text-${size}`]: size,
      [`text-fw${fw}`]: fw,
      [`text-t${tt}`]: tt,
    },
    className,
  );

  const props = {
    className: classes,
    $color: color,
    $cursor: cursor,
    $hover: hover,
    $display: display,
    $noPointers: noPointers,
    ...otherProps,
  };

  if (browser) {
    return <LinkContainerBrowser {...props}>{children || title}</LinkContainerBrowser>;
  }

  return <LinkContainerRouter {...props}>{children || title}</LinkContainerRouter>;
};

LinkRoute.propTypes = { color: PropTypes.oneOf(Object.keys(theme.colors)) };

export default LinkRoute;
