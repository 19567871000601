export const SUBSCRIPTIONS = [
  {
    id: 1,
    title: 'Старт',
    variant: 'orange',
    label: 'free',
    description: 'Для тех, кто просто хочет посмотреть, как все устроено',
    prices: null,
    pricesLabel: '0',
    actionTitle: 'Выбрать',
    list: [
      'Доступ в web-сервис',
      'Добавление блюд в каталог',
      'Добавление баннеров',
      'Выбор темы приложения',
      'Информация о ресторане',
    ],
    info: 'Публикация приложения в Apple Store и Google Play возможна только на платном тарифе',
  },
  {
    id: 2,
    title: 'Медиум',
    variant: 'green',
    label: 'HIT',
    description: 'Для ресторанов и сервисов доставки еды (до 3 точек в одном городе)',
    prices: [
      '7 900',
      '5 900',
      '4 900',
    ],
    pricesInfo: [
      '',
      '*Стоимость при оплате тарифа сразу за 3 месяца.',
      '*Стоимость при оплате тарифа сразу за 1 год.',
    ],
    pricesLabel: null,
    actionTitle: 'Выбрать',
    listPrev: 'Все, что есть в тарифе «Старт»',
    list: [
      'Генерация иконки приложения',
      'Генерация скринов для store',
      'Добавление типов оплаты',
      'Добавление адресов точек',
      'Создание категорий товаров',
      'Создание push-рассылок ',
      'Отслеживание заказов',
      'Сбор клиентской базы',
      'Сервис поддержки',
      '«Сборка» приложения',
      'Публикация в Apple Store',
      'Публикация в Google Play',
      'Обновление приложения',
    ],
    info: null,
  },
  {
    id: 3,
    variant: 'purple',
    title: 'Бизнес',
    label: 'Premium',
    description: 'Для сетей и крупных брендов, требующих индивидуальный подход',
    prices: null,
    pricesInfo: [
      '',
      '*Стоимость при оплате тарифа сразу за 3 месяца.',
      '*Стоимость при оплате тарифа сразу за 1 год.',
    ],
    pricesLabel: null,
    actionTitle: 'Выбрать',
    listPrev: 'Все, что есть в тарифе «Медиум»',
    list: [
      'Индивидуальная дизайн тема',
      'Дизайн баннеров для каталога',
      'Дизайн иконки приложения',
      'Дизайн скринов для store',
      'Расширенные типы оплаты',
      'Персональный менеджер',
      'Интеграция эквайринга',
      'Интеграция с Apple Pay',
      'Интеграция с Google Pay',
      'Интеграция c внешними CRM',
      'Интеграция с сервисами аналитики ',
      'Разработка доп. функционала',
      'Разработка стратегии продвижения',
      'Таргетированная реклама',
      'Профессиональные фуд-фото',
    ],
    info: null,
  },
];

export const RateTypes = {
  BY_ORDER: 'BY_ORDER',
  BY_PRICE: 'BY_PRICE',
};

export const PeriodTypes = {
  MONTH: 'MONTH',
  YEAR: 'YEAR',
  MONTH_3: 'MONTH_3',
};

export const PeriodTabsModel = [
  {
    id: PeriodTypes.MONTH,
    title: 'Месяц',
    idx: 0,
  },
  {
    id: PeriodTypes.MONTH_3,
    title: 'Квартал',
    idx: 1,
  },
  {
    id: PeriodTypes.YEAR,
    title: 'Год',
    idx: 2,
  },
];
