import React from 'react';
import s from 'styled-components';
import YandexMap from 'components/YandexMap';

const CardMapContainer = s.div`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '282px'};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.grey100};
  border-radius: ${({ theme }) => theme.radius.small};
`;

const CardMap = ({
  children,
  searchValue,
  zoom,
  isMarker,
  onCoordsUpdate,
  ...otherProps
}) => (
  <CardMapContainer
    className="w-card-map"
    {...otherProps}
  >
    <YandexMap
      height="100%"
      searchValue={searchValue}
      settings={{
        zoom,
        isMarker,
      }}
      width="100%"
      onCoordsUpdate={onCoordsUpdate}
    />
  </CardMapContainer>
);

export default CardMap;
