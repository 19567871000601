import React from 'react';
import {
  LICENSE,
  POLITIC,
} from 'constants/PathsTypes';
import Box from 'layout/Box';
import Link from 'components/typo/Link';
import Card from 'components/Card/Card';
import Text from 'components/typo/Text';

const ModalSubscriptionsBanner = ({ isLaptopOrMobile }) => (
  <Card
    bg="grey-blur"
    center={!isLaptopOrMobile}
    p={isLaptopOrMobile ? '20px 60px 20px 20px' : '20px 80px'}
  >
    <Box
      display="block"
      m="0 auto"
      maxWidth="812px"
    >
      <Text
        color="grey500"
        size={isLaptopOrMobile ? 'small' : 'normal'}
      >
        Нажимая кнопку «Выбрать» вы принимаете
        {' '}
        <Link
          browser
          display="inline"
          fs="inherit"
          href={LICENSE}
          lh="inherit"
          size={isLaptopOrMobile ? 'small' : ''}
          target="_blank"
        >
          условия Лицензионного договора с ООО «Приложение»
        </Link>
        {' '}
        и подтверждаете ознакомление с
        {' '}
        <Link
          browser
          display="inline"
          fs="inherit"
          href={POLITIC}
          lh="inherit"
          size={isLaptopOrMobile ? 'small' : ''}
          target="_blank"
        >
          Политикой в отношении обработки и защиты персональных данных
          {' '}
          <span
            aria-label="emoji"
            role="img"
          >
            🎩
          </span>
        </Link>
      </Text>
    </Box>
  </Card>
);

export default ModalSubscriptionsBanner;
