import { SubscriptionsActionTypes } from 'redux/types/SubscriptionsTypes';
import { UserActionTypes } from 'redux/types/UserTypes';

const INITIAL_STATE = {
  name: 'subscriptions',
  list: [],
  isFetching: false,
  isFetchingBuy: false,
};

const subscriptionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE };

  case SubscriptionsActionTypes.SET_FETCHING_SUBSCRIPTIONS:
    return {
      ...state,
      isFetching: action.payload,
    };

  case SubscriptionsActionTypes.FETCH_SUBSCRIPTIONS_SUCCESS:
    return {
      ...state,
      list: action.payload,
    };

  case SubscriptionsActionTypes.SET_FETCHING_USER_BUY_SUBSCRIPTIONS:
    return {
      ...state,
      isFetchingBuy: action.payload,
    };

  default:
    return state;
  }
};

export default subscriptionsReducer;
