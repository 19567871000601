import React, { useContext } from 'react';
import s, { ThemeContext } from 'styled-components';
import cn from 'classnames';
import Spinner from 'react-spinner-material';

const SpinnerBaseContainer = s.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1 !important;

  &:not(.--relative) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &.--relative {
    width: 100%;
  }

  &.--fit {
    width: 100%;
    height: 100%;
  }
`;

const SpinnerBase = ({
  relative = false,
  height = null,
  visible = true,
  className = '',
  fit = false,
  size = 'normal',
  fillColor = '',
}) => {
  const theme = useContext(ThemeContext);
  const {
    sizes,
    color,
  } = theme.components.spinner;
  const currentSizes = sizes[size] || sizes.normal;

  return (
    <SpinnerBaseContainer
      className={cn(
        'ws-spinner',
        {
          '--relative': relative,
          '--fit': fit,
        },
        className,
      )}
      style={{ height: height || 'initial' }}
    >
      <Spinner
        color={fillColor || color}
        radius={currentSizes.radius}
        stroke={currentSizes.stroke}
        visible={visible}
      />
    </SpinnerBaseContainer>
  );
};

export default SpinnerBase;
