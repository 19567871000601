import React from 'react';
import { ButtonRound } from 'components/base';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';

const ButtonDelete = ({
  onClick,
  ...otherProps
}) => (
  <ButtonRound
    {...otherProps}
    defaultStyle="light"
    fill="grey500"
    fillHover="black"
    p="5"
    renderIcon={() => <IconTrash />}
    svgHeight="23.5px"
    svgWidth="23px"
    onClick={onClick}
  />
);

export default ButtonDelete;
