import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import s from 'styled-components';
// import FormikErrorFocus from 'formik-error-focus'

import { Grid } from 'layout';
import {
  Button,
  NavigationGuard,
} from 'components/base';
import { ButtonDelete } from 'components/buttons';
import { setModal } from 'redux/actions/SystemActions';

const FormFooterContainer = s.div`
  position: ${({ $isSticky }) => ($isSticky ? 'sticky' : 'relative')};
  bottom: 0;
  left: 0;
  z-index: ${({ $isSticky }) => ($isSticky ? 99 : '')};
  width: 100%;
  max-width: ${({ theme }) => theme.components.ipage.maxWidth}px;
  margin-top: auto;
  padding: 19px 0;
  background-color: ${({ theme }) => theme.colors.whitet90};

  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: 9999px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    transform: translateX(-50%);
    content: "";
  }
`;

const FormFooter = ({
  isSubmitting,
  isValid,
  onReset,
  onNavigationYes,
  onDelete,
  modalTitle = null,
  isSubmitted = false,
  isDirty = false,
}) => {
  const dispatch = useDispatch();

  const handleDelete = useCallback(() => {
    dispatch(
      setModal({
        name: 'confirm',
        type: 'delete',
        center: true,
        onYes: (closeModal) => {
          closeModal();

          return onDelete();
        },
        title: modalTitle,
      }),
    );
  }, [
    dispatch,
    onDelete,
    modalTitle,
  ]);

  return (
    <>
      <FormFooterContainer
        className="form-footer"
        $isSticky={isValid}
      >
        <Grid>
          <Button
            opacityFull
            defaultStyle={isValid ? 'acsent' : 'grey'}
            isDisabled={!isValid || isSubmitting}
            isFetching={isSubmitting}
            title="Сохранить"
            type="submit"
          />
          {isDirty && (
            <Button
              defaultStyle="whitegrey"
              isDisabled={isSubmitting}
              title="Сбросить"
              type="reset"
              onClick={onReset}
            />
          )}
          {onDelete && (
            <ButtonDelete
              className="ml-a"
              isDisabled={isSubmitting}
              onClick={handleDelete}
            />
          )}
        </Grid>
      </FormFooterContainer>
      {/* <FormikErrorFocus
        offset={0}
        align="center"
        focusDelay={100}
        ease="linear"
        duration={1}
      /> */}
      {isValid
      && isDirty
      && !isSubmitted
      && <NavigationGuard onYes={onNavigationYes} />}
    </>
  );
};

export default FormFooter;
