import React, { Suspense } from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import InterfacePreloader from 'layout/Interface/InterfacePreloader';
import ErrorPage from 'pages/ErrorPage';

const RoutesTransition = (props) => {
  const {
    routes,
    rootPath = '',
  } = props;

  return (
    <Suspense fallback={<InterfacePreloader position="absolute" />}>
      <Routes>
        {routes.map(({
          path,
          component: Component,
          element,
          // ...otherProps
        }) => (
          <Route
            key={path}
            path={`${rootPath}${path}`}
            element={element || <Component />}
            // {...otherProps}
          />
        ))}
        <Route
          path="*"
          element={<ErrorPage title="👽&nbsp;Страница не&nbsp;найдена..." />}
        />
      </Routes>
    </Suspense>
  );
};

export default RoutesTransition;
