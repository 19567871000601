import React from 'react';

// components
import { Box } from 'layout';
import {
  Modal,
  ModalContent,
  ModalActions,
  ModalFooterHelp,
} from 'components/modal';
import ForgetPassword from 'components/ForgetPassword';

const ModalForgetPassword = ({
  prevModal,
  closeModal,
}) => (
  <Modal
    flex
    size="full"
  >
    <ModalActions
      closeModal={closeModal}
      prevModal={prevModal}
    />
    <ModalContent
      center
      column
      display="flex"
      p="withSidebar"
    >
      <Box
        flex
        ai="center"
        className="mb-5"
        direction="column"
        height="100%"
        jc="center"
        self="stretch"
      >
        <ForgetPassword />
      </Box>
      <div className="mt-a mx-a">
        <Box
          jc="center"
          self="center"
        >
          <ModalFooterHelp />
        </Box>
      </div>
    </ModalContent>
  </Modal>
);

export default ModalForgetPassword;
