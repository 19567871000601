import React from 'react';
import s from 'styled-components';
import cn from 'classnames';

const ArticleContainer = s.article`
  p + h1 {
    margin-top: 50px;
  }

  p + h2 {
    margin-top: 45px;
  }

  p + h3 {
    margin-top: 40px;
  }

  p + h4,
  p + h5,
  p + h6,
  p + ul,
  p + ol,
  p + img,
  p + p {
    margin-top: 30px;
  }

  h1 + *,
  h2 + *,
  h3 + *,
  h4 + *,
  h5 + *,
  h6 + * {
    margin-top: 30px;
  }

  * + h1,
  * + h2,
  * + h3,
  * + h4 {
    margin-top: 40px;
  }

  img + * {
    margin-top: 40px;
  }

  ul + *,
  ol + * {
    margin-top: 30px;
  }

  img {
    max-width: 100% !important;
    height: auto !important;
  }

  ol,
  ul {
    li {
      margin-bottom: 20px;
      padding: 0;

      &:last-child {
        margin-bottom: unset;
      }
    }
  }
`;

const Article = ({
  children,
  className,
  ...otherProps
}) => (
  <ArticleContainer
    {...otherProps}
    className={cn('ps-article', className)}
  >
    {children}
  </ArticleContainer>
);

export default Article;
