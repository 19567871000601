import React from 'react';
import { Button } from 'components/base';
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';

const ButtonAdd = ({
  title = 'Добавить',
  iconHide = false,
  defaultStyle = 'transparent',
  ...otherProps
}) => (
  <Button
    add={defaultStyle !== 'transparent'}
    defaultStyle={defaultStyle}
    nopadding={defaultStyle === 'transparent'}
    title={title}
    {...otherProps}
  >

    {!iconHide && (
      <IconPlus
        height={18}
        width={18}
      />
    )}
  </Button>
);

export default ButtonAdd;
