/* eslint-disable camelcase */
import React, {
  useRef,
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Formik,
  Field,
} from 'formik';
import Countdown from 'components/base/Countdown';
import {
  Text,
  Link,
} from 'components/typo';
import {
  Form,
  Input,
  FormGroup,
  FormError,
} from 'components/form';
import { Grid } from 'layout';
import Spinner from 'components/base/Spinner';
import { setModal } from 'redux/actions/SystemActions';
import {
  setPhoneConfirmationForm,
  confirmCode,
  getCode,
} from 'redux/actions/AuthActions';
import StorageService from 'services/StorageService';

const PhoneConfirm = ({
  isCodeError,
  setCodeError,
  // isCodeSuccess,
  setCodeSuccess,
}) => {
  const [
    repeat,
    setRepeat,
  ] = useState(false);
  const codeRef = useRef([]);
  const dispatch = useDispatch();
  const { phone } = useSelector((state) => state.auth);
  const { modal: { data } } = useSelector((state) => state.system);

  useEffect(() => {
    if (codeRef.current && codeRef.current[0]) {
      codeRef.current[0].focus();
    }
    // eslint-disable-next-line
  }, [codeRef.current])

  const handleCodeVerify = (code) => {
    setCodeError(false);
    setCodeSuccess(true);

    dispatch(confirmCode({
      code,
      phone,
    }))
      .then((token) => {
        StorageService.setToken(token);
        if (data && data.password) {
          dispatch(
            setModal({
              name: 'change-password',
              back: true,
            }),
          );
        }
      })
      .catch(() => {
        setCodeSuccess(false);
        setCodeError(true);
      });
  };

  const handleRepeat = () => {
    setCodeError(false);

    if (phone) {
      dispatch(getCode(phone));
      setRepeat(false);
      codeRef.current[0].focus();
    }
    else {
      setCodeError(true);
    }
  };

  const handleTimerEnd = () => {
    setRepeat(true);
  };

  const handleKeyPress = (e, handleSubmit) => {
    if (codeRef.current) {
      const idx = +e.target.name;
      const { value } = e.target;
      const { length } = codeRef.current;

      if (e.keyCode === 37) {
        if (idx === 0) codeRef.current[length - 1].focus();
        else codeRef.current[idx - 1].focus();
      }
      else if (e.keyCode === 39) {
        if (idx === length - 1) codeRef.current[0].focus();
        else codeRef.current[idx + 1].focus();
      }
      else if (e.keyCode === 8 && value.length === 0 && idx > 0) {
        codeRef.current[idx - 1].focus();
      }
      else if (e.keyCode === 13) {
        handleSubmit();
      }
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          code: new Array(6)
            .fill(''),
        }}
        onSubmit={(values, {
          setSubmitting,
          setFieldError,
        }) => {
          if (values.code.some((num) => num.length === 0)) {
            setFieldError('code', true);
            setSubmitting(false);

            return;
          }

          dispatch(setPhoneConfirmationForm(values));
          handleCodeVerify(values.code.join(''));
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleSubmit,
            handleBlur,
            setFieldValue,
            resetForm,
            isFetching,
          } = props;

          const handleCodeChange = (e) => {
            if (isCodeError) {
              setCodeError(false);
            }

            const { target } = e;

            if (target.value.length <= 1) {
              if (/\d+/.test(target.value)) target.value.replace(/\d+/, '');
              setFieldValue(`code[${target.name}]`, target.value);
            }

            if (
              values.code
                .filter((_, i) => i !== +target.name)
                .every((v) => v.length === 1)
            ) {
              handleSubmit();
            }
            else if (target.value.length === 1) {
              const nextField = codeRef.current[+target.name + 1];

              if (nextField) {
                nextField.focus();
              }
            }
          };

          const attrs = {
            onChange: handleCodeChange,
            onBlur: handleBlur,
            errors,
            touched,
          };

          return (
            <>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Grid>
                    {values.code.map((value, idx) => (
                      <Field key={idx}>
                        {() => (
                          <Input
                            ref={(el) => (codeRef.current[idx] = el)}
                            square
                            align="center"
                            max={1}
                            readOnly={isFetching}
                            onKeyDown={(e) => handleKeyPress(e, handleSubmit)}
                            className="confirmation-code"
                            // autoFocus={idx === 0 && window.innerWidth > 768}
                            disabled={isFetching}
                            {...attrs}
                            isError={isCodeError}
                            name={idx}
                            type="text"
                            value={value}
                          />
                        )}
                      </Field>
                    ))}
                  </Grid>
                  {errors.code && <FormError>{errors.code}</FormError>}
                </FormGroup>
              </Form>
              {repeat ? (
                <Link
                  className="mt-6"
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    resetForm();
                    handleRepeat();
                  }}
                >
                  Прислать новый код
                </Link>
              ) : (
                <Text
                  className="mt-6"
                  color="grey500"
                >
                  Запросить новый код через
                  {' '}
                  <Countdown
                    handleEnd={handleTimerEnd}
                    seconds={30}
                    tag="span"
                  />
                </Text>
              )}
              {isFetching ? (
                <Spinner
                  relative
                  height={100}
                  size="medium"
                />
              ) : ''}
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default PhoneConfirm;
