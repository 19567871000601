import {
  SettingsActionTypes,
  screenshotStates,
} from 'redux/types/SettingsTypes';
import { UserActionTypes } from 'redux/types/UserTypes';

const INITIAL_STATE = {
  name: 'settings',
  isSubmitting: false,
  isFetching: false,
  appMenuOptions: [],
  appThemes: [],
  countries: [],
  payments: [],
  screenshots: [],
  isFetchingScreenshots: true,
  devicesTypes: null,
  isFetchingDevices: true,
  isFetchingPublish: false,
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE };

  case SettingsActionTypes.SET_FETCHING_SETTINGS_SUBMITTING:
    return {
      ...state,
      isSubmitting: action.payload,
    };

  case SettingsActionTypes.FETCH_GET_APP_MENU_OPTIONS_SUCCESS:
    return {
      ...state,
      appMenuOptions: action.payload,
    };

  case SettingsActionTypes.FETCH_TOGGLE_APP_MENU_OPTION_SUCCESS:
    return {
      ...state,
      appMenuOptions: [
        ...state.appMenuOptions.map((p) => (p.id === action.payload.id
          ? action.payload
          : p)),
      ],
    };

  case SettingsActionTypes.FETCH_GET_COMPANY_PAYMENTS_SUCCESS:
    return {
      ...state,
      payments: action.payload,
    };

  case SettingsActionTypes.FETCH_TOGGLE_COMPANY_PAYMENT_SUCCESS:
    return {
      ...state,
      payments: [
        ...state.payments.map((p) => (p.id === action.payload.id
          ? action.payload
          : p)),
      ],
    };

  case SettingsActionTypes.SET_FETCHING_GET_APP_THEMES:
  case SettingsActionTypes.SET_FETCHING_GET_COUNTRIES:
  case SettingsActionTypes.SET_FETCHING_SETTINGS_DATA:
  case SettingsActionTypes.SET_FETCHING_GET_COMPANY_PAYMENTS:
  case SettingsActionTypes.SET_FETCHING_DELETE_ALL_SCREENSHOTS_BY_TYPE:
    return {
      ...state,
      isFetching: action.payload,
    };

  case SettingsActionTypes.FETCH_GET_APP_THEMES_SUCCESS:
    return {
      ...state,
      appThemes: action.payload,
    };

  case SettingsActionTypes.FETCH_GET_COUNTRIES_SUCCESS:
    return {
      ...state,
      countries: action.payload,
    };

  case SettingsActionTypes.SET_FETCHING_GET_DEVICES_TYPES:
    return {
      ...state,
      isFetchingDevices: action.payload,
    };

  case SettingsActionTypes.FETCH_GET_DEVICES_TYPES_SUCCESS:
    return {
      ...state,
      devicesTypes: action.payload,
    };

  case SettingsActionTypes.SET_FETCHING_GET_SCREENSHOTS:
    return {
      ...state,
      isFetchingScreenshots: action.payload,
    };

  case SettingsActionTypes.SET_FETCHING_APP_SEND_ON_MODERATION:
  case SettingsActionTypes.SET_FETCHING_APP_CHECK_MODERATION_LIST:
  case SettingsActionTypes.SET_FETCHING_APP_PUBLISH:
    return {
      ...state,
      isFetchingPublish: action.payload,
    };

  case SettingsActionTypes.FETCH_GET_SCREENSHOTS_SUCCESS: {
    const isExist = state.screenshots.find((s) => s.deviceType === action.payload.deviceType);

    if (isExist) {
      return {
        ...state,
        screenshots: [
          ...state.screenshots.map((s) => {
            if (s.deviceType === isExist.deviceType) {
              return {
                ...s,
                screenshots: action.payload.screenshots,
              };
            }

            return s;
          }),
        ],
      };
    }

    return {
      ...state,
      screenshots: [
        ...state.screenshots,
        {
          deviceType: action.payload.deviceType,
          screenshots: action.payload.screenshots,
        },
      ],
    };
  }

  case SettingsActionTypes.FETCH_DELETE_SCREENSHOT_SUCCESS:
    return {
      ...state,
      screenshots: [
        ...state.screenshots.map((st) => {
          if (st.deviceType === action.payload.deviceType) {
            return {
              ...st,
              screenshots: [ ...st.screenshots.filter((s) => s.id !== action.payload.id) ],
            };
          }

          return st;
        }),
      ],
    };

  case SettingsActionTypes.FETCH_DELETE_ALL_SCREENSHOTS_BY_TYPE_SUCCESS:
    return {
      ...state,
      screenshots: [
        ...state.screenshots.map((st) => {
          if (st.deviceType === action.payload) {
            return {
              ...st,
              screenshots: [],
            };
          }

          return st;
        }),
      ],
    };

  case SettingsActionTypes.FETCH_UPLOAD_SCREENSHOT_SUCCESS:
    return {
      ...state,
      screenshots: [
        ...state.screenshots.map((st) => {
          if (st.deviceType === action.payload.deviceType) {
            return {
              ...st,
              screenshots: [
                ...st.screenshots.map((s) => {
                  if (s.id === action.payload.prevId) {
                    return {
                      ...s,
                      id: action.payload.screenshot.id,
                      state: screenshotStates.IDLE,
                    };
                  }

                  return s;
                }),
              ],
            };
          }

          return st;
        }),
      ],
    };

  default:
    return state;
  }
};

export default settingsReducer;
