import React from 'react';
import { ToastContainer } from 'react-toastify';

const Notifier = (props) => (
  <ToastContainer
    closeOnClick
    draggable
    hideProgressBar
    pauseOnFocusLoss
    pauseOnHover
    autoClose={5000}
    closeButton={false}
    newestOnTop={false}
    position="top-right"
    rtl={false}
    {...props}
  />
);

export default Notifier;
