import React, { useState } from 'react';

// components
import { Box } from 'layout';
import {
  Modal,
  ModalContent,
  ModalContentWrapper,
  ModalActions,
  ModalFooterHelp,
} from 'components/modal';
import { Title } from 'components/typo';
import PhoneConfirm from 'components/PhoneConfirm';

// images
import { ReactComponent as IconTying } from 'assets/icons/multy/code-confirm-typing.svg';
import { ReactComponent as IconError } from 'assets/icons/multy/code-confirm-error.svg';
import { ReactComponent as IconSuccess } from 'assets/icons/multy/code-confirm-success.svg';

const ModalPhoneConfirm = ({
  prevModal,
  closeModal,
  data,
  isLaptopOrMobile,
}) => {
  const [
    isCodeError,
    setCodeError,
  ] = useState(false);
  const [
    isCodeSuccess,
    setCodeSuccess,
  ] = useState(false);

  const Icon = isCodeError
    ? IconError
    : isCodeSuccess
      ? IconSuccess
      : IconTying;

  return (
    <Modal
      flex
      size="full"
    >
      <ModalActions
        closeModal={closeModal}
        prevModal={prevModal}
      />
      <ModalContentWrapper>
        <ModalContent
          center
          column
          display="flex"
          p="withSidebar"
        >
          <Box
            flex
            ai="center"
            className={isLaptopOrMobile ? '' : 'mt-a'}
            direction="column"
            height="100%"
            jc="center"
          >
            <Box className={isLaptopOrMobile ? 'mb-3' : 'mb-30'}>
              <Icon
                height={52.5}
                width={55}
              />
            </Box>
            <Box className={isLaptopOrMobile ? 'mb-20' : 'mb-5'}>
              <Title tag="h2">Код из SMS</Title>
            </Box>
            <Box className="mb-5">
              <PhoneConfirm
                data={data}
                isCodeError={isCodeError}
                isCodeSuccess={isCodeSuccess}
                setCodeError={setCodeError}
                setCodeSuccess={setCodeSuccess}
              />
            </Box>
          </Box>
          <div className="mt-a mx-a">
            <Box jc="center">
              <ModalFooterHelp
                linkText="Обратиться в поддержку"
                text="Неприходит код?"
              />
            </Box>
          </div>
        </ModalContent>
      </ModalContentWrapper>
    </Modal>
  );
};

export default ModalPhoneConfirm;
