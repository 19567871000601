import React from 'react';
import { Box } from 'layout';

const Line = ({
  children,
  ...props
}) => (
  <Box
    flex
    ai="center"
    jc="space-between"
    {...props}
  >
    {children}
  </Box>
);

export default Line;
