import React from 'react';
import { ButtonRound } from 'components/base';
import { ReactComponent as IconBack } from 'assets/icons/arrow-back.svg';
import { ReactComponent as IconForward } from 'assets/icons/arrow-forward.svg';

const ButtonRoundBack = ({
  onClick,
  forward = false,
  padding = 'normal',
  ...otherProps
}) => (
  <ButtonRound
    {...otherProps}
    bgColor="light"
    padding={padding}
    renderIcon={() => (forward ? <IconForward /> : <IconBack />)}
    onClick={onClick}
  />
);

export default ButtonRoundBack;
