import React, { forwardRef } from 'react';
import { ButtonRound } from 'components/base';
import { ReactComponent as IconSettings } from 'assets/icons/settings-menu.svg';

const ButtonSettings = forwardRef(({
  onClick,
  isHovered,
  ...otherProps
}, ref) => (
  <ButtonRound
    {...otherProps}
    ref={ref}
    defaultStyle="settings"
    fill={isHovered ? 'grey500' : 'grey400'}
    renderIcon={() => <IconSettings />}
    size="settings"
    onClick={onClick}
  />
));

ButtonSettings.displayName = 'ButtonSettings';

export default ButtonSettings;
