import React, { useMemo } from 'react';
import s from 'styled-components';
import { SOCIALS_TYPES } from 'constants/SettingsTypes';
import { ReactComponent as IconFB } from 'assets/icons/socials/fb.svg';
import { ReactComponent as IconVK } from 'assets/icons/socials/vk.svg';
import { ReactComponent as IconIG } from 'assets/icons/socials/ig.svg';
import { ReactComponent as IconTG } from 'assets/icons/socials/tg.svg';
import { ReactComponent as IconWP } from 'assets/icons/socials/wp.svg';
import { ReactComponent as IconSite } from 'assets/icons/socials/site.svg';

const CardIconSocialContainer = s.div`
display: flex;
align-items: center;
justify-content: center;
width: 46px;
height: 46px;
background-color: ${({
    theme,
    $isActive,
  }) => ($isActive ? theme.colors.green : theme.colors.grey100)};
border-radius: ${({ theme }) => theme.radius.medium};
transition: background-color 0.3s ease-in-out;

svg {
  display: block;
  transition: fill 0.3s ease-in-out;
  fill: ${({
    theme,
    $isActive,
  }) => ($isActive ? theme.colors.white : theme.colors.grey500)};
}
`;

const CardIconSocial = ({
  type,
  isActive,
}) => {
  const renderIcon = useMemo(() => {
    let Icon = null;

    switch (type) {
    case SOCIALS_TYPES.INSTAGRAM:
      Icon = IconIG;
      break;
    case SOCIALS_TYPES.VK:
      Icon = IconVK;
      break;
    case SOCIALS_TYPES.FACEBOOK:
      Icon = IconFB;
      break;
    case SOCIALS_TYPES.TELEGRAM:
      Icon = IconTG;
      break;
    case SOCIALS_TYPES.WHATS_APP:
      Icon = IconWP;
      break;
    case SOCIALS_TYPES.SITE:
      Icon = IconSite;
      break;
    default:
      break;
    }

    return <Icon />;
  }, [ type ]);

  return (
    <CardIconSocialContainer $isActive={isActive}>
      {renderIcon}
    </CardIconSocialContainer>
  );
};

export default CardIconSocial;
