import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Formik,
  Field,
} from 'formik';
import { SchemaFormQuestion } from 'constants/ValidationSchemas';

// components
import { Button } from 'components/base';
import { Box } from 'layout';
import {
  Form,
  FormField,
  FormGroup,
} from 'components/form';
import { Title } from 'components/typo';
import useResponsive from 'components/hooks/useResponsive';

// constants
import { fetchFormRequesе } from 'redux/actions/SystemActions';

const FormQuestion = ({
  type,
  title,
  descriptionPlaceholder = 'Сообщение...',
  isEmpty = false,
}) => {
  const dispatch = useDispatch();
  const { isLaptopOrMobile } = useResponsive();
  const { profile = {} } = useSelector((state) => state.user);

  const handleRequest = (values, { setSubmitting }) => {
    const data = {
      email: values.email,
      message: values.message,
      order_type: type,
    };

    dispatch(fetchFormRequesе(data))
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={{
        email: isEmpty ? '' : (profile && profile.email) || '',
        message: '',
      }}
      validationSchema={SchemaFormQuestion}
      onSubmit={handleRequest}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          isSubmitting,
        } = props;

        const attrs = {
          onChange: handleChange,
          onBlur: handleBlur,
          errors,
          touched,
          size: 'big',
        };

        const formIsValid = dirty && isValid;

        return (
          <Form
            noValidate
            onSubmit={handleSubmit}
          >
            <Box
              className={isLaptopOrMobile ? 'mb-20' : 'mb-5'}
              jc="center"
            >
              <Title tag="h2">{title}</Title>
            </Box>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={values.email}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    name="message"
                    placeholder={descriptionPlaceholder}
                    tag="textarea"
                    value={values.message}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Button
                full
                defaultStyle="acsent"
                isDisabled={isSubmitting || !formIsValid}
                isFetching={isSubmitting}
                size="big"
                title="Отправить заявку"
                type="submit"
              />
            </FormGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormQuestion;
