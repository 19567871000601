import React from 'react';
import s from 'styled-components';

const defaultStyles = ({
  theme,
  $br,
  $position,
}) => {
  const positionStyles = theme.components.dropdown.positions[$position] || theme.components.dropdown.positions['bottom-right'];

  return `
    display: inline-flex;
    flex-direction: column;
    padding: 5px 0px;
    background-color: #fff;
    border-radius: ${$br ? theme[$br] : theme.radius.small};
    box-shadow: 5px 5px 25px rgba(113, 128, 150, 0.12);
    border: 0.5px solid ${theme.colors.grey300};


    ${positionStyles ? `
      position: absolute;
      z-index: 10;
      top: ${positionStyles.top || 'unset'};
      left: ${positionStyles.left || 'unset'};
      bottom: ${positionStyles.bottom || 'unset'};
      right: ${positionStyles.right || 'unset'};
      margin-top: ${positionStyles.marginTop || 'unset'};
      margin-bottom: ${positionStyles.marginBottom || 'unset'};
  ` : ''}
  `;
};

const DropdownMenuContainer = s.div`
  ${(props) => defaultStyles(props)}
`;

const DropdownMenu = ({
  children,
  position = 'bottom-right',
  br,
  ...otherProps
}) => (
  <DropdownMenuContainer
    $position={position}
    $br={br}
    {...otherProps}
  >
    {children}
  </DropdownMenuContainer>
);

export default DropdownMenu;
