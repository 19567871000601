import React, { useEffect } from 'react';
import cn from 'classnames';
import s from 'styled-components';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import PageHeaderHome from './PageHeader/PageHeaderHome';
import { ButtonRoundBack } from 'components/buttons';
import { Container } from 'layout';
import useResponsive from 'components/hooks/useResponsive';

const PageContainer = s.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .back-button {
    position: absolute;
    top: 108px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.--relative {
    position: relative;
  }

  &.--center {
    main {
      display: block;
      height: 100%;
    }
  }

  main {
    flex: 1;
    padding: ${({ $noPadding }) => ($noPadding ? 0 : '68px 0 100px 0')};

    @media (max-width: 768px) {
      padding: ${({ $noPadding }) => ($noPadding ? 0 : '40px 0 60px 0')};
    }
  }
`;

const Page = ({
  className,
  children,
  noPadding,
  isSecond = false,
  back = false,
  noHead = false,
  isCenter = false,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLaptopBigOrMobile } = useResponsive();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ location ]);

  const handleGoBack = () => {
    if (typeof back === 'number') {
      navigate(location.pathname.slice(0, location.pathname.lastIndexOf('/')));
    }
    else if (typeof back === 'string') {
      navigate(back);
    }
    else {
      navigate(-1);
    }
  };

  return (
    <PageContainer
      className={cn('page', {
        '--relative': back,
        '--center': isCenter,
      }, className)}
      $noPadding={noPadding}
    >
      {back && !isLaptopBigOrMobile && (
        <Container className="back-button">
          <ButtonRoundBack onClick={handleGoBack} />
        </Container>
      )}
      {!noHead && <PageHeaderHome isSecond={isSecond} />}
      <main>{children}</main>
    </PageContainer>
  );
};

export default Page;
