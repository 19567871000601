import React, { useMemo } from 'react';
import cn from 'classnames';
import s from 'styled-components';
import { ReactComponent as IconCheck } from './icons/check.svg';
import { ReactComponent as IconPlus } from './icons/plus.svg';
import { Text } from 'components/typo';
import { Box } from 'layout';

const ListTextItemContainer = s.div`
  svg {
    transition: fill 0.3s ease-in-out;
    fill: ${({ theme }) => theme.colors.grey500};
  }

  .item-text,
  .item-description {
    transition: color 0.3s ease-in-out;
  }

  &.--m {
    .item-description {
      max-width: 160px;
    }
  }

  &.--s {
    &-dark {
      color: ${({ theme }) => theme.colors.grey500};

      svg {
        fill: ${({ theme }) => theme.colors.black};
      }
    }

    &-white {
      color: ${({ theme }) => theme.colors.white};

      svg {
        fill: ${({ theme }) => theme.colors.white};
      }
    }

    &-grey {
      color: ${({ theme }) => theme.colors.grey500};

      svg {
        fill: ${({ theme }) => theme.colors.grey500};
      }
    }
  }
`;

function ListTextItem({
  children,
  title,
  description,
  isLaptopOrMobile,
  defaultStyle = 'black',
  icon = 'check',
  size = 'medium',
  isTransition = false,
}) {
  const Icon = useMemo(() => {
    switch (icon) {
    case 'check':
      return IconCheck;
    case 'plus':
      return IconPlus;
    default:
      return null;
    }
  }, [ icon ]);

  return (
    <ListTextItemContainer className={cn('w-list-item', {
      '--m': isLaptopOrMobile,
      [`--s-${defaultStyle}`]: defaultStyle,
    })}
    >
      <Box>
        {Icon && <Icon className={isLaptopOrMobile ? 'mr-2' : 'mr-20'} />}
        <div>
          <Text
            className="item-text"
            color="inherit"
            isTransition={isTransition}
            size={size}
          >
            {children || title}
          </Text>
          {description && (
            <Text
              className="item-description mt-10"
              color="whitet70"
              isTransition={isTransition}
              size={isLaptopOrMobile ? 'xs' : 'small'}
            >
              {description}
            </Text>
          )}
        </div>
      </Box>
    </ListTextItemContainer>
  );
}

export default ListTextItem;
