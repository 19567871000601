import React, {
  useCallback,
  useState,
  useMemo,
} from 'react';
import s from 'styled-components';
import cn from 'classnames';

// components
import FormError from './FormError';
import { FormInputDropdown } from 'components/form';
import OutsideAlerter from 'utils/OutsideAlerter';

const getStyles = ({
  theme,
  $isPosition,
}) => `
position: relative;

.dropdown-menu {
  position: absolute;
  right: ${$isPosition === 'right' ? 0 : 'unset'};
  left: ${$isPosition === 'left' ? 0 : 'unset'};
  z-index: 2;
}

&.--bottom .dropdown-menu {
  margin-top: 20px;
  top: 100%;
}

&.--top .dropdown-menu {
  bottom: 100%;
  margin-bottom: 10px;

}

&.--s-field {
  background-color: ${theme.colors.transparent};
  transition: background-color 0.3s ease-in-out;

  input {
    padding: 0 ${theme.spacers[4]}px;
  }

  .toggler {
    right: ${theme.spacers[4]}px;
  }

  &:hover {
    background-color: ${theme.colors.white20};
  }
}
`;

const DropdownContainer = s.div`
  ${(props) => getStyles(props)}
`;

const FormDropdown = (props) => {
  const {
    children,
    name = '',
    renderDropdown = () => {},
    isError = false,
    isTouched = false,
    isPadded = false,
    defaultStyle = '',
    position = 'right',
    isTop = false,
    ...otherProps
  } = props;

  const [
    show,
    setShow,
  ] = useState(false);

  const handleDropdown = useCallback((value) => {
    setShow(value);
  }, [ setShow ]);

  const handleDropdownOpen = useCallback(() => {
    handleDropdown(true);
  }, [ handleDropdown ]);

  const handleDropdownClose = useCallback(() => {
    handleDropdown(false);
  }, [ handleDropdown ]);

  const inputHandler = useMemo(
    () => (show
      ? handleDropdownClose
      : handleDropdownOpen),
    [
      show,
      handleDropdownClose,
      handleDropdownOpen,
    ],
  );

  return (

    <DropdownContainer
      className={cn('w-dropdown', {
        '--padded': isPadded,
        '--top': isTop,
        '--bottom': !isTop,
        [`--s-${defaultStyle}`]: defaultStyle,
      })}
      $isPosition={position}
    >
      <OutsideAlerter onClickOutside={handleDropdownClose}>
        <FormInputDropdown
          {...otherProps}
          isActive={show}
          name={name}
          onClick={inputHandler}
        />
        {isError && isTouched && <FormError>{isError}</FormError>}
        {show && (
          <div className="dropdown-menu">
            {renderDropdown({ closeDropdown: handleDropdownClose })}
          </div>
        )}
      </OutsideAlerter>
    </DropdownContainer>
  );
};

export default FormDropdown;
