import React from 'react';
import { formOrderTypes } from 'constants/FormOrderTypes';

// components
import { Box } from 'layout';
import {
  Modal,
  ModalBanner,
  ModalContent,
  ModalActions,
  ModalContentWrapper,
} from 'components/modal';
import FormRequest from 'components/form/FormRequest';
import {
  TelegramLink,
  EmailLink,
} from 'components/socials';

// images
import { ReactComponent as Icon } from 'assets/icons/multy/payment-card.svg';
import bgImage from 'assets/images/modals/help-bg.jpg';

const ModalPayments = ({
  closeModal,
  isLaptopBigOrMobile,
  isLaptopOrMobile,
}) => {
  const descriptionHtml = (
    <div>
      Заполните форму заявки или напишите на
      {' '}
      <EmailLink />
      {' '}
      или в
      {' '}
      <TelegramLink />
      . Мы свяжемся с вами в течение 20-30 минут и обсудим все детали.
    </div>
  );

  return (
    <Modal
      flex
      size="full"
    >
      <ModalActions closeModal={closeModal} />
      {!isLaptopBigOrMobile && (
        <ModalBanner
          center
          descriptionHtml={descriptionHtml}
          img={bgImage}
          title="Индивидуальный подход к&nbsp;каждому клиенту"
        />
      )}
      <ModalContentWrapper>
        <ModalContent p="withSidebar">
          <Box
            flex
            ai="center"
            direction="column"
            height="100%"
            jc="center"
          >
            <Box className={isLaptopOrMobile ? 'mb-3' : 'mb-30'}>
              <Icon
                height={49}
                width={55}
              />
            </Box>
            <Box self="stretch">
              <FormRequest
                title="Подключить online оплату"
                type={formOrderTypes.ACQUIRING}
              />
            </Box>
          </Box>
        </ModalContent>
      </ModalContentWrapper>
    </Modal>
  );
};

export default ModalPayments;
