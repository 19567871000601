import React from 'react';
import { Field } from 'formik';
import * as PathsTypes from 'constants/PathsTypes';

// components
import {
  Link,
  Text,
} from 'components/typo';
import { FormCheckbox } from 'components/form';

// constants

const FormTrems = ({
  isLaptopOrMobile,
  ...props
}) => (
  <Field>
    {() => (
      <FormCheckbox
        {...props}
        html={(
          <Text
            color="grey500"
            size={isLaptopOrMobile ? 'small' : 'normal'}
            tag="p"
          >
            Соглашаюсь с
            {' '}
            <Link
              browser
              href={PathsTypes.POLITIC}
              size="inherit"
              target="_blank"
            >
              политикой обработки данных
            </Link>
          </Text>
        )}
      />
    )}
  </Field>
);

export default FormTrems;
