import React from 'react';
import styled from 'styled-components';

const FormTimeListContainer = styled.div`
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;

  & > * {
    padding: 10px;
  }

  & > * + * {
    border-top: 1px solid #e3e3e3;
  }

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    fill: #e3e3e3;
  }

  svg:hover {
    fill: red;
  }
`;

const FormTimeList = ({
  children = null,
  ...otherProps
}) => (
  <FormTimeListContainer {...otherProps}>{children}</FormTimeListContainer>
);

export default FormTimeList;
