import React from 'react';
import s from 'styled-components';
import PropTypes from 'prop-types';

export { Text } from 'components/typo';

const defaultStyles = ({ theme }) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    min-width: 28px;
    padding: 5px;
    background-color: ${theme.colors.green};
    color: ${theme.colors.white};
    border-radius: 8px;
    font-size: 16px;
    line-height: 1;
    font-weight: ${theme.fw.sb};
  `;

const BageCountContainer = s.div`
  ${(props) => defaultStyles(props)}
`;

const BageCount = ({
  count,
  children,
  ...otherProps
}) => (
  <BageCountContainer
    className="w-count"
    {...otherProps}
  >
    <span>{count}</span>
  </BageCountContainer>
);

BageCount.propTypes = { count: PropTypes.number };

export default BageCount;
