const SmoothScroolToTarget = (e) => {
  e.preventDefault();
  e.stopPropagation();
  const { target } = e;

  const targetSectionId = target.getAttribute('data-target');
  const targetSection = document.getElementById(targetSectionId);

  if (targetSection) {
    targetSection.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

export default SmoothScroolToTarget;
