import { OrdersActionTypes } from 'redux/types/OrdersTypes';
import { UserActionTypes } from 'redux/types/UserTypes';

const INITIAL_STATE = {
  name: 'orders',
  homeList: [],
  homeListIsEmpty: false,
  clientList: [],
  clientListIsEmpty: false,
  isFetching: false,
  isSubmitting: false,
  newOrdersCount: 0,
  countAll: 0,
  order: null,
  productsModal: false,
};

const ordersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE };

  case OrdersActionTypes.SET_PRODUCTS_MODAL:
    return {
      ...state,
      productsModal: action.payload,
    };

  case OrdersActionTypes.SET_ORDER_COUNT_ALL:
    return {
      ...state,
      countAll: action.payload,
    };

  case OrdersActionTypes.SET_ORDER:
    return {
      ...state,
      order: action.payload,
    };

  case OrdersActionTypes.SET_FETCHING_ORDERS:
    return {
      ...state,
      isFetching: action.payload,
    };

  case OrdersActionTypes.FETCH_ORDERS_HOME_SUCCESS:
    return {
      ...state,
      homeList: action.payload,
    };

  case OrdersActionTypes.FETCH_ORDERS_HOME_UPLOAD_SUCCESS:
    return {
      ...state,
      homeList: state.homeList ? [
        ...state.homeList,
        ...action.payload,
      ] : action.payload,
    };

  case OrdersActionTypes.SET_ORDERS_HOME_IS_EMPTY:
    return {
      ...state,
      homeListIsEmpty: action.payload,
    };

  case OrdersActionTypes.CLEAR_ORDERS_HOME:
    return {
      ...state,
      homeList: [],
      homeListIsEmpty: false,
    };

  case OrdersActionTypes.FETCH_ORDERS_CLIENT_SUCCESS:
    return {
      ...state,
      clientList: action.payload,
    };

  case OrdersActionTypes.SET_ORDERS_CLIENT_IS_EMPTY:
    return {
      ...state,
      clientListIsEmpty: action.payload,
    };

  case OrdersActionTypes.CLEAR_ORDERS_CLIENT:
    return {
      ...state,
      clientList: [],
      clientListIsEmpty: false,
    };

  case OrdersActionTypes.SET_FETCHING_ORDER_SUBMITTING:
    return {
      ...state,
      isSubmitting: action.payload,
    };

  case OrdersActionTypes.FETCH_ORDER_DELETE_SUCCESS:
    return {
      ...state,
      homeList: state.homeList.filter((c) => c.id !== action.payload),
    };

  case OrdersActionTypes.FETCH_ORDER_UPDATE_SUCCESS:
    return {
      ...state,
      order: action.payload,
      homeList: state.homeList ? state.homeList.map((o) => (o.id === action.payload.id
        ? action.payload
        : o))
        : state.homeList,
    };

  case OrdersActionTypes.FETCH_ORDER_STATUS_READY_SUCCESS:
  case OrdersActionTypes.FETCH_ORDER_STATUS_COMPLETE_SUCCESS:
  case OrdersActionTypes.FETCH_ORDER_ACCEPT_SUCCESS:
  case OrdersActionTypes.FETCH_ORDER_REJECT_SUCCESS:
    return {
      ...state,
      homeList: state.homeList.map((c) => (c.id === action.payload.id ? action.payload : c)),
    };

  case OrdersActionTypes.SET_NEW_ORDERS_COUNT:
    return {
      ...state,
      newOrdersCount: action.payload,
    };

  default:
    return state;
  }
};

export default ordersReducer;
