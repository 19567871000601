import React, {
  useMemo,
  useCallback,
} from 'react';
import s from 'styled-components';
import cn from 'classnames';
import {
  PeriodTabsModel,
  PeriodTypes,
  RateTypes,
} from 'constants/SubscriptionsTypes';
import { Box } from 'layout';
import {
  Bage,
  Button,
} from 'components/base';
import {
  Title,
  Text,
} from 'components/typo';
import { ReactComponent as IconCheck } from 'assets/icons/check-tin.svg';
import ListText from 'components/list/ListText';

const SubscriptionItemContainer = s.div`
  padding: 32px;
  color: ${({ theme }) => theme.colors.grey500};
  background-color: ${({ theme }) => theme.colors.grey100};
  border: 1px solid ${({ theme }) => theme.colors.grey100};
  border-radius: ${({ theme }) => theme.radius.xl};
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

  &.--with-hover {
    cursor: pointer;
  }

  &.--with-hover:hover,
  &.--active {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.grey300};

    .sub-item__list {
      .w-text {
        color: ${({ theme }) => theme.colors.black};
      }

      svg {
        fill: ${({ theme }) => theme.colors.black};
      }
    }
  }

  &.--mob {
    padding: 20px;
    border-radius: ${({ theme }) => theme.radius.normalBig};
  }

  .sub-item {
    width: 100%;

    &__head {
      margin-bottom: 44px;
    }

    &__head-top {
      margin-bottom: 15px;
    }

    &__description {
      max-width: 290px;
    }

    &__price {
      position: relative;
      margin-bottom: 20px;

      &-before {
        position: absolute;
        bottom: 100%;
        left: 0;

        & > * {
          font-size: 20px;
          line-height: 14px;
        }
      }
    }

    &__action {
      margin-bottom: 24px;
    }

    &__list {
      padding-top: 24px;
      border-top: 1px solid ${({ theme }) => theme.colors.grey300};
    }

    &__info {
      margin-top: 30px;
    }
  }

  &.--mob .sub-item {
    &__head {
      margin-bottom: 16px;
    }

    &__head-top {
      margin-bottom: 12px;
    }

    &__description {
      max-width: 260px;
    }

    &__action {
      margin-bottom: 22px;
    }

    &__list {
      padding-top: 22px;
    }

    &__info {
      margin-top: 20px;
    }

    &__price-before {
      position: static;
      bottom: unset;
      left: unset;

      & > * {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
`;
const SubscriptionItem = ({
  children,
  id = null,
  title = '',
  label = '',
  description = '',
  variant = 'grey',
  periodsPrices = [],
  activePeriod = PeriodTypes.MONTH,
  pricesLabel = null,
  isAuth = false,
  onToggle = () => {},
  onBuy = () => {},
  activeUserSubscription = {},
  actionTitle = '',
  list = [],
  listPrev = null,
  info = '',
  isLaptopOrMobile = false,
  rate_type: rateType = false,
  withHover = false,
  isFetchingSubscription = false,
  showConfirmModal = () => {},
  ...props
}) => {
  const priceBefore = useMemo(() => {
    if (!periodsPrices.length) return null;
    const isPeriodIdx = PeriodTabsModel.findIndex((p) => p.id === activePeriod);

    if (!isPeriodIdx || isPeriodIdx < 1) {
      return null;
    }

    const price = periodsPrices[isPeriodIdx - 1] && periodsPrices[isPeriodIdx - 1].raw_price;

    return price && price > 0 ? (
      <Text
        color="grey500"
        tag="span"
      >
        <Text
          color="current"
          size="inherit"
          tag="span"
          td="line-through"
        >
          {price}
        </Text>
        {' '}
        ₽/мес
      </Text>
    ) : '';
  }, [
    activePeriod,
    periodsPrices,
  ]);

  const priceInfo = useMemo(() => {
    if (activePeriod === PeriodTypes.MONTH || rateType === RateTypes.BY_ORDER) return null;

    return `*Стоимость при оплате тарифа сразу за ${activePeriod === PeriodTypes.MONTH_3 ? '3 месяца' : '1 год'}.`;
  }, [
    activePeriod,
    rateType,
  ]);

  const activePeriodPriceData = useMemo(() => periodsPrices.find((p) => p.period === activePeriod), [
    periodsPrices,
    activePeriod,
  ]);

  const priceTitle = useMemo(() => {
    const isPrice = activePeriodPriceData || null;

    if (!periodsPrices.length || rateType === RateTypes.BY_ORDER) {
      return (
        <Title
          color="black"
          tag="h2"
        >
          По запросу
        </Title>
      );
    }

    if (!isPrice && !pricesLabel) {
      return (
        <Title
          color="black"
          tag="h2"
        >
          Цена не указана
        </Title>
      );
    }

    return (
      <Title
        color="black"
        tag="h2"
      >
        <Title
          color="current"
          tag="span"
          tagStyle="h1"
        >
          {isPrice ? isPrice.price || '0' : pricesLabel}
        </Title>
        {' '}
        ₽/мес
        {(info || priceInfo) && (
          <Title
            color="grey500"
            tag="span"
            tagStyle="current"
          >
            *
          </Title>
        )}
      </Title>
    );
  }, [
    periodsPrices,
    activePeriodPriceData,
    rateType,
    pricesLabel,
    info,
    priceInfo,
  ]);

  const listAdvantages = useMemo(() => {
    const newList = list.map((text) => ({ title: text }));

    if (listPrev) {
      newList.unshift({
        title: listPrev,
        icon: 'plus',
      });
    }

    return newList;
  }, [
    list,
    listPrev,
  ]);

  const isActive = useMemo(() => {
    const {
      period: activeSubscriptionPeriod = undefined,
      subscriptionId: activeSubscriptionId = undefined,
      ended_at: activeSubscriptionEndedAt = undefined,
    } = activeUserSubscription;

    if (!periodsPrices.length || activeSubscriptionId !== id) return null;

    const isPeriod = periodsPrices.find((p) => p.period === activePeriod);

    if (!isPeriod && activeSubscriptionEndedAt !== null) {
      return false;
    }

    return (isPeriod && isPeriod.id === activeSubscriptionPeriod) || activeSubscriptionEndedAt === null;
  }, [
    id,
    activePeriod,
    periodsPrices,
    activeUserSubscription,
  ]);

  const containerClasses = useMemo(() => cn('sub-item', {
    '--mob': isLaptopOrMobile,
    '--active': isActive,
    '--with-hover': withHover,
  }), [
    isActive,
    isLaptopOrMobile,
    withHover,
  ]);

  const btnTitle = useMemo(() => {
    const { subscriptionId: activeSubscriptionId = undefined } = activeUserSubscription;

    if (activeSubscriptionId === undefined) return actionTitle;

    return isActive ? 'Выбран' : 'Выбрать';
  }, [
    isActive,
    actionTitle,
    activeUserSubscription,
  ]);

  const handleClick = useCallback(() => {
    function buySubscription() {
      return onBuy(activePeriodPriceData.id);
    }

    if (isAuth && rateType !== RateTypes.BY_ORDER) {
      if (activePeriodPriceData && String(activePeriodPriceData.price) === '0') {
        showConfirmModal(activePeriodPriceData.id);
      }
      else {
        buySubscription();
      }
    }
    else {
      onToggle(id);
    }
  }, [
    id,
    isAuth,
    rateType,
    activePeriodPriceData,
    onBuy,
    // dispatch,
    onToggle,
    showConfirmModal,
  ]);

  const action = useMemo(() => (
    <Button
      full
      defaultStyle={isActive ? 'whitegrey' : variant}
      isDisabled={isActive}
      isFetching={isFetchingSubscription}
      opacityFull={isActive}
      size="big"
      title={btnTitle}
      onClick={handleClick}
    >
      {isActive && !isFetchingSubscription && (
        <IconCheck
          fill="black"
          height={28}
          width={28}
        />
      )}
    </Button>
  ), [
    isActive,
    btnTitle,
    handleClick,
    variant,
    isFetchingSubscription,
  ]);

  return (
    <SubscriptionItemContainer
      className={containerClasses}
      {...props}
    >
      <div className="sub-item__head">
        <Box
          className="sub-item__head-top"
          jc="space-between"
        >
          <Title
            color="black"
            fw="n"
            tag="h2"
          >
            {title}
          </Title>
          <Bage
            title={label}
            variant={variant}
          />
        </Box>
        <Text
          className="sub-item__description"
          color="current"
          size={isLaptopOrMobile ? 'small' : 'normal'}
        >
          {description}
        </Text>
      </div>
      <div className="sub-item__price">
        {priceBefore && <div className="sub-item__price-before">{priceBefore}</div>}
        {priceTitle}
      </div>
      <div className="sub-item__action">
        {action}
      </div>
      <div className="sub-item__list">
        <ListText
          isTransition
          big={false}
          defaultStyle="grey"
          items={listAdvantages}
        />
      </div>
      {(info || priceInfo) && (
        <div className="sub-item__info">
          <Text
            color="current"
            size="small"
          >
            {info || priceInfo}
          </Text>
        </div>
      )}
    </SubscriptionItemContainer>
  );
};

export default SubscriptionItem;
