import React from 'react';

// components
import { Box } from 'layout';
import {
  Modal,
  ModalContent,
  ModalActions,
  ModalFooterHelp,
} from 'components/modal';
import ChangePassword from 'components/ChangePassword';

const ModalChangePassword = ({
  prevModal,
  closeModal,
  modal,
}) => {
  const props = {
    closeModal,
    prevModa: modal && modal.prev ? prevModal : null,
  };

  return (
    <Modal
      flex
      size="full"
    >
      <ModalActions {...props} />
      <ModalContent
        center
        column
        display="flex"
        p="withSidebar"
      >
        <Box
          flex
          ai="center"
          direction="column"
          height="100%"
          jc="center"
        >
          <Box
            className="mb-5"
            self="stretch"
          >
            <ChangePassword />
          </Box>
        </Box>
        <div className="mt-a mx-a">
          <Box
            jc="center"
            self="center"
          >
            <ModalFooterHelp />
          </Box>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ModalChangePassword;
