import { StoriesActionTypes } from 'redux/types/StoriesTypes';
import { UserActionTypes } from 'redux/types/UserTypes';

const INITIAL_STATE = {
  name: 'stories',
  homeList: [],
  homeListIsEmpty: true,
  isFetching: false,
  isFetchingToggleActive: false,
  isSubmitting: false,

  groupStory: null,
  groupStoryStories: null,
  isFetchingGroupStoryStories: false,
  story: false,
  isStorySubmitting: false,
};

const storiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE };

  case StoriesActionTypes.SET_FETCHING_STORIES:
    return {
      ...state,
      isFetching: action.payload,
    };

  case StoriesActionTypes.FETCH_STORIES_HOME_SUCCESS:
    return {
      ...state,
      homeList: action.payload,
    };

  case StoriesActionTypes.SET_STORIES_HOME_IS_EMPTY:
    return {
      ...state,
      homeListIsEmpty: action.payload,
    };

  case StoriesActionTypes.CLEAR_STORIES_HOME:
    return {
      ...state,
      homeList: [],
      homeListIsEmpty: false,
    };

  case StoriesActionTypes.FETCH_GET_GROUP_STORY_SUCCESS:
    return {
      ...state,
      groupStory: action.payload,
    };

  case StoriesActionTypes.CLEAR_GROUP_STORY:
    return {
      ...state,
      groupStory: null,
    };

  case StoriesActionTypes.SET_FETCHING_GROUP_STORY_SUBMITTING:
    return {
      ...state,
      isSubmitting: action.payload,
    };

  case StoriesActionTypes.SET_FETCHING_GROUP_STORY_STORIES_BY_ID:
    return {
      ...state,
      isFetchingGroupStoryStories: action.payload,
    };

  case StoriesActionTypes.FETCH_GROUP_STORY_CREATE_SUCCESS:
    return {
      ...state,
      homeList: state.homeList.length ? [
        ...state.homeList,
        action.payload,
      ] : [],
    };

  case StoriesActionTypes.FETCH_GROUP_STORY_UPDATE_SUCCESS: {
    const isExistIdx = state.homeList.findIndex((c) => c.id === action.payload.id);

    if (isExistIdx >= 0) {
      return {
        ...state,
        homeList: [
          ...state.homeList.slice(0, isExistIdx),
          action.payload,
          ...state.homeList.slice(isExistIdx + 1),
        ],
      };
    }

    return state;
  }

  case StoriesActionTypes.FETCH_GROUP_STORY_DELETE_SUCCESS:
    return {
      ...state,
      homeList: state.homeList.filter((c) => c.id !== action.payload),
    };

  case StoriesActionTypes.SET_FETCHING_GROUP_STORY_TOGGLE_ACTIVE:
    return {
      ...state,
      isFetchingToggleActive: action.payload,
    };

  case StoriesActionTypes.FETCH_GROUP_STORY_TOGGLE_ACTIVE_SUCCESS:
    return {
      ...state,
      homeList: [
        ...state.homeList.map((p) => (p.id === action.payload.id
          ? action.payload
          : p)),
      ],
    };

  case StoriesActionTypes.SET_FETCHING_GROUP_STORY_STORIES_SUBMITTING:
    return {
      ...state,
      isStorySubmitting: action.payload,
    };

  case StoriesActionTypes.FETCH_GROUP_STORY_STORIES_BY_ID_SUCCESS: {
    return {
      ...state,
      groupStoryStories: action.payload,
    };
  }

  case StoriesActionTypes.FETCH_GROUP_STORY_STORIES_BY_ID_CREATE_SUCCESS: {
    return {
      ...state,
      groupStoryStories: state.groupStoryStories
        ? [
          ...state.groupStoryStories,
          action.payload,
        ]
        : [ action.payload ],
      homeList: state.homeList.length
        ? state.homeList.map((gs) => (gs.id === action.payload.group
          ? {
            ...gs,
            amount_stories: gs.amount_stories + 1,
          } : gs))
        : [],
    };
  }

  case StoriesActionTypes.FETCH_GROUP_STORY_STORIES_BY_ID_DELETE_SUCCESS: {
    return {
      ...state,
      groupStoryStories: state.groupStoryStories.filter((story) => story.id !== action.payload.storyId),
      homeList: state.homeList.length ? state.homeList.map((gs) => (gs.id === action.payload.groupStoryId
        ? {
          ...gs,
          amount_stories: gs.amount_stories - 1,
        }
        : gs)) : [],
    };
  }

  case StoriesActionTypes.FETCH_GROUP_STORY_STORIES_BY_ID_UPDATE_SUCCESS: {
    return {
      ...state,
      groupStoryStories: state.groupStoryStories.map((story) => (story.id === action.payload.id
        ? action.payload
        : story)),
    };
  }

  case StoriesActionTypes.CLEAR_GROUP_STORY_STORIES: {
    return {
      ...state,
      groupStoryStories: null,
    };
  }

  case StoriesActionTypes.SET_STORY: {
    return {
      ...state,
      story: action.payload,
    };
  }

  case StoriesActionTypes.CLEAR_STORY: {
    return {
      ...state,
      story: null,
    };
  }

  default:
    return state;
  }
};

export default storiesReducer;
