import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import history from 'history/configureHistory';
import { setModal } from 'redux/actions/SystemActions';

const NavigationGuard = ({ onYes = () => {} }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const prevLocation = history.location;

    const unblock = history.block(({ location }) => {
      if (prevLocation.pathname !== location.pathname) {
        dispatch(setModal({
          name: 'confirm',
          center: true,
          type: 'save',
          onYes: (closeModal = () => {}) => {
            unblock();
            closeModal();
            onYes(location);

            return false;
          },
          onNo: (closeModal = () => {}) => {
            unblock();
            closeModal();
            history.push(location.pathname, {
              ...prevLocation.state,
              noFetch: true,
              noScrollTop: false,
            } || {});

            return false;
          },
        }));

        return false;
      }
    });

    return () => unblock();

    // eslint-disable-next-line
  }, [])

  return null;
};

export default NavigationGuard;
