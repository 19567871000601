import React, { useMemo } from 'react';
import { formOrderTypes } from 'constants/FormOrderTypes';

// components
import { Box } from 'layout';
import {
  Modal,
  ModalBanner,
  ModalContent,
  ModalActions,
  ModalContentWrapper,
} from 'components/modal';
import FormRequest from 'components/form/FormRequest';
import {
  EmailLink,
  TelegramLink,
} from 'components/socials';

// images
import { ReactComponent as IconStarBusines } from 'assets/icons/multy/star-modal.svg';
import { ReactComponent as IconStarMedium } from 'assets/icons/multy/star-modal-green.svg';
import bgImage from 'assets/images/modals/help-bg.jpg';

const ModalOrderSubscriptions = ({
  closeModal,
  modal,
  isLaptopBigOrMobile,
  isLaptopOrMobile,
}) => {
  const descriptionHtml = (
    <div>
      Заполните форму заявки или напишите на
      {' '}
      <EmailLink />
      {' '}
      или в
      {' '}
      <TelegramLink />
      . Мы свяжемся с вами в течение 20-30 минут и обсудим все детали.
    </div>
  );

  const modalData = useMemo(() => {
    if (modal && modal.data && modal.data.id) {
      switch (modal.data.id) {
      case 3:
        return {
          title: 'Перейти на «Бизнес»',
          icon: IconStarBusines,
        };

      case 2:
        return {
          title: 'Перейти на «Медиум»',
          icon: IconStarMedium,
        };

      default:
        return {
          title: '',
          icon: null,
        };
      }
    }

    return {
      title: '',
      icon: null,
    };
  }, [ modal ]);

  const Icon = modalData.icon || null;

  return (
    <Modal
      flex
      size="full"
    >
      <ModalActions closeModal={closeModal} />
      {!isLaptopBigOrMobile && (
        <ModalBanner
          center
          descriptionHtml={descriptionHtml}
          img={bgImage}
          title="Индивидуальный подход к&nbsp;каждому клиенту"
        />
      )}
      <ModalContentWrapper>
        <ModalContent p="withSidebar">
          <Box
            flex
            ai="center"
            direction="column"
            height="100%"
            jc="center"
          >
            {Icon && (
              <Box className={isLaptopOrMobile ? 'mb-3' : 'mb-30'}>
                <Icon
                  height={49}
                  width={55}
                />
              </Box>
            )}
            <Box self="stretch">
              <FormRequest
                title={modalData.title}
                type={formOrderTypes.BUSINESS}
              />
            </Box>
          </Box>
        </ModalContent>
      </ModalContentWrapper>
    </Modal>
  );
};

export default ModalOrderSubscriptions;
