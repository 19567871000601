import React from 'react';
import s from 'styled-components';
import * as PathsTypes from 'constants/PathsTypes';
import { Link } from 'components/typo';

const FormPoliticContainer = s.div`
  color: #979797;
  font-size: 13px;
`;

const FormPolitic = ({ className = '' }) => (
  <FormPoliticContainer className={`ps-politic ${className}`}>
    Нажимая кнопку «отправить» вы соглашаетесь с
    {' '}
    <Link to={PathsTypes.POLITIC}>политикой конфиденциальности</Link>
    {' '}
    данных.
  </FormPoliticContainer>
);

export default FormPolitic;
