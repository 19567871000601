import React from 'react';
import { Bage } from 'components/base';

const CardActiveStatus = ({ isActive = true }) => (
  <Bage
    br={8}
    title={isActive ? 'ON' : 'OFF'}
    variant={isActive ? 'orange' : 'grey'}
  />
);

export default CardActiveStatus;
