import { DemosActionTypes } from '../types/DemosTypes';
import { searchResponseIsEmpty } from '../utils/ResponseUtils';
import ApiService from 'services/ApiService';

export const setFetchinghDemos = (value) => ({
  type: DemosActionTypes.SET_FETCHING_DEMOS,
  payload: value,
});

export const fetchDemosError = (value) => ({
  type: DemosActionTypes.FETCH_DEMOS_ERROR,
  payload: value,
});

export const fetchDemos = ({
  query = '',
  onSuccess = () => {},
  onError = () => {},
}) => (dispatch) => {
  dispatch(setFetchinghDemos(true));

  return new Promise((res, rej) => ApiService.apiCall({
    url: `${ApiService.paths.demos.ROOT}${query}`,
    isToken: true,
  })
    .then((response) => {
      onSuccess(response);
      dispatch(setFetchinghDemos(false));

      return res(response);
    })
    .catch((error) => {
      onError(error);
      dispatch(setFetchinghDemos(false));
      dispatch(fetchDemosError(false));

      return rej(error);
    }));
};

export const fetchDemosHomeSuccess = (value) => ({
  type: DemosActionTypes.FETCH_DEMOS_HOME_SUCCESS,
  payload: value,
});

export const setDemosHomeIsEmpty = (value) => ({
  type: DemosActionTypes.SET_DEMOS_HOME_IS_EMPTY,
  payload: value,
});

export const fetchDemosHome = (query = '') => (dispatch) => dispatch(fetchDemos({
  query,
  onSuccess: (response) => {
    dispatch(fetchDemosHomeSuccess(response.results));
    dispatch(setDemosHomeIsEmpty(false));
    dispatch(setDemosHomeIsEmpty(searchResponseIsEmpty(query, response)));
  },
}));

export const clearDemosHome = (value) => ({
  type: DemosActionTypes.CLEAR_DEMOS_HOME,
  payload: value,
});
