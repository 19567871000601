import React, { useState } from 'react';

const WithHover = (WrappedComponent) => {
  const WithHoverComponent = (props) => {
    const [
      isHovered,
      setHover,
    ] = useState(false);
    const [
      isFocused,
      setFocus,
    ] = useState(false);

    const handleMouseEnter = () => {
      setHover(true);
    };
    const handleMouseLeave = () => {
      setHover(false);
    };

    const handleFocus = () => {
      setFocus(true);
    };
    const handleBlur = () => {
      setFocus(false);
    };

    return (
      <WrappedComponent
        isFocused={isFocused}
        isHovered={isHovered}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...props}
      />
    );
  };

  return WithHoverComponent;
};

export default WithHover;
