import React from 'react';
import { Button } from 'components/base';
import { ReactComponent as IconArrow } from 'assets/icons/arrow-right.svg';

const ButtonArrow = ({
  title = 'Добавить',
  iconHide = false,
  defaultStyle = 'transparent',
  ...otherProps
}) => (
  <Button
    arrow
    defaultStyle={defaultStyle}
    title={title}
    {...otherProps}
  >
    {!iconHide && (
      <IconArrow
        height={18}
        width={18}
      />
    )}
  </Button>
);

export default ButtonArrow;
