import React from 'react';
import * as PathsTypes from 'constants/PathsTypes';
import { Link } from 'components/typo';

const TelegramLink = ({
  color = 'white',
  hover = 'whitet70',
}) => (
  <Link
    browser
    color={color}
    hover={hover}
    href={PathsTypes.TELEGRAM}
    target="_blank"
  >
    Telegram
  </Link>
);

export default TelegramLink;
