import React, { useMemo } from 'react';
import cn from 'classnames';
import s from 'styled-components';

const Styles = ({
  theme,
  $color,
  $fw,
  $transitionOn,
}) => `
  color: ${theme.colors[$color] || $color || 'inherit'};
  font-weight: ${$fw ? theme.fw[$fw] : ''};
  transition: ${$transitionOn ? 'color 0.3s ease-in-out' : 'none'};

  &.--center {
    text-align: center;
  }
`;

const TitleContainerH1 = s.h1`
  ${(props) => Styles(props)}
`;

const TitleContainerH2 = s.h2`
  ${(props) => Styles(props)}
`;

const TitleContainerH3 = s.h3`
  ${(props) => Styles(props)}
`;

const TitleContainerH4 = s.h4`
  ${(props) => Styles(props)}
`;

const TitleContainerH5 = s.h5`
  ${(props) => Styles(props)}
`;

const TitleContainerH6 = s.h6`
  ${(props) => Styles(props)}
`;

const TitleContainerSpan = s.span`
  ${(props) => Styles(props)}
`;

const TitleContainerDiv = s.div`
  ${(props) => Styles(props)}
`;

const Title = ({
  children,
  tag = 'h5',
  tagStyle = null,
  fw = '',
  className = '',
  title = '',
  center = false,
  transitionOn = false,
  color,
  ...otherProps
}) => {
  const classes = cn(
    'w-title',
    {
      [`${tag}`]: tag && !tagStyle,
      [`${tagStyle}`]: tagStyle,
      [`text-fw${fw}`]: fw,
      '--center': center,
    },
    className,
  );

  const props = {
    $transitionOn: transitionOn,
    className: classes,
    $fw: fw,
    $color: color,
    ...otherProps,
  };

  const TitleComponent = useMemo(() => {
    switch (tag) {
    case 'h1':
      return TitleContainerH1;

    case 'h3':
      return TitleContainerH3;

    case 'h4':
      return TitleContainerH4;

    case 'h5':
      return TitleContainerH5;

    case 'h6':
      return TitleContainerH6;

    case 'span':
      return TitleContainerSpan;

    case 'div':
      return TitleContainerDiv;

    case 'h2':
    default:
      return TitleContainerH2;
    }
  }, [ tag ]);

  return (
    <TitleComponent {...props}>
      {children || title}
    </TitleComponent>
  );
};

export default Title;
