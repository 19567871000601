import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Formik,
  Field,
} from 'formik';
import { SchemaFormRequest } from 'constants/ValidationSchemas';

// components
import { Button } from 'components/base';
import { Box } from 'layout';
import { Title } from 'components/typo';
import {
  Form,
  FormField,
  FormGroup,
  FormFieldMasked,
  FormTerms,
} from 'components/form';
import useResponsive from 'components/hooks/useResponsive';

// constants

// helpers
import {
  getPhone,
  getNumbers,
} from 'helpers/RegExp';
import { fetchFormRequesе } from 'redux/actions/SystemActions';

const FormRequest = ({
  type = '',
  title = 'Оставить заявку',
  isEmpty = false,
}) => {
  const { isLaptopOrMobile } = useResponsive();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);

  const handleRequest = (values, { setSubmitting }) => {
    const data = {
      email: values.email,
      phone: getPhone(values.phone),
      company_name: values.company,
      order_type: type,
    };

    dispatch(fetchFormRequesе(data))
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={{
        phone: isEmpty ? '' : (profile && profile.phone) || '',
        email: isEmpty ? '' : (profile && profile.email) || '',
        company: isEmpty ? '' : (profile && profile.company) ? profile.company.title : '',
        terms: !isEmpty,
      }}
      validationSchema={SchemaFormRequest}
      onSubmit={handleRequest}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          isSubmitting,
        } = props;

        const attrs = {
          onChange: handleChange,
          onBlur: handleBlur,
          errors,
          touched,
          size: 'big',
        };

        const formIsValid = isValid;

        return (
          <Form
            noValidate
            onSubmit={handleSubmit}
          >
            <Box
              className={isLaptopOrMobile ? 'mb-20' : 'mb-5'}
              jc="center"
            >
              <Title
                center
                tag="h2"
              >
                {title}
              </Title>
            </Box>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    name="company"
                    placeholder="Компания"
                    value={values.company}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={values.email}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    alwaysShowMask={false}
                    customComponent={FormFieldMasked}
                    mask="+9 999 999 99 99"
                    name="phone"
                    placeholder="Телефон"
                    tag="mask"
                    type="tel"
                    value={getNumbers(values.phone)}
                  />
                )}
              </Field>
            </FormGroup>
            <div className={isLaptopOrMobile ? 'my-20' : 'my-4'}>
              <FormTerms
                {...attrs}
                isLaptopOrMobile={isLaptopOrMobile}
                name="terms"
                value={values.terms}
              />
            </div>
            <FormGroup>
              <Button
                full
                defaultStyle="acsent"
                isDisabled={isSubmitting || !formIsValid}
                loader={isSubmitting || !formIsValid}
                size="big"
                title="Отправить заявку"
                type="submit"
              />
            </FormGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormRequest;
