/* eslint-disable no-restricted-globals */
import React, { useMemo } from 'react';
import s from 'styled-components';
import cn from 'classnames';

const renderStyles = ({
  $display,
  $flex,
  $width,
  $maxWidth,
  $minWidth,
  $pointer,
  $noPointers,
  $ai,
  $jc,
  $padding,
}) => `
  width: ${$width || 'auto'};
  padding: ${$padding || ''};
  max-width: ${$maxWidth ? isNaN($maxWidth) ? $maxWidth : `${$maxWidth}px` : 'unset'};
  min-width: ${$minWidth ? isNaN($minWidth) ? $minWidth : `${$minWidth}px` : 'unset'};
  display: ${$display || 'block'};
  flex: ${$flex || 1};
  align-items: ${$ai || 'center'};
  justify-content: ${$jc || 'initial'};
  overflow-wrap: anywhere;
  cursor: ${$pointer ? 'pointer' : 'initial'};
  pointer-events: ${$noPointers ? 'none' : 'initial'};
`;

const GridItemContainerDiv = s.div`
  ${(props) => renderStyles(props)}
`;

const GridItem = ({
  children,
  className,
  display,
  flex,
  width,
  maxWidth,
  minWidth,
  onClick,
  noPointers,
  ai,
  jc,
  padding,
}) => {
  const props = useMemo(() => ({
    className: cn('w-grid-item', className),
    $display: display,
    $flex: flex,
    $width: width,
    $maxWidth: maxWidth,
    $minWidth: minWidth,
    $pointer: Boolean(onClick),
    $noPointers: noPointers,
    $ai: ai,
    $jc: jc,
    $padding: padding,
  }), [
    className,
    display,
    flex,
    width,
    maxWidth,
    minWidth,
    onClick,
    noPointers,
    ai,
    jc,
    padding,
  ]);

  return (
    <GridItemContainerDiv {...props}>
      {children}
    </GridItemContainerDiv>
  );
};

export default GridItem;
