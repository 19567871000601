import React from 'react';
import s from 'styled-components';
import cn from 'classnames';
import { Title } from 'components/typo';
import ButtonRound from 'components/base/ButtonRound';
import { ReactComponent as IconClose } from 'assets/icons/icon-cross.svg';
import { ReactComponent as IconCloseSmall } from 'assets/icons/icon-cross-small.svg';

const styles = ({
  center,
  display,
}) => `
  display: ${display};
  position: relative;
  width: 100%;
  padding: 26px 32px;
  align-items: center;
  text-align: ${center ? 'center' : 'left'};
  color: #000;

  &.--big-offset {
    padding: 26px 50px;
  }

  .w-modal-head-close {
    position: absolute;
    top: 12px;
    right: 12px;
  }
`;

const ModalHeadContainer = s.div`
  ${(props) => styles(props)}
`;

const ModalHead = ({
  className,
  children,
  display = 'block',
  btnSize = 'smallxl',
  title = '',
  bigOffset = false,
  onClose = () => {},
  ...otherProps
}) => {
  const classes = cn('w-modal-head', { '--big-offset': bigOffset }, className);

  const props = {
    ...otherProps,
    className: classes,
    display,
  };

  return (
    <ModalHeadContainer
      className={classes}
      {...props}
    >
      {title && (
        <Title
          color="black"
          tag="h4"
        >
          {title}
        </Title>
      )}
      {children}
      <div className="w-modal-head-close">
        <ButtonRound
          renderIcon={() => (!btnSize === 'small' ? <IconClose /> : <IconCloseSmall />)}
          size={btnSize}
          onClick={onClose}
        />
      </div>
    </ModalHeadContainer>
  );
};

export default ModalHead;
