import React from 'react';
import cn from 'classnames';
import {
  Grid,
  GridItem,
} from 'layout';
import Dropdown from 'components/base/Dropdown';
import { ButtonSettings } from 'components/buttons';
import {
  Card,
  CardMenuSettings,
  CardBtnTrash,
} from 'components/cards';

const CardFull = ({
  children,
  onDelete = null,
  deleteDirect = false,
  onEdit = null,
  onInfo = null,
  onClick = null,
  onDelivery = null,
  onAccept = null,
  onReject = null,
  onStart = null,
  onComplete = null,
  onStopListToggle = null,
  onActiveToggle = null,
  trash = false,
  gutter = 'big',
  isBlocked,
  isHovered,
  lastAuto = false,
  deleteTitle,
  noSettings,
  alignGrid = 'center',
  editTitle,
  ...otherProps
}) => (
  <Card
    {...otherProps}
    pointer
    ai="center"
    br={16}
    direction="column"
    isHovered={isHovered}
    padding="24px 0"
    onClick={onClick}
  >
    <Grid
      ai={alignGrid}
      gutter={gutter}
    >
      {children}
      <GridItem
        className={cn({ 'ml-a': lastAuto })}
        flex="0"
      >
        {noSettings
          ? null
          : trash
            ? (
              <CardBtnTrash
                isHovered={isHovered}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
              />
            ) : (
              <Dropdown
                renderMenu={({ close }) => (
                  <CardMenuSettings
                    deleteTitle={deleteTitle}
                    isBlocked={isBlocked}
                    isHovered={isHovered}
                    onAccept={onAccept}
                    onActiveToggle={onActiveToggle}
                    onClose={close}
                    onComplete={onComplete}
                    onDelete={onDelete}
                    deleteDirect={deleteDirect}
                    onDelivery={onDelivery}
                    onEdit={onEdit}
                    onInfo={onInfo}
                    onReject={onReject}
                    onStart={onStart}
                    onStopListToggle={onStopListToggle}
                    editTitle={editTitle}
                  />
                )}
                toggler={(
                  <ButtonSettings
                    fillHover="black"
                    isHovered={isHovered}
                  />
                )}
              />
            )}
      </GridItem>
    </Grid>
  </Card>
);

export default CardFull;
