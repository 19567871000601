import React from 'react';
import { ReactComponent as Icon } from './icons/clear.svg';
import { ButtonRound } from 'components/base';

const ButtonRemove = ({
  size = 'xs',
  defaultStyle = 'light',
}) => (
  <ButtonRound
    defaultStyle={defaultStyle}
    renderIcon={() => <Icon />}
    size={size}
  />
);

export default ButtonRemove;
