import { CategoriesActionTypes } from 'redux/types/CategoriesTypes';
import { ProductsActionTypes } from 'redux/types/ProductsTypes';
import { UserActionTypes } from 'redux/types/UserTypes';

const INITIAL_STATE = {
  name: 'categories',
  homeList: [],
  homeListIsEmpty: [],
  modalList: [],
  modalListIsEmpty: [],
  globalCategories: null,
  allList: [],
  allListIsEmpty: [],
  isFetching: true,
  isFetchingToggleActive: false,
  isSubmitting: false,

  categoriesProducts: {},
  categoriesProductsSearch: null,
  subcategories: null,
  globalCategory: null,
  category: null,
  isGlobalSubmitting: false,
  isFetchingCategorySubcategories: false,
};

const categoriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE };

  case CategoriesActionTypes.SET_FETCHING_CATEGORIES:
  case CategoriesActionTypes.SET_FETCHING_GLOBAL_CATEGORIES:
    return {
      ...state,
      isFetching: action.payload,
    };

  case CategoriesActionTypes.FETCH_CATEGORIES_HOME_SUCCESS:
    return {
      ...state,
      homeList: [ ...action.payload.filter((c) => !c.is_no_category) ],
    };

  case CategoriesActionTypes.UPDATE_CATEGORY_PRODUCTS:
    return {
      ...state,
      categoriesProducts: state.categoriesProducts
        ? Array.isArray(state.categoriesProducts[action.payload.categoryId])
          ? {
            ...state.categoriesProducts,
            [action.payload.categoryId]: [
              ...state.categoriesProducts[action.payload.categoryId],
              ...action.payload.products,
            ],
          }
          : {
            ...state.categoriesProducts,
            [action.payload.categoryId]: action.payload.products,
          }
        : { [action.payload.categoryId]: action.payload.products },
    };

  case CategoriesActionTypes.UPDATE_CATEGORY_PRODUCTS_SEARCH:
    return {
      ...state,
      categoriesProductsSearch: [
        ...(state.categoriesProductsSearch || []),
        ...action.payload,
      ],
    };

  case CategoriesActionTypes.CLEAR_CATEGORY_PRODUCTS_SEARCH:
    return {
      ...state,
      categoriesProductsSearch: null,
    };

  case CategoriesActionTypes.SET_CATEGORIES_HOME_IS_EMPTY:
    return {
      ...state,
      homeListIsEmpty: action.payload,
    };

  case CategoriesActionTypes.CLEAR_CATEGORIES_HOME:
    return {
      ...state,
      homeList: [],
      homeListIsEmpty: false,
    };

  case CategoriesActionTypes.FETCH_CATEGORIES_MODAL_SUCCESS:
    return {
      ...state,
      modalList: action.payload,
    };

  case CategoriesActionTypes.SET_CATEGORIES_MODAL_IS_EMPTY:
    return {
      ...state,
      modalListIsEmpty: action.payload,
    };

  case CategoriesActionTypes.CLEAR_CATEGORIES_MODAL:
    return {
      ...state,
      modalList: [],
      modalListIsEmpty: false,
    };

  case CategoriesActionTypes.FETCH_CATEGORIES_ALL_SUCCESS:
    return {
      ...state,
      subcategories: action.payload.reduce((acc, subcategory) => {
        const globalId = subcategory.global_category;

        if (Array.isArray(acc[globalId])) {
          acc[globalId].push(subcategory);
        }
        else {
          acc[globalId] = [ subcategory ];
        }

        return acc;
      }, {}),
    };

  case CategoriesActionTypes.SET_FETCHING_GLOBAL_CATEGORY_SUBCATEGORIES:
    return {
      ...state,
      isFetchingCategorySubcategories: action.payload,
    };

  case CategoriesActionTypes.FETCH_GLOBAL_CATEGORY_SUBCATEGORIES_SUCCESS:
    return {
      ...state,
      subcategories: {
        ...(state.subcategories || {}),
        [action.payload.id]: action.payload.response || [],
      },
    };

  case CategoriesActionTypes.SET_CATEGORIES_ALL_IS_EMPTY:
    return {
      ...state,
      allListIsEmpty: action.payload,
    };

  case CategoriesActionTypes.CLEAR_CATEGORIES_ALL:
    return {
      ...state,
      allList: [],
      allListIsEmpty: false,
    };

  case CategoriesActionTypes.SET_CATEGORY:
  case CategoriesActionTypes.FETCH_GET_CATEGORY_BY_ID_SUCCESS:
    return {
      ...state,
      category: action.payload,
    };

  case CategoriesActionTypes.SET_GLOBAL_CATEGORY:
  case CategoriesActionTypes.FETCH_GET_GLOBAL_CATEGORY_BY_ID_SUCCESS:
    return {
      ...state,
      globalCategory: action.payload || null,
    };

  case CategoriesActionTypes.CLEAR_GLOBAL_CATEGORY:
    return {
      ...state,
      globalCategory: null,
    };

  case CategoriesActionTypes.FETCH_ALL_GLOBAL_CATEGORIES_SUCCESS:
    return {
      ...state,
      globalCategories: action.payload.reduce((acc, next) => {
        acc[next.id] = {
          ...next,
          subcategories: null,
        };

        return acc;
      }, {}),
    };

  case CategoriesActionTypes.SET_FETCHING_GLOBAL_CATEGORY_SUBMITTING:
    return {
      ...state,
      isGlobalSubmitting: action.payload,
    };

  case CategoriesActionTypes.SET_FETCHING_CATEGORY_SUBMITTING:
    return {
      ...state,
      isSubmitting: action.payload,
    };

  case CategoriesActionTypes.FETCH_GLOBAL_CATEGORY_DELETE_SUCCESS:
    if (state.globalCategories[action.payload]) {
      const newCategories = { ...state.globalCategories };

      delete newCategories[action.payload];
      const newSubcategories = { ...(state.subcategories || {}) };

      delete newSubcategories[action.payload];

      return {
        ...state,
        globalCategories: newCategories,
        subcategories: newSubcategories,
      };
    }

    return state;

  case CategoriesActionTypes.FETCH_GLOBAL_CATEGORY_CREATE_SUCCESS:
    return {
      ...state,
      globalCategories: {
        ...(state.globalCategories || {}),
        [action.payload.id]: action.payload,
      },
    };

  case CategoriesActionTypes.FETCH_CATEGORY_TOGGLE_PRODUCT_ACTIVE_SUCCESS: {
    const categoryProducts = (state.categoriesProducts || {})[action.payload.category];

    if (Array.isArray(categoryProducts)) {
      return {
        ...state,
        categoriesProducts: {
          ...state.categoriesProducts,
          [action.payload.category]: categoryProducts.map((p) => (p.id === action.payload.id
            ? action.payload
            : p)),
        },
      };
    }

    return state;
  }

  case CategoriesActionTypes.FETCH_GLOBAL_CATEGORY_UPDATE_SUCCESS:
    if (state.globalCategories[action.payload.id]) {
      return {
        ...state,
        globalCategory: action.payload,
        globalCategories: {
          ...(state.globalCategories || {}),
          [action.payload.id]: action.payload,
        },
      };
    }

    return state;

  case CategoriesActionTypes.FETCH_CATEGORY_CREATE_SUCCESS: {
    const isGlobalCategory = (state.subcategories || {})[action.payload.global_category] || null;

    if (isGlobalCategory) {
      return {
        ...state,
        subcategories: {
          ...((state.subcategories || {}) || {}),
          [action.payload.global_category]: [
            ...isGlobalCategory,
            action.payload,
          ].sort((a, b) => {
            if (a.position > b.position) return 1;
            if (a.position < b.position) return -1;

            return -1;
          }),
        },
      };
    }

    return state;
  }

  case CategoriesActionTypes.FETCH_CATEGORY_UPDATE_SUCCESS: {
    const isGlobalCategory = (state.subcategories || {})[action.payload.global_category] || null;

    if (isGlobalCategory) {
      return {
        ...state,
        subcategories: {
          ...(state.subcategories || {}),
          [action.payload.global_category]: isGlobalCategory.map((c) => (c.id === action.payload.id
            ? action.payload
            : c))
            .sort((a, b) => a.position - b.position),
        },
      };
    }

    return state;
  }

  case CategoriesActionTypes.FETCH_CATEGORY_DELETE_SUCCESS: {
    const isGlobalCategory = (state.subcategories || {})[action.payload.globalId] || null;

    if (isGlobalCategory) {
      return {
        ...state,
        subcategories: {
          ...(state.subcategories || {}),
          [action.payload.globalId]: isGlobalCategory.filter((c) => c.id !== action.payload.categoryId),
        },
      };
    }

    return state;
  }

  case CategoriesActionTypes.SET_FETCHING_CATEGORY_TOGGLE_ACTIVE:
    return {
      ...state,
      isFetchingToggleActive: action.payload,
    };

  case CategoriesActionTypes.FETCH_CATEGORY_TOGGLE_ACTIVE_SUCCESS: {
    const globalCategoryId = action.payload.global_category;
    const globalSub = (state.subcategories || {})[globalCategoryId];

    if (globalSub) {
      return {
        ...state,
        subcategories: {
          ...(state.subcategories || {}),
          [globalCategoryId]: (state.subcategories || {})[globalCategoryId].map(((sc) => (sc.id === action.payload.id
            ? action.payload
            : sc))),
        },
      };
    }

    return state;
  }

  case ProductsActionTypes.FETCH_PRODUCT_UPDATE_SUCCESS: {
    const {
      product,
      oldProduct,
    } = action.payload;
    let newCategoriesProducts = { ...(state.categoriesProducts || {}) };
    let newSubcategories = { ...(state.subcategories || {}) };

    const isMove = product.category !== oldProduct.category;

    if (isMove) {
      const oldCategoryProducts = newCategoriesProducts[oldProduct.category] || null;
      const oldSubcategory = newSubcategories[oldProduct.global_category] || null;

      if (Array.isArray(oldCategoryProducts)) {
        newCategoriesProducts = {
          ...newCategoriesProducts,
          [oldProduct.category]: oldCategoryProducts.filter((p) => p.id !== oldProduct.id),
        };
      }

      if (Array.isArray(oldSubcategory)) {
        newSubcategories = {
          ...newSubcategories,
          [oldProduct.global_category]: oldSubcategory.map((sc) => (sc.id === oldProduct.category
            ? {
              ...sc,
              products_count: sc.products_count - 1,
            } : sc)),
        };
      }
    }

    const newCategoryProducts = newCategoriesProducts[product.category];
    const newSubcategory = newSubcategories[product.global_category];

    if (Array.isArray(newCategoryProducts)) {
      newCategoriesProducts = {
        ...newCategoriesProducts,
        [product.category]: isMove ? [
          ...newCategoryProducts,
          product,
        ] : newCategoryProducts.map((p) => (p.id === product.id
          ? product
          : p)),
      };
    }

    if (Array.isArray(newSubcategory)) {
      newSubcategories = {
        ...newSubcategories,
        [product.global_category]: newSubcategory.map((sc) => (sc.id === product.category
          ? {
            ...sc,
            products_count: sc.products_count + 1,
          } : sc)),
      };
    }

    return {
      ...state,
      categoriesProducts: newCategoriesProducts,
      subcategories: newSubcategories,
    };
  }

  case ProductsActionTypes.FETCH_PRODUCT_DELETE_SUCCESS: {
    const globalCategoryId = action.payload.global_category;
    const category = (state.subcategories || {})[globalCategoryId];
    const categoriesProducts = (state.categoriesProducts || {});
    const categoryProducts = categoriesProducts[action.payload.category];

    if (category && categoriesProducts && categoryProducts) {
      return {
        ...state,
        categoriesProducts: {
          ...categoriesProducts,
          [action.payload.category]: (categoryProducts || []).filter((p) => p.id !== action.payload.id),
        },
        subcategories: {
          ...state.subcategories,
          [globalCategoryId]: category.map((sc) => (sc.id === action.payload.category
            ? {
              ...sc,
              products_count: sc.products_count - 1,
            } : sc)),
        },
      };
    }

    return state;
  }

  case ProductsActionTypes.FETCH_PRODUCT_CREATE_SUCCESS: {
    const globalCategoryId = action.payload.global_category;
    const category = (state.subcategories || {})[globalCategoryId];

    if (category && state.categoriesProducts && state.categoriesProducts[action.payload.category]) {
      return {
        ...state,
        categoriesProducts: {
          ...state.categoriesProducts,
          [action.payload.category]: [
            ...(state.categoriesProducts[action.payload.category] || []),
            action.payload,
          ],
        },
        subcategories: {
          ...state.subcategories,
          [globalCategoryId]: category.map((sc) => (sc.id === action.payload.category
            ? {
              ...sc,
              products_count: sc.products_count + 1,
            } : sc)),
        },
      };
    }

    return state;
  }

  default:
    return state;
  }
};

export default categoriesReducer;
