import React from 'react';
import cn from 'classnames';
import { Box } from 'layout';
import { Text } from 'components/typo';
import { Tooltip } from 'components/base';

const FormHeading = ({
  children,
  title,
  color = 'black',
  nomargin = false,
  onClick = null,
  hidden,
  tooltip = null,
  small,
  jc = 'space-between',
  tooltipPosition = 'top-right',
  isImportant = false,
  ...otherProps
}) => (
  <Box
    ai="center"
    className={cn({
      'mb-0': nomargin,
      'mb-3': !nomargin && !small,
      'mb-1': !nomargin && small,
    })}
    jc={jc}
    pointer={onClick}
    onClick={onClick}
    {...otherProps}
  >
    <Text
      color={hidden ? 'transparent' : color}
      fw={small ? 'n' : 'sb'}
      size={small ? 'xs' : 'normal'}
      tag="div"
    >
      {title}
      {isImportant && (
        <Text
          color="red"
          fw="inherit"
          size="inherit"
          tag="span"
        >
          *
        </Text>
      )}
    </Text>
    {children}
    {tooltip && (
      <Tooltip
        className="ml-min"
        position={tooltipPosition}
        text={tooltip}
      />
    )}
  </Box>
);

export default FormHeading;
