// import { toast } from 'react-toastify'

import { SubscriptionsActionTypes } from '../types/SubscriptionsTypes';
import { UserActionTypes } from '../types/UserTypes';
import StorageService from 'services/StorageService';
import ApiService from 'services/ApiService';

export const setFetchinghSubscriptions = (value) => ({
  type: SubscriptionsActionTypes.SET_FETCHING_SUBSCRIPTIONS,
  payload: value,
});

export const fetchSubscriptionsError = (value) => ({
  type: SubscriptionsActionTypes.FETCH_SUBSCRIPTIONS_ERROR,
  payload: value,
});

export const fetchSubscriptionsSuccess = (value) => ({
  type: SubscriptionsActionTypes.FETCH_SUBSCRIPTIONS_SUCCESS,
  payload: value,
});

export const fetchSubscriptions = () => (dispatch) => {
  dispatch(setFetchinghSubscriptions(true));

  return new Promise((res, rej) => ApiService.apiCall({
    url: ApiService.paths.subscriptions.ROOT,
    isToken: true,
  })
    .then((response) => {
      dispatch(setFetchinghSubscriptions(false));
      dispatch(fetchSubscriptionsSuccess(response.results));

      return res(response);
    })
    .catch((error) => {
      dispatch(setFetchinghSubscriptions(false));
      dispatch(fetchSubscriptionsError(error));

      return rej(error);
    }));
};

export const setFetchingBuySubscription = (value) => ({
  type: SubscriptionsActionTypes.SET_FETCHING_USER_BUY_SUBSCRIPTIONS,
  payload: value,
});

export const fetchBuySubscriptionSuccess = (clinic) => ({
  type: SubscriptionsActionTypes.FETCH_USER_BUY_SUBSCRIPTIONS_SUCCESS,
  payload: clinic,
});

export const fetchBuySubscriptionError = (clinic) => ({
  type: SubscriptionsActionTypes.FETCH_USER_BUY_SUBSCRIPTIONS_ERROR,
  payload: clinic,
});

export const fetchBuySubscriptionsAsync = (data) => (dispatch) => {
  dispatch(setFetchingBuySubscription(true));

  return new Promise((res, rej) => {
    ApiService.apiCall({
      method: 'POST',
      token: StorageService.getToken(),
      url: ApiService.paths.subscriptions.BUY,
      data,
    })
      .then((response) => {
        dispatch(setFetchingBuySubscription(false));
        dispatch(fetchBuySubscriptionSuccess(response));

        return res(response);
      })
      .catch((error) => {
        dispatch(setFetchingBuySubscription(false));
        dispatch(fetchBuySubscriptionError());

        return rej(error);
      });
  });
};

export const setCompanySubscription = (subscription) => ({
  type: UserActionTypes.SET_COMPANY_SUBSCRIPTION,
  payload: subscription,
});
